import React from 'react'
import { Modal } from 'antd'
import { getText } from '../../lang'
import MatButton from '../MatButton'
import './MatModal.scss'

const MatModal = (props) => {
  return (
    <>
      {!props.noTrigger && (
        <div
          dataIndex={props.dataIndex}
          className={`modal-btn ${props.triggerClassName || ''} ${props.visible ? 'modal-btn-active' : ''}`}
          onClick={() => {
            props.onTrrigerClick && props.onTrrigerClick()
          }}
          id={props.id}
        >
          {props.trigger ? props.trigger : 'Open Modal'}
        </div>
      )}
      <Modal
        className={`mat-modal ${props.className || ''}`}
        rootClassName={props.rootClassName}
        title={props.title}
        open={props.visible}
        width={props.width || 500}
        styles={{ body: props.bodyStyle, mask: props.maskStyle }}
        destroyOnClose={props.destroyOnClose}
        footer={
          !props.hideFooter
            ? [
                <MatButton
                  key='back'
                  onClick={props.onCancelBtn || props.onCancel}
                  buttonText={props.cancelText || getText('ACTION_CANCEL')}
                  typeButton={'white'}
                  htmlType={'button'}
                  style={{ minWidth: 90, height: 50 }}
                />,
                !props.hideSubmit ? (
                  <MatButton
                    key='submit'
                    onClick={props.onOk}
                    loading={props.confirmLoading}
                    buttonText={props.okText}
                    formName={props.formName}
                    disabled={props.submitButtonDisabled}
                    style={{ minWidth: 90, height: 50 }}
                  />
                ) : null,
              ]
            : null
        }
        centered={props.centered}
        forceRender={props.forceRender}
        okText={props.okText}
        cancelText={props.cancelText}
        confirmLoading={props.confirmLoading}
        onOk={props.onOk}
        onCancel={props.onCancel}
        closable={props.closable}
      >
        <div className='modal-content-wrapper'>{props.children}</div>
      </Modal>
    </>
  )
}

MatModal.defaultProps = {
  centered: true,
  destroyOnClose: true,
}

export default MatModal
