import React, { forwardRef, useImperativeHandle, useRef } from 'react'
import { Input } from 'antd'
import { getText } from '../../lang'
import MyDefaultFormItem from '../MyDefaultFormItem/MyDefaultFormItem'
import './MyDefaultTextarea.scss'

const deviceSize = ['mobile', 'tabletPortrait', 'tablet']

const MyDefaultTextarea = forwardRef(
  (
    {
      isForm,
      screenSize,
      inputClassName,
      label,
      placeholder = getText('WORD_TEXT'),
      autoSize,
      disabled,
      onChange,
      maxLength,
      value,
      allowClear = true,
      showCount,
      onClick,
      onPressEnter,
      onKeyUp,
      onKeyDown,
      name,
      rules,
      hasFeedback = false,
      required = false,
      message,
      maxLenghthMsg,
      minLength,
      formStyle,
      initialValue,
      isLast,
      isFirst,
      color = 'grey',
    },
    ref
  ) => {
    const textAreaRef = useRef()

    useImperativeHandle(ref, () => ({
      autoFocus(position) {
        if (!deviceSize.includes(screenSize)) {
          setTimeout(() => {
            textAreaRef.current.focus()
            if (position) {
              textAreaRef.current.resizableTextArea.textArea.selectionStart =
                position
              textAreaRef.current.resizableTextArea.textArea.selectionEnd = position
            }
          }, 10)
        }
      },
    }))

    return isForm ? (
      <MyDefaultFormItem
        label={label}
        name={name}
        hasFeedback={hasFeedback}
        rules={
          rules || [
            {
              required: required,
              message: message
                ? message
                : `${getText('WORD_PLEASE_INPUT')} ${label ? label : placeholder}!`,
              whitespace: required,
            },
            {
              max: maxLength,
              message:
                maxLength &&
                (maxLenghthMsg ||
                  `${label ? label : placeholder} ${getText('TEXT_MUST_BE_MAXIMUM')} ${maxLength} ${getText('WORD_CHARACTERS')}.`),
            },
            {
              min: minLength,
              message:
                minLength &&
                `${label ? label : placeholder} ${getText('TEXT_MUST_BE_MINIMUM')} ${minLength} ${getText('WORD_CHARACTERS')}.`,
            },
          ]
        }
        style={formStyle}
        initialValue={initialValue}
        isLast={isLast}
        isFirst={isFirst}
      >
        <Input.TextArea
          className={`my_default_textarea ${color} ${inputClassName || ''}`}
          placeholder={label || placeholder}
          autoSize={autoSize || { minRows: 5, maxRows: 5 }}
          disabled={disabled}
          onChange={onChange}
          maxLength={maxLength}
          ref={textAreaRef}
          value={value}
          allowClear={allowClear}
          showCount={showCount}
          onClick={onClick}
          onPressEnter={onPressEnter}
          onKeyUp={onKeyUp}
          onKeyDown={onKeyDown}
          style={{
            resize: 'none',
          }}
        />
      </MyDefaultFormItem>
    ) : (
      <Input.TextArea
        className={`my_default_textarea ${color} ${inputClassName || ''}`}
        placeholder={label || placeholder}
        autoSize={autoSize || { minRows: 5, maxRows: 5 }}
        disabled={disabled}
        onChange={onChange}
        maxLength={maxLength}
        ref={textAreaRef}
        value={value}
        allowClear={allowClear}
        showCount={showCount}
        onClick={onClick}
        onPressEnter={onPressEnter}
        onKeyUp={onKeyUp}
        onKeyDown={onKeyDown}
        style={{
          resize: 'none',
        }}
      />
    )
  }
)

export default MyDefaultTextarea
