import React, { memo } from 'react'
import { Layout } from 'antd'
import { useLocation, useNavigate } from 'react-router-dom'
import { getText } from '../../lang'
import MyDefaultButton from '../../components/MyDefaultButton/MyDefaultButton'
import './MainFooter.scss'

const { Footer } = Layout

const MainFooter = (props) => {
  const location = useLocation()
  const navigate = useNavigate()
  const handleCancel = () => {
    const currentPath = location.pathname
    if (currentPath.includes('location/settings')) {
      if (
        currentPath.includes('livechat') &&
        (currentPath.includes('edit') || currentPath.includes('add'))
      ) {
        // example : livechat-announcement/special/edit
        // OR livechat-greetings/add
        const indexOfLastTrail = currentPath.lastIndexOf('/')
        return navigate(currentPath.substring(0, indexOfLastTrail))
      }
      return navigate('/location/list')
    }
    navigate(-1)
  }

  return (
    <Footer className='mat-footer'>
      {((Array.isArray(props.footerButtons) &&
        props.footerButtons.includes('cancel')) ||
        props.footerButtons['cancel']) && (
        <MyDefaultButton
          buttonText={getText('ACTION_CANCEL')}
          typeButton={'cancel'}
          htmlType={'button'}
          onClick={
            Array.isArray(props.footerButtons)
              ? handleCancel
              : props.footerButtons['cancel'].action
                ? props.footerButtons['cancel'].action
                : handleCancel
          }
          minWidth={161}
        />
      )}
      {((Array.isArray(props.footerButtons) &&
        props.footerButtons.includes('save')) ||
        props.footerButtons['save']) && (
        <MyDefaultButton
          loading={props.loading}
          buttonText={
            Array.isArray(props.footerButtons)
              ? getText('ACTION_SAVE_CHANGES')
              : props.footerButtons['save']?.title
                ? props.footerButtons['save'].title
                : getText('ACTION_SAVE_CHANGES')
          }
          onClick={
            Array.isArray(props.footerButtons)
              ? null
              : props.footerButtons['save'].action
                ? props.footerButtons['save'].action
                : null
          }
          minWidth={161}
        />
      )}
      {((Array.isArray(props.footerButtons) &&
        props.footerButtons.includes('copy')) ||
        props.footerButtons['copy']) && (
        <MyDefaultButton
          loading={props.loading}
          buttonText={getText('WORD_COPY')}
          onClick={
            Array.isArray(props.footerButtons)
              ? null
              : props.footerButtons['copy'].action
                ? props.footerButtons['copy'].action
                : null
          }
          minWidth={161}
        />
      )}
    </Footer>
  )
}

export default memo(MainFooter)
