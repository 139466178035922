import React, { useState, useEffect, useCallback, useRef } from 'react'
import { debounce } from 'lodash'
import { useNavigate } from 'react-router-dom'
import { getText } from '../../../../lang'
import { TABLE_DEFAULT_ROW_NUMBER } from '../../../../devOptions'
import MyDefaultPageLayout from '../../../../components/MyDefaultPageLayout/MyDefaultPageLayout'
import MyDefaultGridList from '../../../../components/MyDefaultGridList/MyDefaultGridList'
import voiceAIActions from '../../../../store/modules/voiceAIActions'
import VoiceAIListItem from './VoiceAIListItem'
import './VoiceAi.scss'

const VoiceAi = ({ setPageTitle, screenSize, organization, setFooterButtons }) => {
  const navigate = useNavigate()

  const [data, setData] = useState({
    list: [],
    total: 0,
  })
  const [isLoading, setIsLoading] = useState(false)
  const [selectedStatus, setSelectedStatus] = useState(true)
  const [enableObj, setEnableObj] = useState({ enabled: selectedStatus })
  const [page, setPage] = useState(0)
  const [search, setSearch] = useState('')
  const [searchList, setSearchList] = useState('')

  const layoutRef = useRef(null)

  useEffect(() => {
    setPageTitle(getText('WORD_VOICE_AI'))
  }, [])

  const handleChangeStatusFilter = (value) => {
    setSelectedStatus(value)
    const fill = async () => {
      if (value === 'all') {
        setEnableObj({})
      } else {
        setEnableObj({ enabled: value })
      }
    }
    fill()
  }
  const getOrganizationsList = async () => {
    setIsLoading(true)
    const objParams = {
      page,
      perPage: TABLE_DEFAULT_ROW_NUMBER,
      search: searchList,
      idOrganization: organization._id,
    }
    if (enableObj && enableObj.hasOwnProperty('enabled')) {
      objParams.enabled = enableObj.enabled
    }
    let result = await voiceAIActions.getVapiAssistants(objParams)
    if (result.success) {
      const newData =
        page === 0
          ? result.data.vapiAssistants
          : [...data.list, ...result.data.vapiAssistants]
      setData({
        list: newData,
        total: result.data.total_items,
      })
    }
    setIsLoading(false)
  }

  useEffect(() => {
    getOrganizationsList()
  }, [selectedStatus, searchList, page])

  const handleSearchFilter = (value) => {
    setSearch(value)
    debouncedChange(value)
  }

  const debouncedChange = useCallback(
    debounce((options) => {
      setPage(0)
      setSearchList(options)
    }, 500),
    []
  )

  const isFiltered = search || (enableObj && enableObj.hasOwnProperty('enabled'))

  return (
    <MyDefaultPageLayout
      ref={layoutRef}
      disableHeader={!isFiltered && !data?.list?.length && !isLoading}
      headerProps={{
        options: [
          { label: getText('WORD_ALL'), value: 'all' },
          { label: getText('WORD_ACTIVE'), value: true },
          { label: getText('WORD_INACTIVE'), value: false },
        ],
        buttonText: getText('TEXT_CREATE_NEW_ASSISTANT'),
        searchValue: search,
        onSearchChange: (value) => handleSearchFilter(value),
        onSelectChange: (options) => handleChangeStatusFilter(options),
        selectValue: selectedStatus,
        onClick: () => {
          navigate(`/organization/settings/${organization._id}/voice_ai/create`)
        },
        screenSize: screenSize,
      }}
    >
      <MyDefaultGridList
        pageStart={page}
        loading={isLoading}
        data={data.list || []}
        renderItem={(item, index) => {
          return (
            <VoiceAIListItem
              item={item}
              key={index + '_' + item._id}
              organization={organization}
            />
          )
        }}
        onLoadMore={() => {
          if (!isLoading && data?.list?.length < data?.total) {
            setIsLoading(true)
            setPage((ov) => ov + 1)
          }
        }}
        totalDataCount={data.total || 0}
        getScrollParent={() => layoutRef?.current}
        emptyTitle={
          isFiltered
            ? getText('TEXT_NO_RESULTS_FOUND')
            : getText('TEXT_CREATE_A_LINK_TO_LANDING_PAGE_TO_ACTIVATE_VOICE_AI')
        }
        emptyButtonTitle={!isFiltered && getText('TEXT_CREATE_NEW_ASSISTANT')}
        onEmptyClick={() =>
          navigate(`/organization/settings/${organization._id}/voice_ai/create`)
        }
      />
    </MyDefaultPageLayout>
  )
}

export default VoiceAi
