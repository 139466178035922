import React, { useEffect } from 'react'
import { Form, Tabs } from 'antd'
import moment from 'moment'
import dayjs from 'dayjs'
import { getText, getTextServerError } from '../../../../lang'
import MatForm from '../../../../components/Form/MatForm'
import { notifyError, notifySuccess } from '../../../../utils/Notify'
import authActions from '../../../../store/modules/authActions'
import OrganizationLayout from '../../components/organizationNewPage/OrganizationLayout'
import OrganizationListTitle from '../../components/organizationNewPage/OrganizationListTitle'
import PageFull from '../../../../components/PageFull'
import utilDate, { FORMAT_DATE } from '../../../../utils/utilsDate'
import organizationActions from '../../../../store/modules/organizationActions'
import MatButton from '../../../../components/MatButton'
import { formatPhone } from '../../../../utils'
import SingleWorkingDay from './SingleWorkingDay'
import { DaysCheck } from './DaysCheck'
import CustomWorkingDay from './CustomWorkingDay'
import './OpeningHours.scss'

export const days = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
]

export const defaultWorkingHours = {
  Sunday: { isOpen: false, from: '9:00 AM', to: '9:00 PM' },
  Monday: { isOpen: true, from: '9:00 AM', to: '9:00 PM' },
  Tuesday: { isOpen: true, from: '9:00 AM', to: '9:00 PM' },
  Wednesday: { isOpen: true, from: '9:00 AM', to: '9:00 PM' },
  Thursday: { isOpen: true, from: '9:00 AM', to: '9:00 PM' },
  Friday: { isOpen: true, from: '9:00 AM', to: '9:00 PM' },
  Saturday: { isOpen: false, from: '9:00 AM', to: '9:00 PM' },
}

const OpeningHours = (props) => {
  const { organization, setLoading } = props

  useEffect(() => {
    props.setFooterButtons && props.setFooterButtons(['save', 'cancel'])
    props.setPageTitle(getText('WORD_OPENING_HOURS'))
    let list = Object.values(organization.workingHours || defaultWorkingHours)
    form &&
      form.setFieldsValue({
        workingHours: list,
        customWorkingHours: organization.customWorkingHours,
      })
  }, [])

  const [form] = Form.useForm()

  const onFinish = async (values) => {
    setLoading(true)
    const entries = Object.fromEntries(days.entries())
    const mappedValues = Object.keys(entries).map((key) => [
      entries[key],
      values.workingHours[key],
    ])
    const workngHoursObject = Object.fromEntries(mappedValues)
    const openingHoursError = DaysCheck(workngHoursObject)

    let result
    let resultCustomWorkingHours

    if (openingHoursError) {
      notifyError(getTextServerError(openingHoursError))
      setLoading(false)
      return false
    }

    const obj = {
      workingHours: workngHoursObject,
    }

    if (!organization._id) {
      obj.name = organization.name
      obj.twilio_number = formatPhone(organization.twilio_number, false)
      obj.timezone = organization.timezone
      obj.defaultLanguage = organization.defaultLanguage
      obj.languages = organization.languages

      result = await organizationActions.saveNew(obj)
    } else {
      result = await organizationActions.saveUpdate(obj, organization._id)
      if (values.customWorkingHours.length) {
        for (const customItem of values.customWorkingHours) {
          const customWorkingHoursObj = {
            isOpen: customItem.isOpen,
            from: customItem.from,
            to: customItem.to,
            date: dayjs(customItem.date).format(FORMAT_DATE),
          }
          if (customItem.isNew) {
            delete customItem.isNew
            resultCustomWorkingHours =
              await organizationActions.saveCustomWorkingHours(
                customWorkingHoursObj,
                organization._id
              )
          } else {
            resultCustomWorkingHours =
              await organizationActions.saveUpdateOfCustomWorkingHours(
                customWorkingHoursObj,
                organization._id,
                customItem._id
              )
          }
          if (!resultCustomWorkingHours.success) {
            notifyError(getTextServerError(resultCustomWorkingHours.errMsg))
            setLoading(false)
            return
          }
        }
      }
    }
    if (result && result.success) {
      organization.workingHours = result.data.workingHours
      if (Boolean(resultCustomWorkingHours)) {
        result.data.customWorkingHours =
          resultCustomWorkingHours.data.customWorkingHours
        form.setFieldsValue({
          customWorkingHours: resultCustomWorkingHours.data.customWorkingHours.map(
            (item) =>
              Object.assign(item, { date: utilDate.getDateByDayJS(item.date) })
          ),
        })
      }
      let org = authActions.getOrganization()
      notifySuccess(
        !organization._id
          ? getText('TEXT_ORGANIZATION_WAS_CREATED_SUCCESSFULLY')
          : getText('TEXT_ORGANIZATION_WAS_SAVED_SUCCESSFULLY')
      )
      props.setOrganization(result.data)
      if (result.data._id === org._id) {
        await authActions.setOrganization(result.data)
      }
    } else {
      notifyError(getTextServerError(result.errMsg))
    }
    setLoading(false)
  }

  const onFinishFailed = (errorInfo) => {
    if (errorInfo.errorFields.length > 0) {
      if (errorInfo.errorFields[0].errors[0] === 'Please input !') {
        notifyError(getText('MSG_OPENING_HOURS_ERROR'))
      } else {
        notifyError(errorInfo.errorFields[0].errors[0])
      }
    }
  }

  return (
    <OrganizationLayout className={'working-hours-wrapper'}>
      <OrganizationListTitle title={getText('WORD_OPENING_HOURS')} />
      <PageFull className={'working-hours-body'}>
        <MatForm
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          initialValues={{}}
        >
          <Tabs
            className='mat-tabs'
            items={[
              {
                key: 'organization_opening_hours',
                label: getText('TEXT_ORGANIZATION_OPENING_HOURS'),
                forceRender: true,
                children: (
                  <Form.List name='workingHours'>
                    {(fields) => {
                      return fields.map((field, index) => {
                        return (
                          <SingleWorkingDay
                            key={index}
                            field={field}
                            form={form}
                            index={index}
                          />
                        )
                      })
                    }}
                  </Form.List>
                ),
              },
              {
                key: 'custom_opening_hours',
                label: getText('TEXT_CUSTOM_OPENING_HOURS'),
                forceRender: true,
                children: (
                  <Form.List name='customWorkingHours'>
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map((field, index) => {
                          return (
                            <CustomWorkingDay
                              key={index}
                              field={field}
                              index={index}
                              form={form}
                              onDelete={async (item) => {
                                if (item.isNew) {
                                  remove(index)
                                } else {
                                  const result =
                                    await organizationActions.deleteCustomWorkingHoursItem(
                                      organization._id,
                                      item._id
                                    )
                                  if (result.success) {
                                    props.setOrganization(result.data)
                                    remove(index)
                                  } else {
                                    notifyError(getTextServerError(result.errMsg))
                                  }
                                }
                                form.validateFields()
                              }}
                            />
                          )
                        })}
                        <MatButton
                          buttonText={getText('WORD_ADD_CUSTOM_HOURS')}
                          typeButton={'white'}
                          htmlType={'button'}
                          onClick={() => {
                            const newCustomOpeningHours = {
                              isOpen: false,
                              from: '9:00 AM',
                              to: '10:00 PM',
                              id: new Date().getTime(),
                              _id: new Date().getTime(),
                              date: moment(),
                              isNew: true,
                            }
                            add(newCustomOpeningHours)
                          }}
                        />
                      </>
                    )}
                  </Form.List>
                ),
              },
            ]}
          />
        </MatForm>
      </PageFull>
    </OrganizationLayout>
  )
}

export default OpeningHours
