import React, { memo } from 'react'
import { Empty, Select } from 'antd'
import LoadingSpinner from '../../../../../components/LoadingSpinner'

const { Option } = Select

const TemplateSelect = (props) => {
  return (
    <Select
      id={props.id}
      onClick={props.onClick}
      onKeyUp={props.onKeyUp}
      onKeyDown={props.onKeyDown}
      onChange={props.onChange}
      className={props.className}
      dropdownStyle={props.dropdownStyle}
      placement={'topRight'}
      popupClassName={props.dropdownClassName}
      suffixIcon={props.suffixIcon}
      popupMatchSelectWidth={props.dropdownMatchSelectWidth}
      disabled={props.disabled}
      value={props.value}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
      dataIndex={props.dataIndex}
      onDropdownVisibleChange={props.onDropdownVisibleChange}
      notFoundContent={
        props.loading ? (
          <LoadingSpinner />
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )
      }
      onSelect={(e, ee) => {
        props.onSelect && props.onSelect(e, ee)
        if (props.options && ee.key) {
          props.onSelectItem &&
            props.onSelectItem(
              props.options.find((item) => item._id === ee.key),
              ee
            )
        }
      }}
      onPopupScroll={(e) => {
        e.persist()
        props.onPopupScroll && props.onPopupScroll(e)
        if (
          e.target.scrollTop + e.target.offsetHeight >=
          e.target.scrollHeight - 10
        ) {
          props.onLoadMore && props.onLoadMore()
        }
      }}
    >
      {props.options.map((opt, index) => {
        return (
          <Option
            key={`${opt._id || index}`}
            value={opt._id || opt.value}
            subvalue={opt.getValue ? opt.getValue() : opt.value}
            replacedText={opt.replacementItem ? opt.replacementItem() : opt.value}
            replacedSubject={
              opt.replacementSubject ? opt.replacementSubject() : opt.value
            }
            subject={opt.getSubject ? opt.getSubject() : opt.value}
          >
            {opt.name}
          </Option>
        )
      })}
    </Select>
  )
}

export default memo(TemplateSelect)
