import React, { useEffect, useState } from 'react'
import { Card, Col, Row } from 'antd'
import { useNavigate, useLocation } from 'react-router-dom'
import { getText } from '../../../lang'
import {
  MainColor,
  emailFormater,
  moveToLoginPage,
  phoneNumberFormater,
} from '../../../utils'
import SVGArrowRight from '../../../icons/SVG/SVGArrowRight'
import SVGEmailSelect from '../../../icons/SVG/SVGEmailSelect'
import SVGSMSSelect from '../../../icons/SVG/SVGSMSSelect'
import SVGLoginLogo from '../../../icons/SVG/SVGLoginLogo'
import PageFull from '../../../components/PageFull'
import MatRow from '../../../components/MatRow'
import MatButton from '../../../components/MatButton'
import LoginPageIcons from './LoginPageIcons'
import { requestVerification } from './loginUtils'
import LoginPageHeader from './LoginPageHeader'

let deviceSize = ['tabletPortrait', 'mobile']

const LoginVerification = (props) => {
  const { screenSize } = props

  const [loading, setLoading] = useState(false)

  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    if (location.state === null) {
      moveToLoginPage(navigate)
    }
  }, [location])

  const isDesktop = !deviceSize.includes(screenSize)

  return (
    <PageFull className='login-page-wrapper'>
      <Row className='login-page-main-row'>
        <Col
          className='login-page-main-col'
          style={{
            width: isDesktop ? '59%' : '100%',
          }}
        >
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: isDesktop ? 'flex-start' : 'center',
              marginTop: isDesktop ? 0 : 20,
            }}
          >
            <SVGLoginLogo />
          </div>
          <Card bordered={false} className='login-page-left-card'>
            <LoginPageHeader
              customTitle={getText('WORD_VERIFICATION')}
              customText={getText('TEXT_RECEIVE_VERIFICATION_CODE_VIA')}
            />
            <MatRow style={{ justifyContent: 'center', flexDirection: 'column' }}>
              {location.state &&
                location.state.methods.map((item, index) => {
                  return (
                    <div key={index} className='hint-button'>
                      <MatButton
                        loading={loading}
                        key={index}
                        radius={8}
                        icon={
                          item.method === 'email' ? (
                            <SVGEmailSelect
                              width={20}
                              height={17}
                              color={MainColor}
                            />
                          ) : (
                            <SVGSMSSelect width={20} height={17} color={MainColor} />
                          )
                        }
                        typeButton={'white'}
                        size={'large'}
                        buttonText={
                          <div className='icon-with-hint-number'>
                            <div className='text-with-icon'>
                              {getText(
                                item.method === 'email'
                                  ? 'WORD_EMAIL'
                                  : 'WORD_SMS_UC'
                              )}{' '}
                              <p>
                                {item.method === 'email'
                                  ? emailFormater(item.value)
                                  : phoneNumberFormater(item.value)}
                              </p>
                            </div>
                            <SVGArrowRight width={12} height={12} />
                          </div>
                        }
                        onClick={async () => {
                          setLoading(true)
                          requestVerification(
                            location.state.token,
                            item.method,
                            item.value,
                            navigate,
                            true,
                            location.state.loginInputValues
                          )
                          setLoading(false)
                        }}
                      />
                    </div>
                  )
                })}
            </MatRow>
            <MatButton
              className='login-verification-cancel-btn'
              typeButton={'cancel'}
              buttonText={getText('ACTION_BACK')}
              onClick={() => {
                moveToLoginPage(navigate)
              }}
            />
          </Card>
        </Col>
        {isDesktop ? <LoginPageIcons /> : null}
      </Row>
    </PageFull>
  )
}

export default LoginVerification
