import React from 'react'
import _ from 'lodash'
import IconUser from '../../../../icons/IconUser'
import MatRow from '../../../../components/MatRow'
import { getText } from '../../../../lang'
import SVGOrganic from '../../../../icons/SVG/SVGOrganic'
import SVGInstagram from '../../../../icons/SVG/SVGInstagram'
import SVGFacebook from '../../../../icons/SVG/SVGFacebook'
import TouchpointsEditModal from './TouchpointsEditModal'

const TouchpointFieldItemHeader = (props) => {
  const { item, organization, onSave, isFromVoiceAI } = props

  const getIcon = (source) => {
    switch (source) {
      case 'instagram':
        return <SVGInstagram />
      case 'inbound':
        return <SVGOrganic />
      case 'facebook':
        return <SVGFacebook />
      default:
        return null
    }
  }

  const isNew = item.is_new && !item.sequence && _.isEmpty(item.users)

  return (
    <MatRow className='touchpoint-field-header' spaceBetween>
      {getIcon(item.source)}
      <MatRow flexStart className='title-with-is-new'>
        <div className='touchpoints-source-with-name'>
          <MatRow flexEnd>
            <div className='title'>{getText('WORD_NAME')}</div>
            <div className='name'>{item.name}</div>
          </MatRow>
          <MatRow flexEnd>
            <div className='title'>{getText('WORD_SOURCE')}</div>
            <div className='name'>{item.source}</div>
          </MatRow>
        </div>
        {isNew && <div className='is-new'>{getText('WORD_NEW')}</div>}
      </MatRow>
      {Boolean(!isFromVoiceAI) && (
        <TouchpointsEditModal
          item={item}
          trigger={<div className='tuchpoints-edit-icon'>{IconUser.edit}</div>}
          organization={organization}
          onSave={onSave}
        />
      )}
    </MatRow>
  )
}

export default TouchpointFieldItemHeader
