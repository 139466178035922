import React from 'react'
import Div from '../Div/Div'

const MyDefaultBlockTitle = ({ title, additionalHeader }) => {
  return (
    <Div className={'my_default_block_title'}>
      {title}
      {additionalHeader && additionalHeader}
    </Div>
  )
}

export default MyDefaultBlockTitle
