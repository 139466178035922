import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Tooltip } from 'antd'
import SVGAISummaryIcon from '../../../../../icons/SVG/SVGAISummaryIcon'
import { getText } from '../../../../../lang'
import MatButton from '../../../../../components/MatButton'
import CenterAISummarizeModal from '../CenterMessageModal/CenterAISummarizeModal'

const CenterAISummerize = ({ conversation, aiSummaryMessage }) => {
  const [visible, setVisible] = useState(false)
  const [uiState, setUIState] = useState({
    isExpandedSize: window.innerWidth > 1400 && window.innerWidth < 1500,
    isTheUIChanged: false,
  })

  let menuOpen = useSelector((state) => {
    return state.authReducer.menuOpen
  })

  useEffect(() => {
    const handleResize = () => {
      const isExpanded = window.innerWidth > 1400 && window.innerWidth < 1500
      setUIState({
        isExpandedSize: isExpanded,
        isTheUIChanged: isExpanded && menuOpen,
      })
    }

    handleResize()

    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [menuOpen])

  return (
    <>
      <CenterAISummarizeModal
        conversation={conversation}
        visible={visible}
        setVisible={setVisible}
      />
      {aiSummaryMessage && (
        <Tooltip
          destroyTooltipOnHide={true}
          title={getText('WORD_SUMMARY')}
          placement={'left'}
          overlayStyle={{ zIndex: uiState.isTheUIChanged ? '1000' : '-1000' }}
        >
          <MatButton
            icon={<SVGAISummaryIcon />}
            size={'small'}
            buttonText={uiState.isTheUIChanged ? '' : getText('WORD_SUMMARY')}
            typeButton={'white'}
            className={'ai_summary_button'}
            onClick={() => {
              setVisible(true)
            }}
            disabled={conversation.kind === 'gbm'}
          />
        </Tooltip>
      )}
    </>
  )
}

export default CenterAISummerize
