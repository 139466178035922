import React, { useEffect, useState } from 'react'
import { Tooltip } from 'antd'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { getText } from '../../../../lang'
import organizationActions from '../../../../store/modules/organizationActions'
import { formatDateTime } from '../../../../utils'
import SwitchWithText from '../../components/SwitchWithText'
import IconMessages from '../../../../icons/IconUser'
import SVGInfo from '../../../../icons/SVG/SVGInfo'
import SVGTouchpoints from '../../../../icons/SVG/SVGTouchpoints'
import OrganizationFieldItem from '../../components/organizationFieldItem'
import TouchpointFieldItemHeader from './TouchpointFieldItemHeader'

const TouchpointsItem = (props) => {
  const { item, organization, onSave } = props
  const navigate = useNavigate()

  const user = useSelector((state) => {
    return state.authReducer.user
  })

  const [consentedState, setConsentedState] = useState()
  const [forwardLead, setForwardLead] = useState()

  const isFromVoiceAI = item?.source && item.source.startsWith('Voice AI')

  useEffect(() => {
    setConsentedState(item.is_consented)
    setForwardLead(item.enabled_forward)
  }, [])

  const handleChangeConsented = async () => {
    const obj = {
      is_consented: !consentedState,
    }
    let result = await organizationActions.saveUpdateTouchpoint_is_consented(
      obj,
      item._id
    )
    if (result.success) {
      setConsentedState(!consentedState)
      onSave && onSave()
    }
  }

  const handleChangeForwardLead = async () => {
    const obj = {
      enabled: !forwardLead,
    }
    let result = await organizationActions.updateForwardLead(obj, item._id)
    if (result.success) {
      setForwardLead(!forwardLead)
      onSave && onSave()
    }
  }

  return (
    <div className='touchpoints-item'>
      <TouchpointFieldItemHeader
        item={item}
        organization={organization}
        onSave={onSave}
        isFromVoiceAI={isFromVoiceAI}
      />
      <OrganizationFieldItem
        fieldValuesList={[
          {
            icon: IconMessages.date,
            label: getText('TEXT_DATE_OF_CREATION'),
            rightItem: item.createdAt && formatDateTime(item.createdAt),
          },
          {
            icon: <SVGTouchpoints />,
            label: getText('WORD_ID'),
            rightItem: item.form_id,
          },
        ]}
      />
      <SwitchWithText
        noText
        title={getText('WORD_CONSENT')}
        value={consentedState}
        onChangeSwitch={handleChangeConsented}
        disabled={
          item.source === 'inbound' ||
          item.source === 'chat' ||
          item.source === 'FB messenger' ||
          isFromVoiceAI
        }
        className='touchpoint-switch'
        icon={
          <Tooltip placement='top' title={getText('WORD_TURN_ON_TOGGLE_WHEN')}>
            <SVGInfo />
          </Tooltip>
        }
        tooltip={isFromVoiceAI}
        tooltipText={getText(
          'TEXT_YOU_CAN_CHANGE_THE_SETTINGS_ON_THE_ASSISTANT_PAGE'
        )}
      />
      <SwitchWithText
        noText
        title={getText('FORWARD_NEW_LEADS_TO_CRM')}
        value={forwardLead}
        onChangeSwitch={handleChangeForwardLead}
        disabled={
          item.source === 'FB messenger' ||
          !Boolean(user.isSuperAdmin && user.isAdmin) ||
          isFromVoiceAI
        }
        className='touchpoint-switch'
        icon={
          <Tooltip placement='top' title={getText('WORD_TURN_ON_TOGGLE_IF')}>
            <SVGInfo />
          </Tooltip>
        }
        tooltip={!Boolean(user.isSuperAdmin && user.isAdmin) || isFromVoiceAI}
        tooltipText={getText(
          isFromVoiceAI
            ? 'TEXT_YOU_CAN_CHANGE_THE_SETTINGS_ON_THE_ASSISTANT_PAGE'
            : 'TEXT_PLEASE_CONTACT_YOUR_ADMINISTRATOR'
        )}
      />
      <OrganizationFieldItem
        fieldValuesList={[
          {
            label: getText('WORD_ASSIGNED_TO'),
            rightItem:
              item.users.length === 0
                ? getText('WORD_UNASSIGNED')
                : item.users.length === 1
                  ? item.users[0].fullName
                  : getText('WORD_ASSIGNED_USERS_COUNT') + ' ' + item.users.length,
            isUnassigned: item.users.length === 0,
          },
          {
            label: getText('TITLE_DRIP_SEQUENCE'),
            rightItem: item.sequence
              ? item.sequence.name
              : getText('WORD_UNASSIGNED'),
            ...(item.sequence &&
              !isFromVoiceAI && {
                onClick: async () => {
                  const result = await organizationActions.getSingleSequence(
                    item.sequence._id
                  )
                  if (result.success) {
                    navigate(
                      `/organization/settings/${organization._id}/dripsequence/edit`,
                      {
                        state: { sequenceData: result.data },
                      }
                    )
                  }
                },
              }),
            isUnassigned: !item.sequence,
          },
        ]}
      />
    </div>
  )
}

export default TouchpointsItem
