import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Divider, Form } from 'antd'
import { useSelector } from 'react-redux'
import { debounce } from 'lodash'
import { getText, getTextServerError } from '../../../../lang'
import PageFix from '../../../../components/PageFix'
import MatForm from '../../../../components/Form/MatForm'
import { notifyError, notifySuccess } from '../../../../utils/Notify'
import LoadingSpinner from '../../../../components/LoadingSpinner'
import OrganizationLayout from '../../components/organizationNewPage/OrganizationLayout'
import MatSelectSecondary from '../../../../components/MatSelect/MatSelectSecondary'
import OrganizationListTitle from '../../components/organizationNewPage/OrganizationListTitle'
import PageFull from '../../../../components/PageFull'
import organizationActions from '../../../../store/modules/organizationActions'
import authActions from '../../../../store/modules/authActions'
import OrganizationLayoutBlock from '../../components/organizationNewPage/OrganizationLayoutBlock'
import InputFormTextSecondary from '../../../../components/Form/InputFormText/InputFormTextSecondary'
import SwitchForm from '../../../../components/Form/SwitchForm'
import { GET_DATA_FETCH_SIZE } from '../../../../utils'
import MatSwitch from '../../../../components/MatSwitch'
import userActions from '../../../../store/modules/userActions'
import InputFormTextAreaSecondary from '../../../../components/Form/InputFormTextArea/InputFormTextAreaSecondary'
import { getUsersListIds } from '../../../broadcast/CreateCampaign/CreateSegment/utils'
import SelectCheckedSecondary from '../../../../components/Form/SelectChecked/SelectCheckedSecondary'
import ConversationAIAutoReply from './ConversationAIAutoReply'
import ConversationAIIntentConfiguration from './ConversationAIIntentConfiguration'
import ConversationAITagUsersSwitch from './ConversationAITagUsersSwitch'
import './ConversationalAI.scss'
import ConversationPreview from './ConversationPreview'
import ConversationAICommunicationChannel from './ConversationAICommunicationChannel'

const ConversationalAI = (props) => {
  const { organization, location, setLoading } = props

  const superhumanConfig = organization.superhumanConfig || false

  const user = useSelector((state) => {
    return state.authReducer.user
  })

  const [enabledSuperhuman, setEnabledSuperhuman] = useState(
    organization.superhumanConfig ? organization.superhumanConfig.enabled : false
  )
  const [loadingData, setLoadingData] = useState(false)
  const [page, setPage] = useState(0)
  const [assignSearchKeyword, setAssignSearchKeyword] = useState('')
  const [usersList, setUsersList] = useState({
    list: [],
    total: 0,
  })

  const [form] = Form.useForm()
  const [formValues, setFormValues] = useState({})

  const intentsList = useMemo(
    () => [
      {
        label: getText('WORD_APPOINTMENT.ALREADY_BOUGHT'),
        value: 'appointment.already_bought',
      },
      {
        label: getText('WORD_APPOINTMENT.ALREADY_CANCELLED_APPOINTMENT'),
        value: 'appointment.already_cancelled_appointment',
      },
      {
        label: getText('WORD_APPOINTMENT.ALREADY_SCHEDULED'),
        value: 'appointment.already_scheduled',
      },
      {
        label: getText('WORD_APPOINTMENT.CANCEL_APPOINTMENT'),
        value: 'appointment.cancel_appointment',
      },
      {
        label: getText('WORD_APPOINTMENT.SERVICE_APPOINTMENT'),
        value: 'appointment.service_appointment',
      },
      { label: getText('WORD_APPROVAL'), value: 'approval' },
      { label: getText('WORD_GENERAL_AVAILABILITY'), value: 'availability' },
      {
        label: getText('WORD_AVAILABILITY.NOT_SURE_OF_VEHICLE'),
        value: 'availability.not_sure_of_vehicle',
      },
      {
        label: getText('WORD_AVAILABILITY.UPDATE_ON_VEHICLE'),
        value: 'availability.update_on_vehicle',
      },
      { label: getText('WORD_APPOINTMENT_CONFIRMATION'), value: 'confirmed' },
      { label: getText('WORD_DEALER_FEE'), value: 'dealer_fee' },
      { label: getText('WORD_DOWN_PAYMENT'), value: 'down_payment' },
      { label: getText('WORD_GENERAL_FINANCE'), value: 'finance' },
      { label: getText('WORD_FINANCE.BAD_CREDIT'), value: 'finance.bad_credit' },
      { label: getText('WORD_FINANCE.INSURANCE'), value: 'finance.insurance' },
      {
        label: getText('WORD_FINANCE.REQUIRED_DOCUMENTS'),
        value: 'finance.required_documents',
      },
      { label: getText('WORD_HISTORY_REPORT'), value: 'history_report' },
      { label: getText('WORD_HOLD_TRANSFER'), value: 'hold_transfer' },
      {
        label: getText('WORD_HOURS_AND_LOCATION.LOCATION'),
        value: 'hours_and_location.location',
      },
      {
        label: getText('WORD_HOURS_AND_LOCATION.WORKING_HOURS'),
        value: 'hours_and_location.working_hours',
      },
      { label: getText('WORD_NEGOTIATIONS'), value: 'negotiations' },
      { label: getText('WORD_PAYMENT'), value: 'payment' },
      { label: getText('WORD_PRICE'), value: 'price' },
      {
        label: getText('WORD_PROCESS.ALREADY_DISCUSSED'),
        value: 'process.already_discussed',
      },
      { label: getText('WORD_PROCESS.CALLBACK'), value: 'process.callback' },
      {
        label: getText('WORD_PROCESS.CUSTOMER_HAS_QUERIES'),
        value: 'process.customer_has_queries',
      },
      {
        label: getText('WORD_PROCESS.IN_PLACE_WITH_REPRESENTATIVE'),
        value: 'process.in_place_with_representative',
      },
      {
        label: getText('WORD_PROCESS.NOT_INTERESTED'),
        value: 'process.not_interested',
      },
      {
        label: getText('WORD_PROCESS.WHO_DO_I_SPEAK_TO'),
        value: 'process.who_do_I_speak_to',
      },
      { label: getText('WORD_PROCESS.WHO_IS_THIS'), value: 'process.who_is_this' },
      { label: getText('WORD_REAPPOINTMENT'), value: 'reappointment' },
      { label: getText('WORD_SHIPPING'), value: 'shipping' },
      { label: getText('WORD_TRADES'), value: 'trades' },
      { label: getText('WORD_VEHICLE_OPTIONS'), value: 'vehicle_options' },
      {
        label: getText('WORD_VEHICLE_OPTIONS.PICTURES'),
        value: 'vehicle_options.pictures',
      },
      {
        label: getText('WORD_VEHICLE_RECOMMENDATIONS'),
        value: 'vehicle_recommendations',
      },
      { label: getText('WORD_GENERAL_APPOINTMENT_QUERIES'), value: 'appointment' },
      {
        label: getText('WORD_FINANCE.ONLINE_PROCESSING'),
        value: 'finance.online_processing',
      },
      {
        label: getText('WORD_FINANCE.CREDIT_SCORE'),
        value: 'finance.credit_score',
      },
      {
        label: getText('WORD_FINANCE.ALREADY_SUBMITTED_APPLICATION'),
        value: 'finance.already_submitted_application',
      },
      {
        label: getText('WORD_TIRE_ROTATION'),
        value: 'tire_rotation',
      },
      {
        label: getText('WORD_PARTS_AVAILABILITY'),
        value: 'parts_availability',
      },
      {
        label: getText('WORD_PART_PRICE'),
        value: 'part_price',
      },
      {
        label: getText('WORD_CAR_WASH'),
        value: 'car_wash',
      },
      {
        label: getText('WORD_OIL_CHANGE'),
        value: 'oil_change',
      },
    ],
    []
  )

  const onFormValuesChange = (_, allValues) => {
    const data = formatData(allValues)
    setFormValues(data)
  }

  useEffect(() => {
    props.setFooterButtons && props.setFooterButtons(['save', 'cancel'])
    props.setPageTitle(getText('AI_BOT_SUPERHUMAN'))
  }, [])

  const intentsSelection = Form.useWatch(['customized_intents'], form)

  useEffect(() => {
    if (intentsSelection) {
      const data = formatData(form.getFieldsValue())
      setFormValues(data)
    }
  }, [form, intentsSelection])

  const searchUsers = useCallback(async () => {
    setLoadingData(true)
    const result = await userActions.getUsersForAssignUnassign(
      page,
      GET_DATA_FETCH_SIZE,
      organization._id,
      assignSearchKeyword
    )
    if (result.success) {
      const newListUsers =
        page > 0 ? [...usersList.list, ...result.data] : result.data
      setUsersList({
        list: getUsersListIds(newListUsers),
        total: result.max,
      })
    }
    setLoadingData(false)
  }, [assignSearchKeyword, page])

  useEffect(() => {
    searchUsers()
  }, [searchUsers])

  function customizeUsers(users) {
    users.forEach((user) => {
      user.label = `${user.firstName} ${user.lastName}`
      user.value = user._id
    })
  }

  const getSuperConfigData = useCallback(async () => {
    const result = await organizationActions.getSuperhumanConfig(organization._id)
    if (result.success) {
      customizeUsers(
        result.data.customized_intents.flatMap((item) => item.users_to_tag)
      )
      customizeUsers(
        result.data.tagging_configuration.tag_users_for_every_message.users_to_tag
      )
      form.setFieldsValue({ ...result.data })
    }
  }, [])

  useEffect(() => {
    getSuperConfigData()
  }, [getSuperConfigData])

  const superhumanMessageSwitch = Form.useWatch(
    ['tagging_configuration', 'tag_users_for_every_message', 'enabled'],
    form
  )

  const debouncedChange = useCallback(
    debounce((options) => setAssignSearchKeyword(options), 1000),
    []
  )

  const formatData = (values) => {
    return {
      superhumanConfig: {
        enabled: enabledSuperhuman,
        ...values,
        customized_intents: !intentsSelection.length
          ? values.customized_intents
          : values.customized_intents.map((item) => {
              return {
                ...item,
                users_to_tag: item.users_to_tag.map((item2) => {
                  if (item2._id) {
                    return item2._id
                  } else {
                    return item2
                  }
                }),
              }
            }),
        tagging_configuration: {
          ...values.tagging_configuration,
          tag_users_for_every_message: {
            enabled:
              values.tagging_configuration.tag_users_for_every_message.enabled,
            users_to_tag: superhumanMessageSwitch
              ? values.tagging_configuration.tag_users_for_every_message.users_to_tag.map(
                  (item) => {
                    if (item._id) {
                      return item._id
                    } else {
                      return item
                    }
                  }
                )
              : [],
          },
        },
      },
    }
  }

  const onFinish = async (values) => {
    setLoading(true)
    const obj = formatData(values)
    const result = await organizationActions.updateSuperhumanConfig(
      organization._id,
      obj
    )
    if (result.success) {
      notifySuccess(
        !organization._id
          ? getText('TEXT_ORGANIZATION_WAS_CREATED_SUCCESSFULLY')
          : getText('TEXT_ORGANIZATION_WAS_SAVED_SUCCESSFULLY')
      )
      organization.superhumanConfig.enabled = result.data.enabled
      const currentOrganization = authActions.getOrganization()
      if (organization._id === currentOrganization._id) {
        authActions.setOrganization(organization)
      }
    } else {
      notifyError(getTextServerError(result.errMsg))
    }
    setLoading(false)
  }

  const onFinishFailed = (errorInfo) => {
    if (errorInfo.errorFields.length > 0) {
      notifyError(errorInfo.errorFields[0].errors[0])
    }
  }
  if (!organization._id) {
    return <LoadingSpinner />
  }

  const showErrorMessage = (inputName) => {
    return getText('ERRORS_INPUT_CONVERSATIONAI').replace('[name]', inputName)
  }

  return (
    <OrganizationLayout className={'conversational-page-ai-wrapper'}>
      <OrganizationListTitle
        title={getText('AI_BOT_SUPERHUMAN')}
        titleNextTo={
          <MatSwitch
            disabled={!Boolean(user.isSuperAdmin)}
            value={enabledSuperhuman}
            onChange={async () => setEnabledSuperhuman(!enabledSuperhuman)}
          />
        }
        subtitle={getText('TEXT_UPDATE_ORGANIZATION_CONVERSATION_AI')}
      />
      <MatForm
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        onValuesChange={onFormValuesChange}
        initialValues={{
          working_hours: {
            in_range: {
              enabled:
                superhumanConfig && superhumanConfig.working_hours
                  ? superhumanConfig.working_hours.in_range.enabled
                  : false,
              delay_minutes:
                superhumanConfig && superhumanConfig.working_hours
                  ? superhumanConfig.working_hours.in_range.delay_minutes
                  : 1,
            },
            off_range: {
              enabled:
                superhumanConfig && superhumanConfig.working_hours
                  ? superhumanConfig.working_hours.off_range.enabled
                  : false,
              delay_minutes:
                superhumanConfig && superhumanConfig.working_hours
                  ? superhumanConfig.working_hours.off_range.delay_minutes
                  : 1,
            },
          },
          options: {
            agent_name:
              superhumanConfig && superhumanConfig.options
                ? superhumanConfig.options.agent_name
                : '',
            agent_role:
              superhumanConfig && superhumanConfig.options
                ? superhumanConfig.options.agent_role
                : '',
            company_type:
              superhumanConfig && superhumanConfig.options
                ? superhumanConfig.options.company_type
                : '',
            inventory_enabled:
              superhumanConfig && superhumanConfig.options
                ? superhumanConfig.options.inventory_enabled
                : false,
          },
          tagging_configuration: {
            tag_users_for_every_message: {
              enabled:
                superhumanConfig && superhumanConfig.tagging_configuration
                  ? superhumanConfig.tagging_configuration
                      .tag_users_for_every_message.enabled
                  : false,
              users_to_tag:
                superhumanConfig && superhumanConfig.tagging_configuration
                  ? superhumanConfig.tagging_configuration
                      .tag_users_for_every_message.users_to_tag
                  : [],
            },
            for_assigned_conversations:
              superhumanConfig && superhumanConfig.tagging_configuration
                ? superhumanConfig.tagging_configuration.for_assigned_conversations
                : '',
            tag_within_hours:
              superhumanConfig && superhumanConfig.tagging_configuration
                ? superhumanConfig.tagging_configuration.tag_within_hours
                : false,
            tag_outside_hours:
              superhumanConfig && superhumanConfig.tagging_configuration
                ? superhumanConfig.tagging_configuration.tag_outside_hours
                : false,
          },
          customized_intents:
            superhumanConfig && superhumanConfig.customized_intents
              ? superhumanConfig.customized_intents
              : [],
          instructions:
            superhumanConfig && superhumanConfig.instructions
              ? superhumanConfig && superhumanConfig.instructions
              : {},
        }}
      >
        <PageFull className={'conversational-page-ai-body'}>
          <PageFix>
            {Boolean(user.isSuperAdmin || user.isAdmin) && (
              <OrganizationLayoutBlock>
                <ConversationAIAutoReply
                  name={['working_hours', 'in_range', 'delay_minutes']}
                  switchName={['working_hours', 'in_range', 'enabled']}
                  title={getText('WORD_OPENING_HOURS')}
                  enabledSuperhuman={enabledSuperhuman}
                />
                <ConversationAIAutoReply
                  name={['working_hours', 'off_range', 'delay_minutes']}
                  switchName={['working_hours', 'off_range', 'enabled']}
                  title={getText('WORD_AFTER_HOURS')}
                  enabledSuperhuman={enabledSuperhuman}
                />
                <Divider
                  plain
                  style={{
                    margin: '16px 0',
                    backgroundColor: 'rgba(91, 102, 234, 0.1)',
                  }}
                />
                <ConversationAICommunicationChannel />
                <Divider
                  plain
                  style={{
                    margin: '16px 0',
                    backgroundColor: 'rgba(91, 102, 234, 0.1)',
                  }}
                />
                <InputFormTextSecondary
                  formStyle={{ marginBottom: '0' }}
                  name={['options', 'agent_name']}
                  label={getText('WORD_AGENT_NAME')}
                  maxLength={50}
                />
                {!Boolean(user.isSuperAdmin) && (
                  <InputFormTextSecondary
                    formStyle={{ marginBottom: '0', marginTop: '20px' }}
                    name={['options', 'agent_role']}
                    label={getText('WORD_AGENT_ROLE')}
                    maxLength={50}
                    message={showErrorMessage(getText('WORD_AGENT_ROLE'))}
                    required
                  />
                )}
              </OrganizationLayoutBlock>
            )}
            {Boolean(user.isSuperAdmin && user.isAdmin) && (
              <OrganizationLayoutBlock>
                <SwitchForm
                  rowClassName='conversation-ai-switch'
                  name={['options', 'inventory_enabled']}
                  text={getText('TEXT_IF_THE_INVENTORY_INTEGRATIONS_IS_ENABLED')}
                />
                <SelectCheckedSecondary
                  name={['options', 'company_type']}
                  label={getText('WORD_COMPANY_TYPE')}
                  showSearch={false}
                  multipleNewStyle
                  option={[
                    {
                      label: getText('LABEL_CAR_DEALERSHIP'),
                      value: 'car dealership',
                    },
                    {
                      label: getText('LABEL_SOLAR_PANEL_COMPANY'),
                      value: 'solar panel company',
                    },
                  ]}
                  allowClear={false}
                  placeholder={getText('WORD_COMPANY_TYPE')}
                  message={showErrorMessage(getText('WORD_COMPANY_TYPE'))}
                  required
                />
                <InputFormTextSecondary
                  formStyle={{ marginBottom: '0' }}
                  name={['options', 'agent_role']}
                  label={getText('WORD_AGENT_ROLE')}
                  maxLength={50}
                  message={showErrorMessage(getText('WORD_AGENT_ROLE'))}
                  required
                />
              </OrganizationLayoutBlock>
            )}
            {Boolean(user.isSuperAdmin || user.isAdmin) && (
              <React.Fragment>
                <OrganizationLayoutBlock
                  label={getText('TEXT_GENERAL_TAGGING_CONFIGURATION')}
                >
                  <SwitchForm
                    rowClassName='conversation-ai-switch'
                    name={[
                      'tagging_configuration',
                      'tag_users_for_every_message',
                      'enabled',
                    ]}
                    text={getText(
                      'TEXT_ALLOW_TAGGING_USERS_FOR_EVERY_SUPERHUMAN_MESSAGE'
                    )}
                  />
                  {superhumanMessageSwitch && (
                    <SelectCheckedSecondary
                      name={[
                        'tagging_configuration',
                        'tag_users_for_every_message',
                        'users_to_tag',
                      ]}
                      label={''}
                      showSearch={true}
                      mode='multiple'
                      loading={loadingData}
                      multipleNewStyle
                      option={usersList.list || []}
                      allowClear={false}
                      placeholder={getText('ACTION_SELECT_USER')}
                      onSearch={debouncedChange}
                      onBlur={() => {
                        setAssignSearchKeyword('')
                      }}
                      onChange={() => {
                        setAssignSearchKeyword('')
                      }}
                      onLoadMore={() => {
                        if (
                          usersList.list.length < usersList.total &&
                          !loadingData
                        ) {
                          setPage(page + 1)
                        }
                      }}
                      message={getText('TEXT_PLEASE_CHOOSE_AT_LEAST_ONE_USER')}
                      required
                    />
                  )}
                  <Divider
                    plain
                    style={{
                      margin: '16px 0',
                      backgroundColor: 'rgba(91, 102, 234, 0.1)',
                    }}
                  />
                  <SelectCheckedSecondary
                    name={['tagging_configuration', 'for_assigned_conversations']}
                    label={getText('WORD_FOR_ASSIGNED_CONVERSATION')}
                    showSearch={false}
                    multipleNewStyle
                    option={[
                      {
                        label: getText('TEXT_TAG_ONLY_ASSIGNED_USERS'),
                        value: 'tag_assigned_users_only',
                      },
                      {
                        label: getText('TEXT_TAG_ONLY_USERS_FROM_LIST'),
                        value: 'tag_provided_users_only',
                      },
                      {
                        label: getText('TEXT_TAG_BOTH'),
                        value: 'tag_both',
                      },
                    ]}
                    allowClear={true}
                    placeholder={getText('WORD_FOR_ASSIGNED_CONVERSATION')}
                    message={getText('ERR_MSG_TAG_WORKFLOW')}
                    required
                  />
                  <Divider
                    plain
                    style={{
                      margin: '16px 0',
                      backgroundColor: 'rgba(91, 102, 234, 0.1)',
                    }}
                  />
                  <ConversationAITagUsersSwitch />
                </OrganizationLayoutBlock>
                <OrganizationLayoutBlock
                  label={getText('TEXT_INTENT_CONFIGURATION')}
                >
                  <Form.List name={'customized_intents'}>
                    {(fields, { add, remove }) => (
                      <>
                        <MatSelectSecondary
                          dropdownClassName={'mat-form-select-dropdown'}
                          className={'mat-select-secondary'}
                          label={''}
                          showSearch={true}
                          placeholder={getText('LABEL_SELECT_INTENTS')}
                          mode='multiple'
                          multipleNewStyle
                          options={intentsList}
                          onClear={() => {
                            form.setFieldsValue({
                              customized_intents: [],
                            })
                          }}
                          value={
                            intentsSelection && intentsSelection.length
                              ? intentsSelection.map((item) => item.intent_id)
                              : []
                          }
                          onSelectItem={(item) => {
                            add({
                              instructions: '',
                              intent_id: item.value,
                              is_excluded: false,
                              users_to_tag: [],
                            })
                          }}
                          onDeselect={(val) => {
                            const findIndex = intentsSelection.findIndex(
                              (item) => item.intent_id === val
                            )
                            remove(findIndex)
                          }}
                        />
                        {fields.map((field, index) => {
                          const isCustomized_intents =
                            form.getFieldsValue().customized_intents &&
                            form.getFieldsValue().customized_intents[field.name]
                          let intentName
                          if (isCustomized_intents) {
                            intentName = intentsList.find((findItem) => {
                              return (
                                findItem.value === isCustomized_intents.intent_id
                              )
                            })
                          }
                          return (
                            <ConversationAIIntentConfiguration
                              key={index}
                              form={form}
                              field={field}
                              intentName={intentName}
                              usersList={usersList.list || []}
                              loading={loadingData}
                              onSearch={debouncedChange}
                              onBlur={() => {
                                setAssignSearchKeyword('')
                              }}
                              onChange={() => {
                                setAssignSearchKeyword('')
                              }}
                              onLoadMore={() => {
                                if (
                                  usersList.list.length < usersList.total &&
                                  !loadingData
                                ) {
                                  setPage(page + 1)
                                }
                              }}
                              onRemove={() => {
                                remove(field.name)
                              }}
                            />
                          )
                        })}
                      </>
                    )}
                  </Form.List>
                </OrganizationLayoutBlock>
                <OrganizationLayoutBlock
                  label={getText('WORD_GENERAL_INSTRUCTIONS')}
                >
                  <InputFormTextAreaSecondary
                    name={['instructions', 'general']}
                    label={''}
                    placeholder={getText('TEXT_TYPE_YOUR_INSTRUCTIONS')}
                    maxLength={25000}
                    autoSize={{ minRows: 5, maxRows: 20 }}
                    showCount
                  />
                </OrganizationLayoutBlock>
                <OrganizationLayoutBlock
                  label={getText('WORD_INSTRUCTIONS_TO_HUMAN_EXPERT')}
                >
                  <InputFormTextAreaSecondary
                    name={['instructions', 'human']}
                    label={''}
                    placeholder={getText('TEXT_TYPE_YOUR_INSTRUCTIONS')}
                    maxLength={25000}
                    autoSize={{ minRows: 5, maxRows: 20 }}
                    showCount
                  />
                </OrganizationLayoutBlock>
                <OrganizationLayoutBlock label={getText('WORD_CURRENT_PROMOTIONS')}>
                  <InputFormTextAreaSecondary
                    name={['instructions', 'promotions']}
                    label={''}
                    placeholder={getText('TEXT_TYPE_YOUR_CURRENT_PROMOTIONS')}
                    maxLength={25000}
                    autoSize={{ minRows: 5, maxRows: 20 }}
                    showCount
                  />
                </OrganizationLayoutBlock>
              </React.Fragment>
            )}
          </PageFix>
          <PageFix>
            <ConversationPreview
              organizationId={organization._id}
              locationId={location.id}
              config={formValues.superhumanConfig || formValues}
            />
          </PageFix>
        </PageFull>
      </MatForm>
    </OrganizationLayout>
  )
}

export default ConversationalAI
