import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { Form, Modal } from 'antd'
import { getText, getTextServerError } from '../../../../lang'
import { notifyError, notifySuccess } from '../../../../utils/Notify'
import {
  checkFormatPhoneNumber,
  checkPhoneFormatValidationDuringSaving,
  getInitialCountry,
} from '../../../../utils'
import organizationActions from '../../../../store/modules/organizationActions'
import SelectTimeZone from '../../../../components/api/SelectTimeZone'
import MatForm from '../../../../components/Form/MatForm'
import ConfirmPopup from '../../../../components/ConfirmPopup'
import LoadingSpinner from '../../../../components/LoadingSpinner'
import authActions from '../../../../store/modules/authActions'
import MyDefaultPageLayout from '../../../../components/MyDefaultPageLayout/MyDefaultPageLayout'
import MyDefaultSwitch from '../../../../components/MyDefaultSwitch/MyDefaultSwitch'
import MyDefaultBlock from '../../../../components/MyDefaultBlock/MyDefaultBlock'
import MyDefaultInputText from '../../../../components/MyDefaultInputText/MyDefaultInputText'
import MyDefaultPhoneInput from '../../../../components/MyDefaultPhoneInput/MyDefaultPhoneInput'
import StripeButton from './StripeButton'
import LanguagesSection from './LanguagesSection'

const languages = ['en', 'fr', 'sp']

const General = (props) => {
  const { user = {}, organization, setLoading } = props
  const { accessibleOrganizations = [] } = user
  const [enabled, setEnabled] = useState(organization.enabled)
  const [enabledBroadcast, setEnabledBroadcast] = useState(
    organization.enable_broadcast
  )
  const [defaultLanguage, setDefaultLanguage] = useState(
    organization.defaultLanguage
  )
  const [listLanguage, setListLanguage] = useState(organization.languages || [])
  const [visible, setVisible] = useState(false)
  const [enable2fa, setEnable2fa] = useState(organization.mfaConfig.enabled || false)
  const [form] = Form.useForm()

  useEffect(() => {
    props.setFooterButtons && props.setFooterButtons(['save', 'cancel'])
    props.setPageTitle(getText('WORD_GENERAL'))
    setEnabled(organization.enabled)
    setEnabledBroadcast(organization.enable_broadcast)
    setDefaultLanguage(organization.defaultLanguage)
    setListLanguage(organization.languages || [])
  }, [])

  const onBlurMessagingSid = (event) => {
    const { twilioMessaging = {} } = organization
    const { value: inputValue } = event.target || {}

    if (inputValue === twilioMessaging.messagingServiceSid) {
      return
    }

    Modal.confirm({
      title: getText('TEXT_FOR_MESSAGING_SID_CONFIRM'),
      onCancel() {
        form.setFieldsValue({
          messagingServiceSid: twilioMessaging.messagingServiceSid,
        })
      },
      onOk() {
        const data = form.getFieldsValue()
        onFinish(data)
      },
      okText: getText('WORD_YES'),
      cancelText: getText('ACTION_CANCEL'),
      okButtonProps: {
        type: 'primary',
        className: 'mat-btn mat-btn-primary',
      },
      cancelButtonProps: {
        type: 'cancel',
        className: 'mat-btn',
      },
    })
  }

  const onFinish = async (values) => {
    setLoading(true)
    values.languages = listLanguage
    values.defaultLanguage = defaultLanguage
    values.enabled = enabled
    if (values.mfaConfig) {
      delete values.mfaConfig
    }
    const phoneCheckingData = checkPhoneFormatValidationDuringSaving(
      values.twilio_number,
      values.callForwardingNumber
    )
    if (typeof phoneCheckingData === 'object') {
      values.twilio_number = phoneCheckingData.twilio_number
      values.callForwardingNumber = phoneCheckingData.callForwardingNumber
    } else {
      setLoading(false)
      return
    }
    let result
    if (!organization._id) {
      result = await organizationActions.saveNew(values)
    } else {
      result = await organizationActions.saveUpdate(values, organization._id)
    }
    if (result.success) {
      let org = authActions.getOrganization()
      const result2FA = await organizationActions.update2fa(
        enable2fa,
        organization._id
      )
      if (result2FA.success) {
        result.data.mfaConfig = result2FA.data
        setEnable2fa(result2FA.data.enabled)
      } else {
        notifyError(getTextServerError(result.errMsg))
      }
      notifySuccess(
        !organization._id
          ? getText('TEXT_ORGANIZATION_WAS_CREATED_SUCCESSFULLY')
          : getText('TEXT_ORGANIZATION_WAS_SAVED_SUCCESSFULLY')
      )
      props.setOrganization(result.data)
      if (result.data._id === org._id) {
        await authActions.setOrganization(result.data)
      }
      const newPhoneNumber = checkFormatPhoneNumber(result.data.twilio_number)
      const newCallForwardingNumber = checkFormatPhoneNumber(
        result.data.callForwardingNumber
      )
      form.setFieldsValue({
        twilio_number: newPhoneNumber,
        callForwardingNumber: newCallForwardingNumber,
      })
    } else {
      let messagingServiceSid = values.messagingServiceSid || ''
      notifyError(getTextServerError(result.errMsg, messagingServiceSid))
    }
    setLoading(false)
  }

  const onFinishFailed = (errorInfo) => {
    if (errorInfo.errorFields.length > 0) {
      notifyError(errorInfo.errorFields[0].errors[0])
    }
  }
  if (!organization._id) {
    return <LoadingSpinner />
  }

  return (
    <MyDefaultPageLayout disableHeader>
      <MatForm
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={{
          name: organization.name || '',
          twilio_number:
            !_.isEmpty(organization) && organization.twilio_number
              ? checkFormatPhoneNumber(organization.twilio_number, true)
              : '',
          messagingServiceSid:
            !_.isEmpty(organization) &&
            organization.hasOwnProperty('twilioMessaging')
              ? organization.twilioMessaging.messagingServiceSid
              : '',
          timezone: organization.timezone || '',
          defaultLanguage: organization.defaultLanguage || '',
          enable_broadcast: organization.enable_broadcast || false,
          mfaConfig: organization.mfaConfig || {},
          callForwardingNumber:
            !_.isEmpty(organization) && organization.callForwardingNumber
              ? checkFormatPhoneNumber(organization.callForwardingNumber, true)
              : '',
        }}
      >
        <MyDefaultBlock>
          <ConfirmPopup
            title={getText('TEXT_ORG_POPUP_MESSAGE')}
            visible={visible}
            placement={'topRight'}
            onVisibleChange={() => {
              setVisible(!visible)
            }}
            okText={getText('WORD_YES')}
            cancelText={getText('WORD_NO')}
            onCancel={() => {
              if (enabled) {
                setEnabled(true)
              }
            }}
            onConfirm={() => {
              setEnabled(false)
            }}
            trigger={
              <MyDefaultSwitch
                // isForm
                title={getText('TEXT_ORGANIZATION_IS_ACTIVE')}
                isFirst
                titleStyle={{ fontSize: 14 }}
                noText
                value={enabled}
                onChangeSwitch={() => {
                  setEnabled(!enabled)
                  if (!enabled) {
                    form.validateFields()
                  }
                  if (enabled) {
                    setVisible(!visible)
                    setEnabled(true)
                  }
                }}
              />
            }
          />
          <MyDefaultInputText
            isForm
            name='name'
            label={getText('LABEL_ORGANIZATION_NAME')}
            required
            isFirst
          />
          <MyDefaultPhoneInput
            isForm
            initialCountry={getInitialCountry(organization.twilio_number)}
            name='twilio_number'
            label={getText('WORD_CHAT_')}
            onChange={(value) => {
              form.setFieldsValue({ twilio_number: value })
            }}
            required
          />
          {user.isSuperAdmin && !accessibleOrganizations.length && (
            <MyDefaultInputText
              isForm
              name='messagingServiceSid'
              label={getText('LABEL_TWILIO_MESSAGING_SID')}
              onBlur={onBlurMessagingSid}
            />
          )}
          <MyDefaultPhoneInput
            isForm
            initialCountry={getInitialCountry(
              organization.callForwardingNumber || organization.twilio_number
            )}
            name='callForwardingNumber'
            label={getText('TEXT_CALLS_FORWARDED_TO')}
            onChange={(value) => {
              form.setFieldsValue({ callForwardingNumber: value })
            }}
          />
          <SelectTimeZone
            isForm
            name='timezone'
            label={getText('WORD_TIME_ZONE')}
            showSearch={true}
            organization={organization}
            required
            isLast
          />
        </MyDefaultBlock>

        <MyDefaultBlock enableMarginTop title={getText('WORD_LANGUAGE')}>
          <LanguagesSection
            defaultListLanguage={
              user.isSuperAdmin || user.isAdmin ? languages : listLanguage
            }
            defaultLanguage={defaultLanguage}
            listLanguage={listLanguage}
            disabled={!user.isSuperAdmin && !user.isAdmin}
            onSetAsDefaultClick={(lng) => {
              setDefaultLanguage(lng)
              form && form.setFieldsValue({ defaultLanguage: lng })
            }}
            onChangeSwitch={(lng) => {
              let list = listLanguage
              list.includes(lng)
                ? (list = list.filter((lang) => {
                    return lang !== lng
                  }))
                : list.push(lng)
              setListLanguage(list)
            }}
          />
        </MyDefaultBlock>
        <MyDefaultBlock enableMarginTop>
          <MyDefaultSwitch
            name='enable_broadcast'
            title={getText('TEXT_ACCESS_TO_BROADCASTING_FEATURE')}
            isForm
            isFirst
            disableMargins
            isLast={!user.isSuperAdmin}
            titleStyle={{ fontSize: 14 }}
            noText
            value={enabled}
            onChangeSwitch={() => {
              setEnabledBroadcast(!enabledBroadcast)
              if (!enabledBroadcast) {
                form.validateFields()
              }
            }}
          />
          {user.isSuperAdmin && (
            <MyDefaultSwitch
              name={['mfaConfig', 'enabled']}
              isLast
              titleStyle={{ fontSize: 14 }}
              noText
              value={enable2fa}
              title={`${enable2fa ? getText('WORD_DISABLE') : getText('WORD_ENABLE')} 2FA`}
              onChangeSwitch={() => {
                setEnable2fa(!enable2fa)
                if (!enable2fa) {
                  form.validateFields()
                }
              }}
            />
          )}
        </MyDefaultBlock>
        {organization.enable_payments && (
          <StripeButton organization={organization} />
        )}
      </MatForm>
    </MyDefaultPageLayout>
  )
}

export default General
