import { notifyError } from '../../utils/Notify'
import {
  fetchFromUrlPOSTAsync,
  fetchFromUrlGETAsync,
  uploadAndFetchPOStFile,
  fetchFromUrlPATCHAsync,
  fetchFromUrlPUTAsync,
} from '../../utils/UrlHelper'
import authActions from './authActions'

const urlCampaigns = '/campaigns'
const urlDeliverabilityScore = '/messages/deliverabilityScore'

const fs = {
  save: async (obj) => {
    const urlParams = Object.keys(obj).map((key) => {
      return { key, value: obj[key] }
    })
    const result = await fetchFromUrlPOSTAsync(
      '/campaigns/filter-customers',
      urlParams
    )
    return result
  },
  saveWithFile: async (obj) => {
    let result = await uploadAndFetchPOStFile('/campaigns/upload-customers', obj)
    return result
  },
  cancel: async (id) => {
    const result = await fetchFromUrlPATCHAsync(urlCampaigns + '/' + id, [])
    return result
  },
  getDeliverabilityScore: async (message, conversation_id) => {
    const urlParams = [
      { key: 'message', value: message },
      { key: 'is_broadcast_message', value: true },
      { key: 'message_rank', value: 1 },
    ]
    // urlParams.push({ key: 'is_dev_db', value: Boolean(!['STAGE', 'RELEASE', 'PROD'].includes(process.env.REACT_APP_ENV)) })

    const result = await fetchFromUrlGETAsync(
      urlDeliverabilityScore,
      urlParams,
      10000
    )
    return result
  },
  getPageList: async (start, range, orgId, paginationOptions = {}) => {
    const urlParams = [
      { key: 'page', value: start + 1 },
      { key: 'perPage', value: range },
    ]

    if (paginationOptions.statusFilter) {
      urlParams.push({ key: 'status', value: paginationOptions.statusFilter.value })
    }
    if (paginationOptions.search) {
      urlParams.push({ key: 'search', value: paginationOptions.search })
    }
    if (paginationOptions.creatorFilter) {
      urlParams.push({
        key: 'createdBy',
        value: paginationOptions.creatorFilter.value,
      })
    }
    if (paginationOptions.sortByName) {
      urlParams.push({ key: 'sortByName', value: paginationOptions.sortByName })
    }
    if (paginationOptions.dateFilter) {
      if (paginationOptions.dateFilter.value[0]) {
        urlParams.push({
          key: 'date_gte',
          value: paginationOptions.dateFilter.value[0],
        })
      }
      if (paginationOptions.dateFilter.value[1]) {
        urlParams.push({
          key: 'date_lte',
          value: paginationOptions.dateFilter.value[1],
        })
      }
    }

    if (orgId) {
      urlParams.push({ key: '_organization_id', value: orgId })
    }
    const result = await fetchFromUrlGETAsync(urlCampaigns, urlParams)
    if (result.success) {
      result.max = result.data.total_items
      result.data = result.data.campaigns
    }
    return result
  },

  getUnavailableTime: async (orgId) => {
    const result = await fetchFromUrlGETAsync(
      urlCampaigns + '/unavailable-time?_organization_id=' + orgId,
      []
    )
    return result
  },

  getAIGeneratedContent: async (subject, dynamicTags) => {
    const urlParams = [
      { key: 'subject', value: subject },
      { key: 'dynamicTags', value: dynamicTags },
    ]

    const result = await fetchFromUrlPOSTAsync(
      '/campaigns/generate-message',
      urlParams
    )

    return result
  },

  updateBroadcastMessage: async (id, message) => {
    const urlParams = [{ key: 'message', value: message }]
    const result = await fetchFromUrlPUTAsync(urlCampaigns + '/' + id, urlParams)
    return result
  },

  downloadOptedOutCustomer: async (_id, name) => {
    try {
      const headers = {
        Authorization: 'Bearer ' + authActions.getTokenData().accessToken,
      }
      const response = await fetch(
        `https://${process.env.REACT_APP_DOMAIN}/api/v1/campaigns/${_id}/download/opted_out`,
        {
          method: 'GET',
          headers: headers,
        }
      )
      const blob = await response.blob()

      const url = URL.createObjectURL(blob)

      const anchor = document.createElement('a')
      anchor.href = url
      anchor.download = name

      document.body.appendChild(anchor)
      anchor.click()

      URL.revokeObjectURL(url)
    } catch (error) {
      notifyError(error)
    }
  },
}

const loc = {}

const broadcastingActions = Object.assign(fs, loc)

export default broadcastingActions
