import React, { useState } from 'react'
import { Flex } from 'antd'
import SVGHamburgerFilterIcon from '../../icons/SVG/SVGHamburgerFilterIcon.js'
import MyDefaultButton from '../MyDefaultButton/MyDefaultButton'
import MyDefaultSearchInput from '../MyDefaultSearchInput/MyDefaultSearchInput'
import MyDefaultSelect from '../MyDefaultSelect/MyDefaultSelect'
import MyDefaultDrawer from '../MyDefaultDrawer/MyDefaultDrawer'
import './MyDefaultPageHeader.scss'

const deviceSizes = ['tabletPortrait', 'mobile']

const MyDefaultPageHeader = ({
  onClick,
  options,
  buttonText,
  searchValue,
  onSearchChange,
  selectValue,
  onSelectChange,
  screenSize,
}) => {
  const [visibleDrawer, setvisibleDrawer] = useState(false)

  return (
    <Flex className='my_default_page_header'>
      <Flex className='my_default_page_header--left' justify='start'>
        <MyDefaultSearchInput value={searchValue} onChange={onSearchChange} />
        {Boolean(deviceSizes.includes(screenSize)) ? (
          <MyDefaultButton
            icon={<SVGHamburgerFilterIcon color={'var(--white)'} />}
            onClick={() => {
              setvisibleDrawer(true)
            }}
          />
        ) : (
          <MyDefaultSelect
            color={'white'}
            options={options}
            value={selectValue}
            onChange={onSelectChange}
          />
        )}
      </Flex>
      <Flex className='my_default_page_header--right' justify='end'>
        <MyDefaultButton type='primary' buttonText={buttonText} onClick={onClick} />
      </Flex>
      <MyDefaultDrawer
        placement={'right'}
        closable={false}
        onClose={() => {
          setvisibleDrawer(false)
        }}
        open={visibleDrawer}
        width={285}
        onClearAll={() => {
          onSelectChange(true)
        }}
      >
        <MyDefaultSelect
          color={'white'}
          options={options}
          value={selectValue}
          onChange={onSelectChange}
        />
      </MyDefaultDrawer>
    </Flex>
  )
}

export default MyDefaultPageHeader
