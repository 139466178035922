import React from 'react'
import { Typography, Divider, Collapse } from 'antd'
import { getText } from '../../../../lang'
const { Title, Text } = Typography

const IntegratedCtaInstructions = ({ id }) => {
  const content = () => {
    return (
      <div>
        <Title level={3}>{getText('INTEGRATION_INSTRUCTIONS_TITLE')}</Title>

        <Text>{getText('INTEGRATION_INSTRUCTIONS_CONTENT')}</Text>

        <Divider />

        <Text strong>{getText('STEP1_TITLE')}</Text>
        <pre
          style={{ fontSize: 12 }}
        >{`<div class="matador-widget" data-id="${id}"></div>`}</pre>

        <Text>{getText('STEP1_CONTENT')}</Text>

        <Divider />

        <Text strong>{getText('STEP2_TITLE')}</Text>
        <pre style={{ fontSize: 12 }}>
          {`<link href="https://chat.matador.ai/widget/index.css" rel="stylesheet"></link>`}
        </pre>

        <Text>{getText('STEP2_CONTENT')}</Text>

        <Divider />

        <Text strong>{getText('STEP3_TITLE')}</Text>
        <pre style={{ fontSize: 12 }}>
          {`<script src="https://chat.matador.ai/widget/index.js"></script>`}
        </pre>

        <Text>{getText('STEP3_CONTENT')}</Text>

        <Divider />

        <Text>{getText('INTEGRATION_CONGRATULATIONS')}</Text>

        <Divider />
        <Text strong>{getText('VEHICLE_PROPERTIES_EXPLANATION')}</Text>
        <ul className='pl-3 mt-3'>
          <li>
            <code>data-mileage</code> - {getText('MILEAGE_EXPLANATION')}
          </li>
          <li>
            <code>data-stock</code> - {getText('STOCK_NUMBER_EXPLANATION')}
          </li>
          <li>
            <code>data-model</code> - {getText('MODEL_EXPLANATION')}
          </li>
          <li>
            <code>data-year</code> - {getText('YEAR_EXPLANATION')}
          </li>
          <li>
            <code>data-make</code> - {getText('MAKE_EXPLANATION')}
          </li>
          <li>
            <code>data-vin</code> - {getText('VIN_EXPLANATION')}
          </li>
        </ul>
        <Divider />
        <Text strong>{getText('EXAMPLE')}:</Text>
        <pre style={{ fontSize: 12 }}>
          {`<div className="matador-widget" 
    data-id="${id}" 
    data-mileage="MILEAGE" 
    data-stock="STOCK" 
    data-model="MODEL" 
    data-year="YEAR" 
    data-make="MAKE" 
    data-vin="VIN">
  </div>`}
        </pre>
        <Divider />

        <Text>{getText('INTEGRATION_SUPPORT')}</Text>
      </div>
    )
  }

  const items = [
    {
      key: '1',
      label: getText('ADDITIONAL_INSTRUCTIONS'),
      children: content(),
    },
  ]

  return (
    <>
      <Collapse items={items} />
    </>
  )
}

export default IntegratedCtaInstructions
