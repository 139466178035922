import React, { useEffect } from 'react'
import { Form, Modal } from 'antd'
import OrganizationLayout from '../../components/organizationNewPage/OrganizationLayout'
import OrganizationListTitle from '../../components/organizationNewPage/OrganizationListTitle'
import PageFull from '../../../../components/PageFull'
import './SettingsTransfer.scss'
import SelectFromOrganizations from '../../../../components/api/SelectFromOrganizations'
import MatForm from '../../../../components/Form/MatForm'
import { getText, getTextServerError } from '../../../../lang'
import { replaceWords } from '../../../../utils'
import SelectCheckedSecondary from '../../../../components/Form/SelectChecked/SelectCheckedSecondary'
import { notifyError, notifySuccess } from '../../../../utils/Notify'
import organizationActions from '../../../../store/modules/organizationActions'

const SettingsTransfer = (props) => {
  // const { organization, setLoading } = props

  // const [enableIPWhiteList, setEnableIPWhiteList] = useState(
  //   organization.ipWhitelist.enabled
  // )

  const [form] = Form.useForm()

  useEffect(() => {
    props.setFooterButtons && props.setFooterButtons(['copy', 'cancel'])
    props.setPageTitle(getText('WORD_SETTINGS_TRANSFER'))
  }, [])

  const onFinish = async (values) => {
    if (values.fromOrg.value === values.toOrg.value) {
      notifyError(getText('WORD_PLEASE_SELECT_DIFFERENT_ORGANIZATIONS'))
      return
    }
    Modal.confirm({
      title: getText('WORD_SETTINGS_TRANSFER'),
      content: (
        <>
          {replaceWords(getText('CONFIRMATION_COPY_ORG_SETTINGS_TITLE'), {
            fromOrg: values?.fromOrg?.label || '',
          })}
          <br />
          <br />
          {replaceWords(getText('CONFIRMATION_COPY_ORG_SETTINGS_DESCRIPTION'), {
            toOrg: values?.toOrg?.label || '',
          })}
        </>
      ),
      icon: null,
      okText: getText('WORD_COPY'),
      cancelText: getText('ACTION_CANCEL'),
      onOk: async () => {
        const obj = {
          settings: { dripSequence: { shouldCopy: true } },
          toOrg: values.toOrg.value,
          fromOrg: values.fromOrg.value,
        }
        const result = await organizationActions.transferSettings(obj)
        if (result.success) {
          notifySuccess(getText('WORD_SETTINGS_TRANSFERED_SUCCESSFULLY'))
        } else {
          notifyError(result.errMsg)
        }
      },
      centered: true,
      okButtonProps: {
        type: 'primary',
        className: 'mat-btn mat-btn-primary',
      },
      className: 'confirm-alert-wrapper',
      cancelButtonProps: {
        type: 'white',
        className: 'mat-btn mat-btn-white',
      },
    })
    // setLoading(true)
    // if (values.ipWhitelist.ips.length > 10) {
    //   setLoading(false)
  }

  const onFinishFailed = (errorInfo) => {
    if (errorInfo.errorFields.length > 0) {
      notifyError(getTextServerError(errorInfo.errorFields[0].errors[0]))
    }
  }

  return (
    <OrganizationLayout className={'settings-transfer-wrapper'}>
      <OrganizationListTitle
        title={getText('WORD_ORGANIZATION_RESTRICTION_SETTINGS')}
      />
      <PageFull className={'settings-transfer'}>
        <MatForm
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          initialValues={{
            enabled: true,
          }}
        >
          <SelectFromOrganizations
            secondarySelect
            name={'fromOrg'}
            label={getText('WORD_SELECT_SOURCE_ORGANIZATION_COPY_FROM')}
            placeholder={getText('WORD_SELECT_ORGANIZATION')}
            message={getText('WORD_PLEASE_SELECT_ITEM')}
            dropdownStyle={{ zIndex: 10000 }}
            allowClear={false}
            required
            labelInValue
          />
          <SelectCheckedSecondary
            label={getText('WORD_SELECT_SETTINGS_TO_COPY')}
            placeholder={getText('WORD_SELECT_SETTING')}
            name={'settings'}
            option={[{ label: getText('WORD_SEQUENCE'), value: 'dripSequence' }]}
            onChangeItem={(val, item) => {}}
            allowClear={false}
            required
            message={getText('WORD_PLEASE_SELECT_SETTING')}
          />
          <SelectFromOrganizations
            secondarySelect
            name={'toOrg'}
            label={getText('WORD_SELECT_DESTINATION_ORGANIZATION_COPY_TO')}
            placeholder={getText('WORD_SELECT_ORGANIZATION')}
            message={getText('WORD_PLEASE_SELECT_ITEM')}
            dropdownStyle={{ zIndex: 10000 }}
            allowClear={false}
            required
            labelInValue
          />
        </MatForm>
      </PageFull>
    </OrganizationLayout>
  )
}

export default SettingsTransfer
