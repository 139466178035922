import React from 'react'
import MyDefaultBlockTitle from './MyDefaultBlockTitle'
import './MyDefaultBlock.scss'

const MyDefaultBlock = ({
  children,
  enableMarginTop,
  title,
  additionalHeader,
  fullWidth,
}) => {
  return (
    <div
      className={`default_block_wrapper ${enableMarginTop ? 'margin_top' : ''}`}
      style={{ width: fullWidth ? '100%' : 'none' }}
    >
      {title && (
        <MyDefaultBlockTitle title={title} additionalHeader={additionalHeader} />
      )}
      {children}
    </div>
  )
}

export default MyDefaultBlock
