import React, { useEffect, useState } from 'react'
import { Form } from 'antd'
import { useLocation, useNavigate } from 'react-router-dom'
import organizationActions from '../../../../store/modules/organizationActions'
import { notifyError, notifySuccess } from '../../../../utils/Notify'
import { getText, getTextServerError } from '../../../../lang'
import PageFull from '../../../../components/PageFull'
import PageFix from '../../../../components/PageFix'
import OrganizationPage from '../../components/OrganizationPage'
import MatForm from '../../../../components/Form/MatForm'
import InputFormText from '../../../../components/Form/InputFormText'
import SwitchForm from '../../../../components/Form/SwitchForm'
import MatButton from '../../../../components/MatButton'
import MatBlockWithLabel from '../../../../components/MatBlockWithLabel'
import SelectChecked from '../../../../components/Form/SelectChecked'
import LoadingSpinner from '../../../../components/LoadingSpinner'
import { getLongName } from '../../../../utils'
import {
  DAYS,
  DEFAULT,
  EMAIL,
  IMMEDIATELY,
  MINUTES,
  NIGHT,
  SMS,
} from '../../../../devOptions'
import AutoReplyItem from './AutoReplyItem'
import AutoReplyPanel from './AutoReplyPanel'

const deviceSize = ['mobile', 'tabletPortrait', 'tablet']

const DripSequence = (props) => {
  const { screenSize, organization, setLoading } = props

  const location = useLocation()
  const navigate = useNavigate()

  const sequenceData = location.state && location.state.sequenceData
  const languages = organization.languages
  const type = location.state && location.state.type

  const [touchpointList, setTouchpointList] = useState([])
  const [selectedAiBotMessage, setSelectedAiBotMessage] = useState(null)
  const [messagesToDelete, setMessagesToDelete] = useState([])
  const [triggeredOn] = useState('touchpoint')
  const [form] = Form.useForm()

  const getSelectedIndex = !selectedAiBotMessage
    ? 0
    : form
        .getFieldValue('auto_reply_items')
        .findIndex((autoReply) => autoReply.id === selectedAiBotMessage.id)

  const getSequenceList = Form.useWatch('auto_reply_items', form)

  const deviceCheck = deviceSize.includes(screenSize)

  useEffect(() => {
    props.setFooterButtons && props.setFooterButtons(['save', 'cancel'])
  }, [])

  useEffect(() => {
    const fill = async () => {
      let firstAutoMessage = { text: {} }
      let otherAutoMessages = []
      let result
      if (sequenceData && sequenceData._id) {
        result = await organizationActions.getSequenceSourceListForUpdate(
          sequenceData._id
        )
      } else {
        result = await organizationActions.getSequenceNewSourceList(type)
      }
      if (result.success) {
        const touchpointListWithLabel = result.data.map((touchpoint) => ({
          label: touchpoint.name,
          value: touchpoint.source,
          ...touchpoint,
        }))
        setTouchpointList(touchpointListWithLabel)
      }
      if (sequenceData) {
        firstAutoMessage.text = Object.assign(
          {},
          ...organization.languages.map((lng) => ({
            [lng]: sequenceData.first_message[lng]
              ? sequenceData.first_message[lng]
              : '',
          }))
        )

        if (
          sequenceData.firstMessageDelay.value === 0 &&
          sequenceData.firstMessageDelay.unit === MINUTES
        ) {
          sequenceData.firstMessageDelay.unit = IMMEDIATELY
        }

        if (sequenceData._video_upload_id) {
          firstAutoMessage._video_upload_id = sequenceData._video_upload_id
        }

        if (sequenceData._id) {
          firstAutoMessage._id = sequenceData._id
        }

        firstAutoMessage.time_unit = Object.assign(
          {},
          sequenceData.firstMessageDelay
        )

        firstAutoMessage.subject = Object.assign(
          {},
          sequenceData.firstMessageSubject
        )
        firstAutoMessage.send_via = sequenceData.firstMessageCommunicationChannel

        otherAutoMessages = sequenceData.auto_reply_items.map((reply, index) => ({
          ...reply,
          text: Object.assign(
            {},
            ...organization.languages.map((lng) => ({
              [lng]: reply?.text && reply?.text[lng] ? reply.text[lng] : '',
            }))
          ),
          time_unit: Object.assign(
            {},
            reply?.time_unit?.value
              ? reply.time_unit
              : {
                  value: 0,
                  unit: IMMEDIATELY,
                }
          ),
          isSecondMsg: Boolean(index === 0),
        }))
      } else {
        organization.languages.forEach((lng) => (firstAutoMessage.text[lng] = ''))
      }

      const completeFirstAutoMessage = {
        ...firstAutoMessage,
        isFirstItem: true,
        media: sequenceData ? sequenceData.first_message_media : [],
        minimumTimeBetweenMessages:
          triggeredOn === 'activity' &&
          sequenceData &&
          sequenceData.minimumTimeBetweenMessages,
        time_unit: firstAutoMessage.time_unit || {
          value: 0,
          unit: IMMEDIATELY,
        },
        send_via: sequenceData ? sequenceData.firstMessageCommunicationChannel : SMS,
      }
      form.setFieldsValue({
        auto_reply_items: [completeFirstAutoMessage, ...otherAutoMessages],
      })
      setSelectedAiBotMessage(completeFirstAutoMessage)
    }
    if (location.pathname.includes('edit') && !sequenceData) {
      return navigate(`/organization/settings/${organization._id}/dripsequence/list`)
    }
    if (organization && organization._id) {
      fill()
    }
  }, [organization, organization._id])

  const isAnyEmptyField = (autoReplies) => {
    const getMessageNumberError = (index) => {
      const number = index + 1
      switch (number) {
        case 1:
          return 'first'
        case 2:
          return 'second'
        case 3:
          return 'third'
        default:
          return number + 'th'
      }
    }
    let isEmptyField = false
    let emptyListMessages = []
    let emptyListSubject = []

    autoReplies.forEach((autoReply, index) => {
      for (const lang in autoReply.text) {
        if (lang !== organization.defaultLanguage) {
          const cleanContent = autoReply.text[lang].replaceAll(/\s/g, '')
          if (!cleanContent) {
            emptyListMessages.push({
              lang: lang,
              index: index,
            })
          }
        }
      }
      if (autoReply.send_via === 'email') {
        for (const lang in autoReply.subject) {
          if (!languages.includes(lang)) {
            delete autoReply.subject[lang]
          }
        }
        for (const lang in autoReply.subject) {
          const cleanContent = autoReply.subject[lang].replaceAll(/\s/g, '')
          if (!cleanContent) {
            emptyListSubject.push({
              lang: lang,
              index: index,
            })
          }
        }
      }
    })
    if (
      emptyListMessages.length === autoReplies.length ||
      emptyListSubject.length === autoReplies.length
    ) {
      isEmptyField = false
    } else {
      emptyListMessages.forEach((itemNumber) => {
        notifyError(
          `${getLongName(
            itemNumber.lang
          )} field is required for ${getMessageNumberError(
            itemNumber.index
          )} auto-reply message.`
        )
        isEmptyField = true
      })
      emptyListSubject.forEach((itemNumber) => {
        notifyError(
          `${getLongName(
            itemNumber.lang
          )} field is required for ${getMessageNumberError(
            itemNumber.index
          )} subject`
        )
        isEmptyField = true
      })
    }

    const allTimeUnits = autoReplies.map((autoReply) => {
      return (
        autoReply.time_unit.value + autoReply.time_unit.unit + autoReply.send_via
      )
    })
    const uniqueTimeUnits = [...new Set(allTimeUnits)]

    if (allTimeUnits.length !== uniqueTimeUnits.length) {
      notifyError(getText('ERR_SAME_TIME_AUTO_REPLY'))
      isEmptyField = true
    }

    return isEmptyField
  }

  const onFinish = async (values) => {
    setLoading(true)
    let result
    if (isAnyEmptyField(values.auto_reply_items)) return setLoading(false)

    const [firstMessage = {}, ...otherMessages] = values.auto_reply_items
    let minimumTimeBetweenMessages =
      values.auto_reply_items[0].minimumTimeBetweenMessages

    if (triggeredOn === 'activity') {
      values.auto_reply_items.filter((it) => {
        return delete it.minimumTimeBetweenMessages
      })
    }
    const {
      text,
      time_unit,
      send_via,
      subject,
      remove_video,
      _video_upload_id,
      media,
    } = firstMessage

    const getFirstMessageDelay = () => {
      if (time_unit.unit === IMMEDIATELY) {
        return { value: 0, unit: MINUTES }
      }
      return time_unit
    }

    const objSend = {
      name: values.name,
      isActive: values.isActive,
      sources: values.sources || [],
      _organization_id: organization._id,
      triggeredOn,
      first_message: text,
      firstMessageDelay: getFirstMessageDelay(),
      firstMessageCommunicationChannel: send_via,
      ...(send_via === 'email' && {
        firstMessageSubject: subject,
      }),
    }
    if (remove_video) {
      objSend.remove_video = remove_video
    }
    if (_video_upload_id) {
      objSend._video_upload_id = _video_upload_id
    }
    if (media.length) {
      objSend.first_message_media = media
    }
    for (const lng in objSend.firstMessageSubject) {
      if (!languages.includes(lng)) {
        delete objSend.firstMessageSubject[lng]
      }
    }

    if (triggeredOn === 'activity') {
      objSend.minimumTimeBetweenMessages = {
        unit: DAYS,
        value: minimumTimeBetweenMessages.value,
      }
    }

    const newMessages = otherMessages
      .filter((reply) => reply.isNewMessage)
      .map(({ isNewMessage, id, ...newMessage }) => {
        delete newMessage.isSecondMsg
        if (newMessage.time_unit.unit === IMMEDIATELY) {
          newMessage.time_unit.value = 0
          newMessage.time_unit.unit = MINUTES
          newMessage.time_unit.minutes = 0
        }
        return {
          ...newMessage,
          ...(newMessage.send_via === 'email' && {
            subject: newMessage.subject,
          }),
          send_via: newMessage.send_via,
        }
      })

    if (newMessages.length) {
      objSend.newMessages = newMessages
    }
    if (messagesToDelete.length) {
      objSend.removeMessages = messagesToDelete
    }

    // I am using sort here because we are sending requests one by one and have to sort them by delay time from big to small
    const existingMessages = otherMessages
      .filter((reply) => !reply.isNewMessage)
      .sort((a, b) => b.time_unit.value - a.time_unit.value)

    if (sequenceData && sequenceData._id) {
      result = await organizationActions.saveUpdateSequence(
        objSend,
        sequenceData._id
      )
      for (const autoReplyItem of existingMessages) {
        if (autoReplyItem.send_via === 'email') {
          for (const lng in autoReplyItem.subject) {
            if (!languages.includes(lng)) {
              delete autoReplyItem.subject[lng]
            }
          }
        }

        const objSendText = {
          text: autoReplyItem.text,
          time_unit: autoReplyItem.time_unit,
          send_via: autoReplyItem.send_via,
          ...(autoReplyItem.send_via === 'email' && {
            subject: autoReplyItem.subject,
          }),
          _organization_id: organization._id,
        }
        if (autoReplyItem.time_unit.unit === IMMEDIATELY) {
          objSendText.time_unit.value = 0
          objSendText.time_unit.unit = MINUTES
          objSendText.time_unit.minutes = 0
        }
        if (autoReplyItem._video_upload_id) {
          objSendText._video_upload_id = autoReplyItem._video_upload_id
        }
        if (autoReplyItem.media.length) {
          objSendText.media = autoReplyItem.media
          delete objSendText.media[0]._id
        }
        if (autoReplyItem.remove_video) {
          objSendText.remove_video = autoReplyItem.remove_video
        }
        const resultText =
          await organizationActions.saveUpdateSequenceAutoReplayMessege(
            objSendText,
            autoReplyItem.id
          )
        if (!resultText.success) {
          setLoading(false)
          return notifyError(getTextServerError(resultText.errMsg))
        }
      }
    } else {
      objSend.type = type
      result = await organizationActions.saveNewSequence(objSend)
    }

    if (result && result.success) {
      notifySuccess(getText('TEXT_SEQUENCE_WAS_SAVED_SUCCESSFULLY'))
      navigate(`/organization/settings/${organization._id}/dripsequence/list`)
    } else {
      notifyError(getTextServerError(result.errMsg, objSend.name))
    }
    setLoading(false)
  }

  const onFinishFailed = (errorInfo) => {
    if (errorInfo.errorFields.length > 0) {
      if (errorInfo.errorFields[0].name[0] === 'name') {
        notifyError(getText('ERR_SEQUENCE_NAME_IS_REQUIRED'))
      } else {
        notifyError(getTextServerError(errorInfo.errorFields[0].errors[0]))
      }
    }
  }

  function renderSelectedAutoReplyPanel(fields) {
    const matchingField = fields.find((field, index) => index === getSelectedIndex)
    if (matchingField) {
      return !selectedAiBotMessage ? (
        <LoadingSpinner style={{ width: '100%' }} />
      ) : (
        <AutoReplyPanel
          form={form}
          field={matchingField.name}
          sequenceData={sequenceData}
          triggeredOn={triggeredOn}
          selectedAiBotMessage={selectedAiBotMessage}
          setSelectedAiBotMessage={setSelectedAiBotMessage}
          selectedIndex={getSelectedIndex}
          languages={languages}
          type={type}
        />
      )
    }
  }

  return (
    <OrganizationPage className='aibot-organization-page'>
      <PageFull className='aibot-full-page'>
        <MatForm
          className='create-drip-campaign'
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          initialValues={{
            name: sequenceData ? sequenceData.name : '',
            sources: sequenceData ? sequenceData.sources : [],
            isActive: sequenceData ? sequenceData.isActive : true,
            triggeredOn,
          }}
        >
          <PageFix className='aibot-left-side'>
            <div className='sequence_type_wrapper'>
              <p className='sequence_type_title'>
                {getText('TEXT_TYPE_OF_SEQUENCE').toUpperCase()}
              </p>
              <p className='sequence_type_value'>
                {getText(
                  Boolean(type === NIGHT || sequenceData?.type === NIGHT)
                    ? 'TEXT_AFTER_HOURS'
                    : 'TEXT_WORKING_HOURS'
                )}
              </p>
            </div>
            <InputFormText
              name='name'
              label={getText('TEXT_SEQUENCE_NAME')}
              placeholder={getText('TEXT_SEQUENCE_NAME')}
              required
              errorMessage={getText('ERR_VALIDATION_REQUIRED')}
            />
            <div className='sequence_status_wrapper'>
              <p className='sequence_status_text'>
                {getText('TEXT_SEQUENCE_STATUS')}
              </p>
              <SwitchForm
                name='isActive'
                rowStyle={{ width: '100px' }}
                text={getText('WORD_ACTIVE')}
                disabled={!organization.enable_auto_reply}
              />
            </div>
            {sequenceData && sequenceData.featuresConnectingData.AIBot && (
              <div
                className='footer-connected-account'
                style={{
                  fontSize: ' 12px',
                  color: '#333333',
                  marginTop: '-15px',
                  marginBottom: '20px',
                }}
              >
                <span>
                  {sequenceData.featuresConnectingData.AIBot.isConnected
                    ? getText('ACTION_CONNECTED_BY')
                    : getText('ACTION_DISCONNECTED_BY')}
                  {': '}
                </span>
                <span>
                  {sequenceData.featuresConnectingData.AIBot.userData.fullName}
                </span>
              </div>
            )}
            {triggeredOn === 'touchpoint' && (
              <SelectChecked
                name={'sources'}
                label={getText('WORD_TOUCHPOINTS')}
                showSearch={true}
                mode='multiple'
                multipleNewStyle
                option={touchpointList}
                allowClear={false}
                required={false}
                message={getText('ERR_SELECT_TOUCHPOINT')}
                getPopupContainer={() => document.querySelector('.aibot-left-side')}
                maxTagCount={5}
              />
            )}
          </PageFix>
          <Form.List name='auto_reply_items'>
            {(fields, { add, remove }) => (
              <>
                <div className='auto-reply-items-list-wrapper'>
                  <MatBlockWithLabel className='auto-reply-items-list'>
                    {fields.map((field, index) => {
                      const item = form.getFieldValue('auto_reply_items')[field.name]
                      return (
                        <AutoReplyItem
                          key={index}
                          item={item}
                          isActive={index === getSelectedIndex}
                          sequenceData={sequenceData}
                          deviceCheck={deviceCheck}
                          onClick={() => setSelectedAiBotMessage(item)}
                          onDelete={() => {
                            remove(index)
                            const autoMessagesList =
                              form.getFieldValue('auto_reply_items')
                            if (!item.isNewMessage) {
                              setMessagesToDelete((others) => [...others, item._id])
                            }
                            setSelectedAiBotMessage(
                              autoMessagesList[getSelectedIndex - 1]
                            )
                          }}
                        />
                      )
                    })}
                  </MatBlockWithLabel>
                  {(sequenceData?.type === DEFAULT ||
                    type === DEFAULT ||
                    getSequenceList?.length < 2) && (
                    <MatButton
                      className='add-aibot-reply-item'
                      htmlType={'button'}
                      onClick={() => {
                        const newAutoReply = {
                          time_unit: {
                            unit:
                              sequenceData?.type === NIGHT || type === NIGHT
                                ? MINUTES
                                : DAYS,
                            value: 1,
                          },
                          send_via:
                            getSequenceList?.length < 2 &&
                            (sequenceData?.type === NIGHT || type === NIGHT)
                              ? getSequenceList[0]?.send_via === SMS
                                ? EMAIL
                                : SMS
                              : SMS,
                          text: Object.assign(
                            {},
                            ...organization.languages.map((lng) => ({
                              [lng]: '',
                            }))
                          ),
                          isNewMessage: true,
                          id: new Date().getTime(),
                          isSecondMsg: Boolean(getSequenceList.length === 1),
                        }
                        add(newAutoReply)
                        setSelectedAiBotMessage(newAutoReply)
                      }}
                      buttonText={
                        <>
                          <div className='plus-circle'>+</div>{' '}
                          {getText('TEXT_ADD_DRIP_SEQUENCE')}
                        </>
                      }
                    />
                  )}
                </div>
                {renderSelectedAutoReplyPanel(fields)}
              </>
            )}
          </Form.List>
        </MatForm>
      </PageFull>
    </OrganizationPage>
  )
}

export default DripSequence
