import React, { forwardRef, memo, useCallback, useState } from 'react'
import { debounce } from 'lodash'
import { Empty, Select } from 'antd'
import { getText } from '../../lang'
import SVGSelectArrow from '../../icons/SVG/SVGSelectArrow'
import LoadingSpinner from '../LoadingSpinner'
import MyDefaultFormItem from '../MyDefaultFormItem/MyDefaultFormItem'
import './MyDefaultSelect.scss'

const MyDefaultSelect = forwardRef(
  (
    {
      onSearch,
      className,
      defaultValue,
      loading,
      labelInValue,
      value,
      dropdownClassName,
      mode,
      placement,
      disabled,
      showSearch,
      maxTagCount,
      suffixIcon,
      dropdownMatchSelectWidth,
      dropdownStyle,
      menuItemSelectedIcon,
      onDropdownVisibleChange,
      getPopupContainer,
      onSelect,
      options,
      onSelectItem,
      onDeselect,
      onPopupScroll,
      onLoadMore,
      customOption,
      children,
      onClick,
      onBlur,
      onFocus,
      onChange,
      isForm,
      required = false,
      message,
      name,
      label = getText('WORD_SELECT'),
      hasFeedback,
      formStyle,
      initialValue,
      isLast,
      isFirst,
      filterOption,
      color = 'grey',
      placeholder = getText('WORD_SELECT_ITEM'),
    },
    ref
  ) => {
    const [isOpen, setisOpen] = useState(false)

    const debouncedChange = useCallback(
      debounce((options) => onSearch && onSearch(options), 700),
      []
    )

    const rules = useCallback(() => {
      const rules = []
      if (required && !disabled) {
        rules.push({
          required: required,
          message: message
            ? message
            : label || placeholder
              ? `${getText('WORD_PLEASE_SELECT')} ${label || placeholder}`
              : message || `${getText('WORD_PLEASE_SELECT_ITEM')}!`,
        })
      }
      return rules
    }, [required, disabled])

    return isForm ? (
      <MyDefaultFormItem
        label={label}
        name={name}
        hasFeedback={hasFeedback}
        rules={rules()}
        style={formStyle}
        initialValue={initialValue}
        isLast={isLast}
        isFirst={isFirst}
      >
        <Select
          className={`my_default_select ${color} ${className || ''}`}
          ref={ref}
          defaultValue={defaultValue}
          value={loading ? getText('WORD_LOADING') : value}
          labelInValue={labelInValue}
          popupClassName={`my_default_select--dropdown ${dropdownClassName || ''}`}
          loading={loading}
          mode={mode}
          placement={placement}
          placeholder={placeholder}
          disabled={disabled}
          showSearch={showSearch}
          onSearch={showSearch && debouncedChange}
          maxTagCount={maxTagCount}
          filterOption={
            Boolean(filterOption)
              ? filterOption
              : (input, option) => {
                  return (
                    option.children
                      .toString()
                      .toLowerCase()
                      .indexOf(input.toString().toLowerCase()) > -1
                  )
                }
          }
          suffixIcon={
            suffixIcon || (
              <SVGSelectArrow
                style={{
                  pointerEvents: 'none',
                  transform: isOpen ? 'rotate(-180deg)' : 'rotate(0deg)',
                }}
              />
            )
          }
          popupMatchSelectWidth={dropdownMatchSelectWidth}
          dropdownStyle={dropdownStyle}
          menuItemSelectedIcon={menuItemSelectedIcon}
          onDropdownVisibleChange={(e) => {
            setisOpen(e)
            onDropdownVisibleChange && onDropdownVisibleChange(e)
          }}
          getPopupContainer={
            getPopupContainer ? getPopupContainer : (trigger) => trigger.parentNode
          }
          onSelect={(value) => {
            onSelect && onSelect(value)
            let found = options.find((option) => option.value === value)
            if (found) {
              onSelectItem && onSelectItem(found)
            }
          }}
          onDeselect={onDeselect}
          notFoundContent={
            loading ? (
              <LoadingSpinner />
            ) : (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )
          }
          onPopupScroll={(e) => {
            e.persist()
            onPopupScroll && onPopupScroll(e)
            if (
              Math.ceil(e.target.scrollTop + e.target.offsetHeight) ===
              e.target.scrollHeight
            ) {
              onLoadMore && onLoadMore()
            }
          }}
          options={customOption && options}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          onClick={onClick}
        >
          {customOption
            ? children
            : options.map((option, index) => (
                <Select.Option
                  key={option.value + '_' + index}
                  value={option.value}
                  className={
                    option.label === value ? 'ant-select-item-option-selected' : ''
                  }
                  disabled={option.label === value}
                >
                  {option.label}
                </Select.Option>
              ))}
        </Select>
      </MyDefaultFormItem>
    ) : (
      <Select
        className={`my_default_select ${color} ${className || ''}`}
        ref={ref}
        defaultValue={defaultValue}
        value={loading ? getText('WORD_LOADING') : value}
        labelInValue={labelInValue}
        popupClassName={`my_default_select--dropdown ${dropdownClassName || ''}`}
        loading={loading}
        mode={mode}
        placement={placement}
        placeholder={placeholder}
        disabled={disabled}
        showSearch={showSearch}
        onSearch={showSearch && debouncedChange}
        maxTagCount={maxTagCount}
        filterOption={
          Boolean(filterOption)
            ? filterOption
            : (input, option) => {
                return (
                  option.children
                    .toString()
                    .toLowerCase()
                    .indexOf(input.toString().toLowerCase()) > -1
                )
              }
        }
        suffixIcon={
          suffixIcon || (
            <SVGSelectArrow
              style={{
                pointerEvents: 'none',
                transform: isOpen ? 'rotate(-180deg)' : 'rotate(0deg)',
              }}
            />
          )
        }
        popupMatchSelectWidth={dropdownMatchSelectWidth}
        dropdownStyle={dropdownStyle}
        menuItemSelectedIcon={menuItemSelectedIcon}
        onDropdownVisibleChange={(e) => {
          setisOpen(e)
          onDropdownVisibleChange && onDropdownVisibleChange(e)
        }}
        getPopupContainer={
          getPopupContainer ? getPopupContainer : (trigger) => trigger.parentNode
        }
        onSelect={(value) => {
          onSelect && onSelect(value)
          let found = options.find((option) => option.value === value)
          if (found) {
            onSelectItem && onSelectItem(found)
          }
        }}
        onDeselect={onDeselect}
        notFoundContent={
          loading ? (
            <LoadingSpinner />
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )
        }
        onPopupScroll={(e) => {
          e.persist()
          onPopupScroll && onPopupScroll(e)
          if (
            Math.ceil(e.target.scrollTop + e.target.offsetHeight) ===
            e.target.scrollHeight
          ) {
            onLoadMore && onLoadMore()
          }
        }}
        options={customOption && options}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        onClick={onClick}
      >
        {customOption
          ? children
          : options.map((option, index) => (
              <Select.Option
                key={option.value + '_' + index}
                value={option.value}
                className={
                  option.label === value ? 'ant-select-item-option-selected' : ''
                }
                disabled={option.label === value}
              >
                {option.label}
              </Select.Option>
            ))}
      </Select>
    )
  }
)

export default memo(MyDefaultSelect)
