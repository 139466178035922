import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { Badge } from 'antd'
import { useSelector } from 'react-redux'
import authActions from '../../../../../store/modules/authActions'
import locationActions from '../../../../../store/modules/locationActions'
import { notifyError } from '../../../../../utils/Notify'
import MatBlockWithLabel from '../../../../../components/MatBlockWithLabel'
import { getText } from '../../../../../lang'
import MatSelectSecondary from '../../../../../components/MatSelect/MatSelectSecondary'
import MatSwitchSecondary from '../../../../../components/MatSwitch/MatSwitchSecondary'
import SVGIntegrationModalSettings from '../../../../../icons/SVG/SVGIntegrationModalSettings'
import MatModal from '../../../../../components/MatModal'
import MatRow from '../../../../../components/MatRow'
import AuthButtons from './FacebookAuth'
import FacebookPageForms from './FacebookForms'

function FacebookIntegration(props) {
  const { locationId, facebookConnection = {}, featuresConnectingData } = props
  const [isLoading, setIsLoading] = useState(false)
  const [account, setAccount] = useState({})
  const [fbData, setFbData] = useState({ pages: [], cursor: '', next: false })
  const [selectedPage, setSelectedPage] = useState({})
  const [selectedAccountType, setselectedAccountType] = useState()
  const [visible, setVisible] = useState(false)
  const [accountTypeOptions, setaccountTypeOptions] = useState([])

  const user = useSelector((state) => {
    return state.authReducer.user
  })

  useEffect(() => {
    const fillAccountTypes = async () => {
      setIsLoading(true)
      const result = await locationActions.getFacebookBusinessAccounts(locationId)
      if (result.success) {
        setaccountTypeOptions([
          {
            label: getText('TEXT_PERSONAL_FACEBOOK_ACCOUNT'),
            value: 'personal_accounts',
          },
          ...(result.data.accounts || []).map((acc) => {
            acc.value = acc.id
            acc.label = acc.name
            return { ...acc }
          }),
        ])
      }
      setIsLoading(false)
    }
    if (account?.isConnected) {
      fillAccountTypes()
    }
  }, [account?.isConnected])

  useEffect(() => {
    if (facebookConnection) {
      let selectedAccType = 'personal_accounts'
      if (
        facebookConnection?.facebookPage?.isBusinessPage &&
        facebookConnection.facebookPage?.facebook_business_account
      ) {
        selectedAccType =
          facebookConnection.facebookPage.facebook_business_account.id
      }
      setselectedAccountType(selectedAccType)
      setAccount(facebookConnection.facebookAccount || {})
      setSelectedPage(facebookConnection.facebookPage || {})
    }
  }, [])

  useEffect(() => {
    const getFacebookPages = async () => {
      setIsLoading(true)
      let result

      if (selectedAccountType === 'personal_accounts') {
        result = await locationActions.getFacebookAccountPages(locationId)
      } else {
        result = await locationActions.getFacebookBusinessAccountPages(
          locationId,
          selectedAccountType
        )
      }

      if (result.success) {
        const { pages = [], cursor = '', next = false } = result.data
        setFbData({
          pages,
          cursor,
          next,
        })
      }

      setIsLoading(false)
    }

    if (selectedAccountType && account?.isConnected) {
      getFacebookPages()
    }
  }, [selectedAccountType, account?.isConnected])

  const handleLogout = async () => {
    window.FB.logout()
    if (account.isConnected) {
      let result = await authActions.disconnectFacebook(locationId)
      if (result.success) {
        featuresConnectingData.FB.isConnected =
          result.data.facebookAccount.isConnected
        featuresConnectingData.FB.userData.fullName = user.fullName
        setSelectedPage({})
        setAccount(result.data.facebookAccount)
      }
    }
  }

  const handleLoginResponse = async (response) => {
    if (response) {
      if (response.status && response.status === 'unknown') {
        setTimeout(() => {
          notifyError(
            getText(
              'TEXT_THERE_WAS_AN_ISSUE_CONNECTING_YOUR_ACCOUNT_PLEASE_TRY_AGAIN'
            )
          )
        }, 30000)
        return
      }
      let result = await authActions.connectFacebook(response, locationId)
      if (result.success) {
        if (featuresConnectingData && featuresConnectingData.FB) {
          featuresConnectingData.FB.isConnected =
            result.data.facebookAccount.isConnected
          featuresConnectingData.FB.userData.fullName = user.fullName
        }
        setAccount(result.data.facebookAccount)
        setVisible(true)
      }
    }
  }

  const handleSelectPage = async (pageId) => {
    const pageObj = fbData.pages.find((page) => page.id === pageId)
    if (selectedAccountType !== 'personal_accounts') {
      const selectedBusinessAcc = accountTypeOptions.find(
        (acc) => acc.id === selectedAccountType
      )
      pageObj.isBusinessPage = true
      pageObj.facebookBusinessAccount = {
        id: selectedBusinessAcc.id,
        name: selectedBusinessAcc.name,
      }
    }
    let result = await locationActions.setFacebookPages(pageObj, locationId)
    if (result.success) {
      setSelectedPage(
        Object.assign(result.data.facebookPage, {
          instagram_business_account:
            result.data.facebookPage.instagramBusinessAccount,
        }) || {}
      )
    } else {
      notifyError(result.errMsg)
    }
  }

  return (
    <>
      <MatBlockWithLabel className={'integration-item-footer-item'}>
        {selectedPage.id && fbData.pages.length > 0 && (
          <MatSwitchSecondary
            height={20}
            defaultValue={facebookConnection.isMessengerEnabled}
            label={getText('ACTION_ENABLE_MESSENGER')}
            onChange={async (value) =>
              await locationActions.switchFacebookMessenger(locationId, value)
            }
          />
        )}
        {!_.isEmpty(featuresConnectingData && featuresConnectingData.FB) && (
          <div className='footer-connected-account'>
            <span>
              {getText(
                featuresConnectingData.FB.isConnected
                  ? 'ACTION_CONNECTED_BY'
                  : 'ACTION_DISCONNECTED_BY'
              )}
            </span>
            <span>{featuresConnectingData.FB.userData.fullName}</span>
          </div>
        )}
        <MatRow className={'facebook-item-row'}>
          {account.isConnected && (
            <MatModal
              trigger={
                <Badge dot={selectedPage.id === ''} size='default'>
                  <SVGIntegrationModalSettings className={'modal-settings-icon'} />
                  {selectedPage.id === '' && (
                    <div className='integration-finish'>
                      {getText('TEXT_PLEASE_FINISH_INTEGRATION_SETUP')}
                    </div>
                  )}
                </Badge>
              }
              title={getText('ACTION_INTEGRATE_FB_INSTAGRAM_PAGE')}
              forceRender={false}
              visible={visible}
              onTrrigerClick={() => {
                setVisible(true)
              }}
              onCancel={() => setVisible(false)}
              hideFooter
              className='facebook-integration-modal'
              destroyOnClose={false}
            >
              <MatBlockWithLabel black label={''}>
                <div className='facebook-block'>
                  {account.isConnected && (
                    <>
                      <MatSelectSecondary
                        value={selectedAccountType}
                        className={`select-fb-page ${
                          !Boolean(selectedAccountType) ? 'no-value' : ''
                        }`}
                        loading={isLoading}
                        showClearIcon={false}
                        options={accountTypeOptions}
                        onSelect={(accType) => {
                          setselectedAccountType(accType)
                        }}
                      />
                      {Boolean(selectedAccountType) && (
                        <MatSelectSecondary
                          value={
                            fbData.pages.length === 0
                              ? getText(
                                  'TEXT_DURING_INTEGRATION_SETUP_NO_FB_PAGES_WERE_SELECTED'
                                )
                              : Boolean(selectedPage.name)
                                ? selectedPage.name
                                : getText('TEXT_SELECT_FACEBOOK_PAGE')
                          }
                          className={`select-fb-page ${
                            !Boolean(selectedPage.name) ? 'no-value' : ''
                          }`}
                          options={fbData.pages.map((page) => ({
                            label: page.name,
                            value: page.id,
                          }))}
                          customOption
                          loading={isLoading}
                          disabled={fbData.pages.length === 0}
                          onSelect={handleSelectPage}
                          onClear={() => {
                            setSelectedPage({})
                          }}
                        />
                      )}
                      {selectedPage.id && fbData.pages.length > 0 && (
                        <FacebookPageForms
                          account={account}
                          leadForms={facebookConnection.leadForms}
                          locationId={locationId}
                          selectedPage={selectedPage}
                        />
                      )}
                    </>
                  )}
                </div>
              </MatBlockWithLabel>
            </MatModal>
          )}
          <AuthButtons
            handleLogout={handleLogout}
            handleLoginResult={handleLoginResponse}
            isConnected={account.isConnected}
          />
        </MatRow>
      </MatBlockWithLabel>
      {!_.isEmpty(selectedPage) && selectedPage.id && (
        <MatRow
          flexStartCol
          className='integration-item-footer-item'
          style={{ marginTop: 10 }}
        >
          {selectedPage && selectedPage.name && (
            <div className='footer-connected-account'>
              <span>{getText('WORD_CONNECTED_PAGE_FB')}</span>
              <span>{selectedPage.name}</span>
            </div>
          )}
          <br />
          {selectedPage &&
            selectedPage.instagram_business_account &&
            selectedPage.instagram_business_account.name && (
              <div className='footer-connected-account'>
                <span>{getText('WORD_CONNECTED_PAGE_IG')}</span>
                <span>{selectedPage.instagram_business_account.name}</span>
              </div>
            )}
        </MatRow>
      )}
    </>
  )
}

export default FacebookIntegration
