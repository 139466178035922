import React, { useEffect, useState } from 'react'
import { useMixpanel } from 'react-mixpanel-browser'
import { useLocation, useNavigate } from 'react-router-dom'
import { BROADCASTING_SUB_PAGES } from '../../../../../config'
import { GET_DATA_FETCH_SIZE, setMixpanel } from '../../../../../utils'
import broadcastingActions from '../../../../../store/modules/broadcastingActions'
import { notifyError } from '../../../../../utils/Notify'
import { getText, getTextServerError } from '../../../../../lang'
import { generateResponseObj, loadLocation } from '../utils'
import SuccessModal from '../../../../../components/api/SuccessModal'
import Campaign from '../SubPages/Campaign/Campaign'
import Content from '../SubPages/Content/Content'
import Workflows from '../SubPages/Workflows/Workflows'
import Scheduler from '../SubPages/Scheduler/Scheduler'
import Conditions from '../SubPages/Conditions/Conditions'
import authActions from '../../../../../store/modules/authActions'
import StepHeader from './StepHeader'
import './StepPages.scss'

const StepPages = React.memo((props) => {
  const { user, organization, screenSize } = props
  const mixpanel = useMixpanel()
  const location = useLocation()
  const navigate = useNavigate()

  const [segmentState, setSegmentState] = useState({})
  const [currentStep, setCurrentStep] = useState(0)
  const [getLocationList, setLocationList] = useState({
    locations: [],
    maxLocations: 0,
  })
  const [isActionFinished, setIsActionFinished] = useState(false)
  const [isShowSuccessModal, setIsShowSuccessModal] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (location && location.state && organization) {
      if (location.state.fromOrg) {
        setSegmentState({ ...location.state.item })
        setCurrentStep(3)
      } else {
        setSegmentState({ ...location.state })
      }
      ;(async () => {
        let data = await loadLocation(
          getLocationList.locations.length,
          GET_DATA_FETCH_SIZE
        )
        setLocationList(data)
      })()
    } else {
      navigate('/broadcasting/create-campaign')
    }
    setIsLoading(false)
  }, [])

  const onChangeStep = (step) => {
    setCurrentStep(step)
  }

  const onStateChange = (key, value) => {
    if (segmentState[key] !== value) {
      setSegmentState({
        ...segmentState,
        [key]: value,
      })
    }
  }

  const handleLoadMoreLocations = async () => {
    if (
      !isActionFinished &&
      getLocationList.locations.length < getLocationList.maxLocations
    ) {
      setIsActionFinished(true)
      let data = await loadLocation(
        getLocationList.locations.length / GET_DATA_FETCH_SIZE,
        GET_DATA_FETCH_SIZE
      )
      setLocationList(data)
      setIsActionFinished(false)
    }
  }

  const saveItems = async () => {
    let sendObj = generateResponseObj(segmentState)
    if (!sendObj) {
      return
    }
    let result
    if (segmentState.uploadExcel) {
      result = await broadcastingActions.saveWithFile(sendObj)
    } else {
      result = await broadcastingActions.save(sendObj)
    }

    if (result.success) {
      setMixpanel(
        mixpanel,
        'broadcast created',
        user.createdAt,
        user.fullName,
        user.organization.name,
        user.location.name,
        null,
        user.organization._id,
        user.location._id,
        user._id
      )
      setIsShowSuccessModal(true)
      setTimeout(() => {
        setIsShowSuccessModal(true)
        navigate('/broadcasting/list')
      }, 1500)
    } else {
      notifyError(getTextServerError(result.errMsg))
    }
  }

  const handlerDetectStep = (item) => {
    if (currentStep + item < 0) {
      let path = `${
        segmentState.fileUploadPage
          ? '/broadcasting/create-campaign/file-upload'
          : '/broadcasting/create-campaign/create-segment'
      }`
      navigate(path)
      return
    }
    if (
      currentStep + item >=
      BROADCASTING_SUB_PAGES(authActions.getLanguage()).length
    ) {
      saveItems()
      return
    }

    let step = currentStep
    if (currentStep + item < 0) {
      step = 0
    } else if (
      currentStep + item >=
      BROADCASTING_SUB_PAGES(authActions.getLanguage()).length
    ) {
      step = BROADCASTING_SUB_PAGES(authActions.getLanguage()).length - 1
    } else {
      step = currentStep + item
    }
    onChangeStep(step)
  }

  return (
    <div className='main-steps-wrapper'>
      <div className='steps-wrapper'>
        <div className='steps-block'>
          <StepHeader
            deviceSize={screenSize}
            current={currentStep}
            labelPlacement='vertical'
          />
        </div>
        {isLoading ? (
          <div>LOADING ...</div>
        ) : (
          <>
            {isShowSuccessModal && (
              <SuccessModal
                text={getText('TEXT_BROADCAST_WILL_BE_SENT')}
                open={isShowSuccessModal}
              />
            )}
            {(() => {
              switch (
                BROADCASTING_SUB_PAGES(authActions.getLanguage())[currentStep]
                  .stepNumber
              ) {
                case 1:
                  return (
                    <Campaign
                      onStateChange={onStateChange}
                      consentValue={segmentState.send_consent}
                      name={segmentState.name}
                      _location_id={segmentState._location_id}
                      getLocationList={getLocationList}
                      handleLoadMoreLocations={async () => {
                        await handleLoadMoreLocations()
                      }}
                      onChangeStep={(item) => handlerDetectStep(item)}
                    />
                  )
                case 2:
                  return (
                    <Content
                      onChangeStep={(item) => handlerDetectStep(item)}
                      onStateChange={onStateChange}
                      inputMessage={segmentState.inputMessage}
                      aiInputMessage={segmentState.aiInputMessage}
                      uploadExcel={!!segmentState.uploadExcel}
                      organization={organization}
                      alternativeContent={
                        !!segmentState.alternativeContent
                          ? segmentState.alternativeContent
                          : []
                      }
                    />
                  )
                case 3:
                  return (
                    <Workflows
                      organization={organization}
                      onStateChange={onStateChange}
                      onChangeStep={(item) => handlerDetectStep(item)}
                      workflow={segmentState.workflow}
                      segmentState={segmentState}
                    />
                  )
                case 4:
                  return (
                    <Scheduler
                      user={user}
                      onChangeStep={(item) => handlerDetectStep(item)}
                      onStateChange={onStateChange}
                      uploadExcel={!!segmentState.uploadExcel}
                      date={segmentState.date}
                      usersList={
                        segmentState && segmentState.usersList
                          ? segmentState.usersList
                          : { users: [], maxUsers: 0 }
                      }
                      appointmentToBroadcast={segmentState.appointmentToBroadcast}
                      users_to_assign={segmentState.users_to_assign}
                      time={segmentState.time}
                      tags_to_assign={segmentState.tags_to_assign}
                      restrictions={organization?.restrictions}
                      checkBoxValue={segmentState.checkBoxValue}
                      screenSize={screenSize}
                    />
                  )
                case 5:
                  return (
                    <Conditions
                      onStateChange={onStateChange}
                      onChangeStep={(item) => handlerDetectStep(item)}
                      accepted={segmentState.accepted}
                    />
                  )
                default:
                  return (
                    <Scheduler
                      user={user}
                      onChangeStep={(item) => handlerDetectStep(item)}
                      onStateChange={onStateChange}
                      uploadExcel={!!segmentState.uploadExcel}
                      date={segmentState.date}
                      appointmentToBroadcast={segmentState.appointmentToBroadcast}
                      users_to_assign={segmentState.users_to_assign}
                      time={segmentState.time}
                      tags_to_assign={segmentState.tags_to_assign}
                      restrictions={organization?.restrictions}
                      checkBoxValue={segmentState.checkBoxValue}
                      screenSize={screenSize}
                    />
                  )
              }
            })()}
          </>
        )}
      </div>
    </div>
  )
})

export default StepPages
