import React, { forwardRef, useImperativeHandle, useRef } from 'react'
import { Input } from 'antd'
import { getText } from '../../lang'
import MyDefaultFormItem from '../MyDefaultFormItem/MyDefaultFormItem'
import './MyDefaultInputText.scss'

const MyDefaultInputText = forwardRef(
  (
    {
      isForm,
      formClassName,
      formStyle,
      label,
      name,
      hasFeedback = false,
      initialValue,
      validateStatus,
      hidden,
      required = false,
      validator,
      errorMessage,
      placeholder,
      maxLength,
      minLength = 0,
      id,
      color,
      inputClassName,
      allowClear = true,
      disabled,
      onPressEnter,
      autoComplete = 'off',
      size,
      onBlur,
      onFocus,
      autoFocus,
      addonBefore,
      onClear,
      onChange,
      onClick,
      onKeyDown,
      onKeyUp,
      isFirst,
      isLast,
    },
    ref
  ) => {
    const inputRef = useRef()

    useImperativeHandle(ref, () => ({
      autoFocus(position) {
        setTimeout(() => {
          inputRef.current.focus()
          if (position) {
            inputRef.current.input.selectionStart = position
            inputRef.current.input.selectionEnd = position
          }
        }, 10)
      },
    }))

    return isForm ? (
      <MyDefaultFormItem
        formClassName={formClassName}
        style={formStyle}
        label={label}
        name={name}
        hasFeedback={hasFeedback}
        initialValue={initialValue}
        validateStatus={validateStatus}
        hidden={hidden}
        isFirst={isFirst}
        isLast={isLast}
        rules={[
          {
            required: required,
            message: errorMessage
              ? errorMessage
              : `${getText('WORD_PLEASE_INPUT')} ${placeholder ? placeholder : label}!`,
            whitespace: required,
            validator: validator,
          },
          {
            max: maxLength,
            message: `${
              label ? label : placeholder
            } ${getText('TEXT_MUST_BE_MAXIMUM')} ${maxLength} ${getText('WORD_CHARACTERS')}.`,
          },
          {
            min: minLength,
            message: `${
              label ? label : placeholder
            } ${getText('TEXT_MUST_BE_MINIMUM')} ${minLength} ${getText('WORD_CHARACTERS')}.`,
          },
        ]}
      >
        <Input
          id={id}
          className={`my_default_input ${color || 'grey'} ${inputClassName || ''}`}
          placeholder={placeholder ? placeholder : label}
          allowClear={allowClear}
          disabled={disabled}
          ref={inputRef}
          maxLength={maxLength}
          onPressEnter={onPressEnter}
          autoComplete={autoComplete}
          size={size}
          onBlur={onBlur}
          onFocus={onFocus}
          autoFocus={autoFocus}
          addonBefore={addonBefore}
          onChange={(e) => {
            if (e.target.value === '') {
              onClear && onClear()
            }
            onChange && onChange(e)
          }}
          onClick={onClick}
          onKeyDown={onKeyDown}
          onKeyUp={onKeyUp}
        />
      </MyDefaultFormItem>
    ) : (
      <Input
        id={id}
        className={`my_default_input ${color || 'grey'} ${inputClassName || ''}`}
        placeholder={placeholder ? placeholder : label}
        allowClear={allowClear}
        disabled={disabled}
        ref={inputRef}
        maxLength={maxLength}
        onPressEnter={onPressEnter}
        autoComplete={autoComplete}
        size={size}
        onBlur={onBlur}
        onFocus={onFocus}
        autoFocus={autoFocus}
        addonBefore={addonBefore}
        onChange={(e) => {
          if (e.target.value === '') {
            onClear && onClear()
          }
          onChange && onChange(e)
        }}
        onClick={onClick}
        onKeyDown={onKeyDown}
        onKeyUp={onKeyUp}
      />
    )
  }
)

export default MyDefaultInputText
