import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Divider } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import SVGSendMessageNew from '../../../../../icons/SVG/SVGSendMessageNew'
import MatSelect from '../../../../../components/MatSelect'
import { getText, getTextServerError } from '../../../../../lang'
import SVGArrowDown from '../../../../../icons/SVG/SVGArrowDown'
import SVGPressEnterIcon from '../../../../../icons/SVG/SVGPressEnterIcon'
import userActions from '../../../../../store/modules/userActions'
import authActions from '../../../../../store/modules/authActions'
import { notifyError } from '../../../../../utils/Notify'
import { MainColor } from '../../../../../utils'
import { EMAIL, MOBILE } from '../../../../../devOptions'
import MatButton from '../../../../../components/MatButton'
import './SendMessageButton.scss'

const SendMessageButton = ({
  screenSize,
  videoStatusUpload,
  onClick,
  sendingMsg,
  sendingMedia,
  disabled,
  uploadFiles,
  videoUpload,
  newMessageValue,
  newEmailSubject,
  msgChangeEmailSMSType,
  isLoading,
  isExtension,
}) => {
  const userAdditionalConfigsBtn = useSelector((state) => {
    return state.authReducer.user.additionalConfigs.useEnterBtnToSendMessage
  })

  const userId = useSelector((state) => {
    return state.authReducer.user._id
  })

  const isMobileApp = useSelector((state) => {
    return state.authReducer.isMobileApp
  })

  const [selectValuem, setSelectValuem] = useState(
    !userAdditionalConfigsBtn ? 'notSent' : 'sent'
  )

  const isShowSendMethodType =
    msgChangeEmailSMSType === EMAIL ||
    isMobileApp ||
    screenSize === MOBILE ||
    (isExtension && screenSize === MOBILE)

  return isShowSendMethodType ? (
    <MatButton
      onClick={(e) => {
        e.preventDefault()
        onClick()
      }}
      disabled={
        videoStatusUpload === 'uploading'
          ? true
          : sendingMsg ||
            sendingMedia ||
            disabled ||
            (!uploadFiles.length &&
              !videoUpload.length &&
              !Boolean(newMessageValue.inputMessageForSending) &&
              !newEmailSubject.newValue) ||
            isLoading
      }
      className={`message-center-button-send ${
        isShowSendMethodType ? 'icon-size' : ''
      }`}
      icon={
        isLoading ? (
          <LoadingOutlined style={{ fontSize: 12 }} />
        ) : isShowSendMethodType ? (
          <SVGSendMessageNew
            color={sendingMsg ? MainColor : '#6c757d'}
            width={20}
            height={20}
          />
        ) : !userAdditionalConfigsBtn ? (
          <SVGSendMessageNew
            color={sendingMsg ? '#fff' : 'rgba(255, 255, 255, 0.1)'}
            width={20}
            height={20}
          />
        ) : (
          <SVGPressEnterIcon width={16} height={12} />
        )
      }
    />
  ) : (
    <div
      className='send-button-with-dropdown-wrapper'
      style={{ opacity: isLoading ? 0.5 : 1 }}
    >
      <MatButton
        onClick={() => onClick()}
        disabled={
          videoStatusUpload === 'uploading'
            ? true
            : sendingMsg ||
              sendingMedia ||
              disabled ||
              (!uploadFiles.length &&
                !videoUpload.length &&
                !Boolean(newMessageValue.inputMessageForSending) &&
                !newEmailSubject.newValue) ||
              isLoading
        }
        className={`message-center-button-send ${
          isShowSendMethodType ? 'icon-size' : ''
        }`}
        icon={
          isLoading ? (
            <LoadingOutlined style={{ fontSize: 12 }} />
          ) : isShowSendMethodType ? (
            <SVGSendMessageNew color={sendingMsg ? MainColor : '#6c757d'} />
          ) : !userAdditionalConfigsBtn ? (
            <SVGSendMessageNew
              width={16}
              height={16}
              color={sendingMsg ? '#fff' : 'rgba(255, 255, 255, 0.1)'}
            />
          ) : (
            <SVGPressEnterIcon width={16} height={12} />
          )
        }
      />
      <Divider type='vertical' />
      <MatSelect
        options={[
          {
            label: (
              <>
                {' '}
                <SVGSendMessageNew width={16} height={16} />
                <span className='not-show'>{getText('WORD_PRESS_SENT_BUTTON')}</span>
                {selectValuem === 'notSent' ? (
                  <span className='default'>{getText('WORD_DEFAULT')}</span>
                ) : (
                  ''
                )}
              </>
            ),
            value: 'notSent',
          },
          {
            label: (
              <>
                <div className='press_enter_icon_wrapper'>
                  <SVGPressEnterIcon />
                </div>
                <span className='not-show'>{getText('WORD_PRESS_ENTER')}</span>
                {selectValuem === 'sent' ? (
                  <span className='default'>{getText('WORD_DEFAULT')}</span>
                ) : (
                  ''
                )}
              </>
            ),
            value: 'sent',
          },
        ]}
        suffixIcon={<SVGArrowDown color={'#fff'} />}
        placement={'topRight'}
        onChange={async (val) => {
          setSelectValuem(val)
          const result = await userActions.setAdditionalConfigs(
            { useEnterBtnToSendMessage: val === 'notSent' ? false : true },
            userId
          )
          if (result.success) {
            authActions.setUserData(result.data)
          } else {
            notifyError(getTextServerError(result.errMsg))
          }
        }}
      />
    </div>
  )
}

export default SendMessageButton
