import React from 'react'
import SVGMyDefaultEditIcon from '../../icons/SVG/SVGMyDefaultEditIcon'
import Div from '../Div/Div'

const MyDefaultBlockHeader = ({ onClick, label }) => {
  return (
    <Div className={'my_default_block_header'}>
      <span className='my_default_block_header--label'>{label}</span>

      <SVGMyDefaultEditIcon onClick={onClick} />
    </Div>
  )
}

export default MyDefaultBlockHeader
