import React, {
  forwardRef,
  useEffect,
  useState,
  useImperativeHandle,
  useCallback,
  memo,
} from 'react'
import { useSelector } from 'react-redux'
import broadcastingActions from '../../../store/modules/broadcastingActions'
import { getText } from '../../../lang'
import { formatDateAppoinment, GET_DATA_FETCH_SIZE } from '../../../utils'
import { getBroadcastList } from '../../broadcast/CreateCampaign/CreateSegment/utils'
import MatSelectSecondary from '../../../components/MatSelect/MatSelectSecondary'

const BroadcastsFilter = forwardRef((props, ref) => {
  const { onChangeBroadcast, selectedBroadcastItem } = props

  const organization = useSelector((state) => {
    return state.authReducer.user.organization
  })

  const user = useSelector((state) => {
    return state.authReducer.user
  })

  useImperativeHandle(ref, () => {
    return {
      resetBroadcast: () => {
        onChangeBroadcast('')
      },
    }
  })

  const [listBroadcast, setListBroadcast] = useState([])
  const [broadcastSearchKeyword, setBroadcastSearchKeyword] = useState('')
  const [isBroadActionFinished, setIsBroadActionFinished] = useState(false)
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)

  const searchBroadcast = useCallback(async () => {
    setLoading(true)
    const broadcastFiltersFilters = {
      sent: true,
      canceled: false,
      search: broadcastSearchKeyword || '',
    }
    let resultBroadcast = await broadcastingActions.getPageList(
      0,
      GET_DATA_FETCH_SIZE,
      organization._id,
      broadcastFiltersFilters
    )
    let stateBroadcastValue = {}
    if (resultBroadcast.success) {
      stateBroadcastValue = {
        listBroadcast: await getBroadcastList(resultBroadcast.data),
        maxManagers: resultBroadcast.max,
      }
    }
    if (!selectedBroadcastItem) {
      setListBroadcast(stateBroadcastValue)
    }
    setLoading(false)
  }, [broadcastSearchKeyword, organization, selectedBroadcastItem])

  useEffect(() => {
    if ((user.isAdmin || user.isManager) && open) {
      searchBroadcast()
    }
  }, [searchBroadcast, open])

  const handleLoadMoreBroadcast = async () => {
    const broadcastFiltersFilters = {
      sent: true,
      canceled: false,
    }
    if (user.isAdmin || user.isManager) {
      if (
        !isBroadActionFinished &&
        listBroadcast.listBroadcast.length < listBroadcast.maxManagers
      ) {
        setIsBroadActionFinished(true)
        const result = await broadcastingActions.getPageList(
          listBroadcast.listBroadcast.length / GET_DATA_FETCH_SIZE,
          GET_DATA_FETCH_SIZE,
          organization._id,
          broadcastFiltersFilters
        )
        if (result.success) {
          if (listBroadcast.listBroadcast.length < listBroadcast.maxManagers) {
            let listOld = listBroadcast.listBroadcast
            let list = [...listOld, ...result.data]
            setListBroadcast({
              listBroadcast: await getBroadcastList(list),
              maxManagers: result.max,
            })
          }
        }
        setIsBroadActionFinished(false)
      }
    }
  }

  return (
    <div className='dropdown-with-selected-item'>
      <MatSelectSecondary
        label={getText('WORD_BROADCAST_LIST')}
        placeholder={getText('WORD_SELECT_ITEM')}
        dropdownClassName={'filter-broadcast-list-dropdown'}
        options={listBroadcast.listBroadcast || []}
        onLoadMore={handleLoadMoreBroadcast}
        loading={loading}
        value={
          selectedBroadcastItem
            ? selectedBroadcastItem.name +
              ' ' +
              formatDateAppoinment(selectedBroadcastItem.createdAt)
            : selectedBroadcastItem._id
        }
        onSearch={(search) => setBroadcastSearchKeyword(search)}
        showSearch={true}
        onChangeItem={(val, item) => {
          onChangeBroadcast(item)
          setBroadcastSearchKeyword('')
        }}
        onClear={() => {
          onChangeBroadcast('')
          setBroadcastSearchKeyword('')
        }}
        onDropdownVisibleChange={() => setOpen(true)}
      />
    </div>
  )
})

export default memo(BroadcastsFilter)
