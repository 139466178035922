import React, { useState, useEffect, useCallback } from 'react'
import { debounce } from 'lodash'
import { getText } from '../../../../lang'
import { TABLE_DEFAULT_ROW_NUMBER } from '../../../../devOptions'
import organizationActions from '../../../../store/modules/organizationActions'
import OrganizationPage from '../../components/OrganizationPage'
import MatList from '../../../../components/MatList'
import InputText from '../../../../components/InputText'
import SVGSearch from '../../../../icons/SVG/SVGSearch'
import TouchpointsItem from './TouchpointsItem'
import './Touchpoint.scss'
import TouchPointsItemNew from './TouchPointsItemNew'

const Touchpoints = (props) => {
  const { organization } = props

  const [page, setPage] = useState(0)
  const [perPage, setPerPage] = useState(TABLE_DEFAULT_ROW_NUMBER)
  const [totalCount, setTotalCount] = useState(0)
  const [finalList, setFinalList] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [sequencesData, setsequencesData] = useState({
    night: [],
    default: [],
  })
  const [selectedRegularSequence, setselectedRegularSequence] = useState()
  const [selectedAfterHourSequence, setselectedAfterHourSequence] = useState()

  const [searchInput, setSearchInput] = useState({
    mainValue: '',
    debouncedValue: '',
  })

  useEffect(() => {
    props.setPageTitle(getText('WORD_TOUCHPOINTS'))
  }, [])

  useEffect(() => {
    if (organization && organization._id) {
      getListTouchpoints()
    }
  }, [organization, page, perPage, searchInput.debouncedValue])

  useEffect(() => {
    const getSequencesList = async () => {
      const result = await organizationActions.getPageListSequenceByGroupAll(
        organization._id
      )

      if (result.success) {
        const newData = {
          night: [],
          default: [],
        }
        for (const sequence of result.data.sequences.night) {
          sequence.value = sequence._id
          sequence.label = sequence.name
          if (sequence.newSourceAutoAssignment) {
            setselectedAfterHourSequence(sequence)
          }
          newData.night.push(sequence)
        }
        for (const sequence of result.data.sequences.default) {
          sequence.value = sequence._id
          sequence.label = sequence.name
          if (sequence.newSourceAutoAssignment) {
            setselectedRegularSequence(sequence)
          }
          newData.default.push(sequence)
        }
        setsequencesData(newData)
      }
    }
    getSequencesList()
  }, [])

  const getListTouchpoints = async () => {
    let orgId = organization._id
    let list = []
    let result = await organizationActions.getListTouchpoint(
      page,
      perPage,
      orgId,
      searchInput.debouncedValue
    )
    if (result.success) {
      for (const obj of result.data && result.data.list) {
        list = list.concat(obj.forms)
      }
      setTotalCount(result.data.total_items)
      setIsLoading(false)
      setFinalList(list)
    }
  }

  const debouncedChange = useCallback(
    debounce((options) => {
      setPage(0)
      setSearchInput({
        debouncedValue: options,
        mainValue: options,
      })
    }, 500),
    []
  )

  return (
    <div className='touchpoints-wrapper'>
      <OrganizationPage
        title={getText('WORD_TOUCHPOINTS')}
        subtitle={getText('TEXT_UPDATE_ORGANIZATION_TOUCHPOINTS')}
        headerRight={
          <div className='inputWithSearch'>
            <SVGSearch />
            <InputText
              className='inputSearch'
              name='inputSearch'
              placeholder={getText('TEXT_TOUCHPOINTS_SEARCH')}
              allowClear={() => {}}
              value={searchInput.mainValue}
              onChange={(e) => {
                setSearchInput({
                  ...searchInput,
                  mainValue: e.target.value,
                })
                debouncedChange(e.target.value)
              }}
            />
          </div>
        }
      >
        {/* <SelectAutoAssignSequence
          organizationId={organization.id}
          onChange={(e) => {
            setSearchInput({
              ...searchInput,
              mainValue: e.target.value,
            })
            debouncedChange(e.target.value)
          }}
          searchInput={searchInput}
        /> */}
        <MatList
          grid
          loading={isLoading}
          data={searchInput.mainValue ? finalList : [{ type: 'new' }, ...finalList]}
          pagination={true}
          defaultPageSize={perPage}
          paginationCurrentPage={page}
          paginationTotalCount={totalCount}
          onPaginationChange={setPage}
          onShowSizeChange={setPerPage}
          containerClassName='touchpoints-list'
          renderItem={(item, index) => {
            if (index === 0 && !searchInput.mainValue) {
              return (
                <TouchPointsItemNew
                  sequencesData={sequencesData}
                  selectedRegularSequence={selectedRegularSequence}
                  selectedAfterHourSequence={selectedAfterHourSequence}
                  setselectedRegularSequence={setselectedRegularSequence}
                  setselectedAfterHourSequence={setselectedAfterHourSequence}
                  organizationId={organization.id}
                />
              )
            }
            return (
              <TouchpointsItem
                key={index + '_' + item._id}
                item={item}
                organization={organization}
                onSave={() => getListTouchpoints()}
              />
            )
          }}
        />
      </OrganizationPage>
    </div>
  )
}

export default Touchpoints
