import React, { useRef, useState, useEffect } from 'react'
import { Flex } from 'antd'
import Hls from 'hls.js'
import voiceAIActions from '../../store/modules/voiceAIActions'
import SVGAudioForwardIcon from '../../icons/SVG/SVGAudioForwardIcon'
import SVGAudioBackwardIcon from '../../icons/SVG/SVGAudioBackwardIcon'
import SVGPauseIcon from '../../icons/SVG/SVGPauseIcon'
import SVGAudioIcon from '../../icons/SVG/SVGAudioIcon'
import LoadingSpinner from '../LoadingSpinner'

const VoiceAIAudio = ({ idPlayBack }) => {
  const [isPlaying, setIsPlaying] = useState(false)
  const [progress, setProgress] = useState(0)
  const [currentTime, setCurrentTime] = useState(0)
  const [duration, setDuration] = useState(0)
  const [isScrubbing, setIsScrubbing] = useState(false)
  const [audioSrc, setaudioSrc] = useState('')
  const [isLoading, setisLoading] = useState(false)

  const audioRef = useRef(null)
  const progressBarRef = useRef(null)

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60)
    const seconds = Math.floor(time % 60)
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`
  }

  const togglePlayPause = () => {
    const audio = audioRef?.current
    if (audio.paused) {
      try {
        audio.play()
        setIsPlaying(true)
      } catch (error) {
        console.error(error)
      }
    } else {
      try {
        audio.pause()
        setIsPlaying(false)
      } catch (error) {
        console.error(error)
      }
    }
  }

  const updateProgress = () => {
    const audio = audioRef.current
    if (!isScrubbing) {
      setCurrentTime(audio.currentTime)
      const progressPercentage = (audio.currentTime / audio.duration) * 100
      setProgress(progressPercentage)
    }
  }

  const handleScrub = (e) => {
    const progressBar = progressBarRef?.current

    if (!progressBar) return

    const { left, width } = progressBar.getBoundingClientRect()

    const clickX = e.clientX - left

    if (clickX >= 0 && clickX <= width) {
      const audio = audioRef.current
      const newTime = (clickX / width) * audio.duration
      setProgress((clickX / width) * 100)
      setCurrentTime(newTime)
      audio.currentTime = newTime
    }
  }

  const handleMouseDown = (e) => {
    setIsScrubbing(true)
    handleScrub(e)
  }

  const handleMouseMove = (e) => {
    if (isScrubbing) {
      handleScrub(e)
    }
  }

  const handleMouseUp = () => {
    setIsScrubbing(false)
  }

  const skipTime = (seconds) => {
    const audio = audioRef.current
    audio.currentTime = Math.min(
      Math.max(audio.currentTime + seconds, 0),
      audio.duration
    )
  }

  useEffect(() => {
    const audio = audioRef.current
    const setAudioData = () => setDuration(audio.duration)
    audio?.addEventListener('loadedmetadata', setAudioData)
    return () => {
      audio?.removeEventListener('loadedmetadata', setAudioData)
    }
  }, [])

  useEffect(() => {
    const audio = audioRef.current
    const handleEnded = () => setIsPlaying(false)
    audio?.addEventListener('ended', handleEnded)
    return () => {
      audio?.removeEventListener('ended', handleEnded)
    }
  }, [])

  useEffect(() => {
    document.addEventListener('mouseup', handleMouseUp)
    document.addEventListener('mousemove', handleMouseMove)
    return () => {
      document.removeEventListener('mouseup', handleMouseUp)
      document.removeEventListener('mousemove', handleMouseMove)
    }
  }, [isScrubbing])

  const getVideoUrl = async () => {
    if (idPlayBack) {
      const result = await voiceAIActions.getRecordingUrlByPlaybackId(idPlayBack)
      if (Hls.isSupported() && result?.success && result?.data?.signedRequest) {
        const hls = new Hls()
        hls.loadSource(result.data.signedRequest)
        hls.attachMedia(audioRef.current)
        setaudioSrc(hls)
        if (audioRef?.current) {
          try {
            audioRef?.current?.play()
            setIsPlaying(true)
          } catch (error) {
            console.error(error)
          }
        }
      }
      setisLoading(false)
    }
  }

  return (
    <div className='audio_player_container'>
      <Flex align='center' gap={8} className='audio_player_container--controls'>
        <SVGAudioBackwardIcon
          onClick={() => skipTime(-15)}
          color={'var(--secondaryTextColor)'}
        />
        {isLoading ? (
          <LoadingSpinner withoutHeight />
        ) : isPlaying ? (
          <SVGPauseIcon onClick={togglePlayPause} />
        ) : (
          <SVGAudioIcon
            onClick={() => {
              if (!audioSrc) {
                setisLoading(true)
                getVideoUrl()
              } else {
                togglePlayPause()
              }
            }}
            color={'var(--secondaryTextColor)'}
          />
        )}
        <SVGAudioForwardIcon
          onClick={() => skipTime(15)}
          color={'var(--secondaryTextColor)'}
        />
      </Flex>

      <div className='audio_player_container--time-display first'>
        <span>{formatTime(currentTime)}</span>
      </div>
      <div
        className='audio_player_container--progress-container'
        ref={progressBarRef}
        onMouseDown={handleMouseDown}
      >
        <div
          className={`audio_player_container--progress-container-progress ${progress === 0 ? 'zero' : ''}`}
          style={{ width: `${progress}%` }}
        ></div>
      </div>
      <div className='audio_player_container--time-display last'>
        <span>{formatTime(duration)}</span>
      </div>
      <audio ref={audioRef} onTimeUpdate={updateProgress}>
        <source type='audio/wav' />
        {/* <source src='' type='audio/ogg' /> */}
        Your browser does not support the audio element.
      </audio>
    </div>
  )
}

export default VoiceAIAudio
