import React, { useRef, forwardRef, useImperativeHandle } from 'react'
import MyDefaultFormItem from '../MyDefaultFormItem/MyDefaultFormItem'
import './MyDefaultInputNumber.scss'

const deviceSize = ['mobile', 'tabletPortrait', 'tablet']

const MyDefaultInputNumber = forwardRef(
  (
    {
      inputClassName,
      onChange,
      placeholder = '',
      disabled = false,
      value,
      label,
      maxLength = 255,
      style,
      screenSize,
      step = 1,
      size = 'middle',
      controls = false,
      isForm,
      required = false,
      hasFeedback = true,
      name,
      formClassName,
      initialValue,
      errorMessage,
      type,
      min,
      max,
      noStyle,
      validateStatus,
      color = 'grey',
    },
    ref
  ) => {
    const inputAreaRef = useRef()

    useImperativeHandle(ref, () => ({
      autoFocus() {
        if (!deviceSize.includes(screenSize)) {
          inputAreaRef.current && inputAreaRef.current.focus()
        }
      },
    }))

    return isForm ? (
      <MyDefaultFormItem
        className={formClassName}
        label={label}
        name={name}
        initialValue={initialValue}
        hasFeedback={hasFeedback}
        rules={[
          {
            required: required,
            message: errorMessage,
            type: type,
            min: min,
            max: max,
          },
        ]}
        style={style}
        noStyle={noStyle}
        validateStatus={validateStatus}
      >
        <input
          className={`my_default_input_number ${color} ${inputClassName || ''}`}
          onChange={onChange}
          disabled={disabled}
          placeholder={placeholder ? placeholder : label}
          step={step}
          size={size}
          type='number'
          controls={controls}
          maxLength={maxLength}
        />
      </MyDefaultFormItem>
    ) : (
      <input
        className={`my_default_input_number ${color} ${inputClassName || ''}`}
        onChange={onChange}
        disabled={disabled}
        placeholder={placeholder ? placeholder : label}
        step={step}
        value={value}
        size={size}
        type='number'
        controls={controls}
        maxLength={maxLength}
      />
    )
  }
)

export default MyDefaultInputNumber
