import React from 'react'
import { Flex } from 'antd'
import './MyDefaultRow.scss'

const MyDefaultRow = ({ leftSide, rightSide }) => {
  return (
    <Flex align='center' justify='space-between' className='my_default_row'>
      <span>{leftSide}</span>
      <span>{rightSide}</span>
    </Flex>
  )
}

export default MyDefaultRow
