import React from 'react'
import { Flex } from 'antd'
import CopyToClipboard from 'react-copy-to-clipboard'
import './VoiceAIDetails.scss'
import { getText } from '../../lang'
import { getLongName, MainColor } from '../../utils'
import SVGDrawerMessages from '../../icons/SVG/SVGDrawerMessages'
import SVGAiBotSmsIcon from '../../icons/SVG/SVGAiBotSmsIcon'
import SVGLanguage from '../../icons/SVG/SVGLanguage'
import SVGVehicleInterestIcon from '../../icons/SVG/SVGVehicleInterestIcon'
import SVGCallsIcon from '../../icons/SVG/SVGCallsIcon'
import SVGCopyIcon from '../../icons/SVG/SVGCopyIcon'
import Div from '../Div/Div'
import ProfileLetters from '../ProfileLetters'
import MyDefaultRow from '../MyDefaultRow/MyDefaultRow'
import MyDefaultTagBlock from '../MyDefaultTagBlock/MyDefaultTagBlock'
import MatBlockWithLabel from '../MatBlockWithLabel'
import utilNumber from '../../utils/utilNumber'
import InputTextarea from '../InputTextarea'
import { notifySuccess } from '../../utils/Notify'
import utilDate from '../../utils/utilsDate'
import VoiceAIAudio from './VoiceAIAudio'

const VoiceAIDetails = ({ item }) => {
  return (
    <Flex className='voice_ai_list_item'>
      <Div className='voice_ai_list_item--left'>
        <MyDefaultRow
          leftSide={getText('WORD_STATUS')}
          rightSide={
            <MyDefaultTagBlock
              title={getText(item?.success ? 'WORD_SUCCESS' : 'WORD_FAILURE')}
              backgroundColor={
                item?.success ? 'var(--greenLight)' : 'var(--lightRed)'
              }
              titleColor={item?.success ? 'var(--green)' : 'var(--red)'}
            />
          }
        />
        <MyDefaultRow
          leftSide={getText('WORD_CALLBACKS_SCHEDULED')}
          rightSide={getText(item?.isCallbackNeed ? 'WORD_YES' : 'WORD_NO')}
        />
        <MatBlockWithLabel black label={getText('WORD_CALLER_INFO')}>
          <Flex align='center'>
            <ProfileLetters
              className='medium'
              name={item?.nameSpecifiedByCustomer || ''}
              withoutIcon
            />
            <Div className='voice_ai_list_item--left-caller-info'>
              <Flex align='center' gap={8}>
                <span>{item?.nameSpecifiedByCustomer || ''}</span>
                <SVGDrawerMessages color={MainColor} width={20} height={20} />
              </Flex>
              <p>{item?.callFromPhone}</p>
            </Div>
          </Flex>
        </MatBlockWithLabel>
        <MatBlockWithLabel
          black
          label={getText('WORD_CONSENT')}
          className='voice_ai_list_item--left-consent-info'
        >
          <MyDefaultRow
            leftSide={
              <Flex gap={8} align='center'>
                <SVGAiBotSmsIcon width={16} height={16} /> {getText('WORD_SMS_UC')}
              </Flex>
            }
            rightSide={
              <MyDefaultTagBlock
                title={getText('WORD_APPROVED')}
                backgroundColor='var(--green)'
                titleColor='var(--white)'
              />
            }
          />
          <MyDefaultRow
            leftSide={
              <Flex gap={8} align='center'>
                <SVGCallsIcon /> {getText('WORD_CALL')}
              </Flex>
            }
            rightSide={
              <MyDefaultTagBlock
                title={getText('WORD_APPROVED')}
                backgroundColor='var(--green)'
                titleColor='var(--white)'
              />
            }
          />
        </MatBlockWithLabel>
        <MatBlockWithLabel black className='voice_ai_list_item--left-vehicle-info'>
          <SVGVehicleInterestIcon backgroundColor={'rgba(120, 84, 246, 0.20)'} />
          <Div className='voice_ai_list_item--left-vehicle-info-inner'>
            <p>{getText('WORD_VEHICLE_OF_INTEREST')}</p>
            <p>{`${item?.vehice?.year || ''} ${item?.vehicle?.make || ''} ${item?.vehicle?.model || ''}`}</p>
            <p>{item?.vehice?.vin || ''}</p>
          </Div>
        </MatBlockWithLabel>
        <MatBlockWithLabel black className='voice_ai_list_item--left-consent-info'>
          <MyDefaultRow
            leftSide={
              <Flex gap={8} align='center'>
                <SVGLanguage backgroundColor={'rgba(234, 153, 87, 0.2)'} />{' '}
                {getText('WORD_LANGUAGE')}
              </Flex>
            }
            rightSide={
              <MyDefaultTagBlock
                title={getLongName(item?.language || 'en')}
                backgroundColor={'transparent'}
                titleColor='var(--green)'
                border={'1px solid var(--borderLineColor)'}
              />
            }
          />
        </MatBlockWithLabel>
      </Div>
      <Div className='voice_ai_list_item--right'>
        {Boolean(item?.recording?.playback_id) && (
          <VoiceAIAudio idPlayBack={item?.recording?.playback_id} />
        )}
        <Div className={'voice_ai_list_item--right-list-info'}>
          <MyDefaultRow
            leftSide={getText('WORD_DATE')}
            rightSide={utilDate.getLocalizedFullDate(item?.createdAt, 'MMM D, YYYY')}
          />
          <MyDefaultRow
            leftSide={getText('WORD_DURATION')}
            rightSide={
              item?.durationSeconds
                ? `${Math.floor(item.durationSeconds / 60)}:${Math.floor(item.durationSeconds % 60)} min`
                : ''
            }
          />
          <MyDefaultRow
            leftSide={getText('WORD_ESTIMATED_VALUE')}
            rightSide={utilNumber.getNumberWithDollars(item?.value || 0)}
          />
          {Boolean(item?.isAppointment) && (
            <MyDefaultRow
              leftSide={getText('WORD_APPOINTMENT_TEXT')}
              rightSide={utilDate.getLocalizedFullDate(
                item?.appointmentDate,
                'MMM D, YYYY, hh:mm A'
              )}
            />
          )}
          <MyDefaultRow leftSide={getText('WORD_TYPE')} rightSide={item?.reason} />
          <MyDefaultRow
            leftSide={getText('TEXT_OF_TIMES_CALLED')}
            rightSide={item?.numberOfTimesCalled}
          />
        </Div>
        <MatBlockWithLabel
          black
          label={getText('WORD_SUMMARY')}
          className={'voice_ai_list_item--right-copy-part'}
        >
          <InputTextarea value={item?.summary || ''} autoSize={false} disabled />
          {Boolean(item?.summary) && (
            <CopyToClipboard
              text={item.summary}
              onCopy={() => notifySuccess(getText('WORD_COPIED'))}
            >
              <SVGCopyIcon className={'copy_icon'} />
            </CopyToClipboard>
          )}
        </MatBlockWithLabel>
      </Div>
    </Flex>
  )
}

export default VoiceAIDetails
