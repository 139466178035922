import React, { memo, useMemo, useContext } from 'react'
import { Flex, Tooltip } from 'antd'
import _ from 'lodash'
import ProfileLetters from '../../../../components/ProfileLetters'
import {
  ARCHIVED,
  CALL,
  EXPORT,
  MY_MESSAGES,
  OUTGOING_CALL,
  REMINDER,
  TAG_USER,
} from '../../utils/constants'
import { addTagBeforeText, getCustomerName, isToday } from '../../../../utils'
import SVGMessageNotificationEnableDisable from '../../../../icons/SVG/SVGMessageNotificationEnableDisable'
import SVGRemindMe from '../../../../icons/SVG/SVGRemindMe'
import { getText } from '../../../../lang'
import SVGPhotoIcon from '../../../../icons/SVG/SVGPhotoIcon'
import SVGConvCheckedIcon from '../../../../icons/SVG/SVGConvCheckedIcon'
import SVGShowVideoIcon from '../../../../icons/SVG/SVGShowVideoIcon'
import utilDate from '../../../../utils/utilsDate'
import { MessageContext } from '../../main/MessageMain'
import SVGResponseRequiredIcon from '../../../../icons/SVG/SVGResponseRequiredIcon'
import './MessageConversations.scss'

const getLastMessageContent = (obj) => {
  const messages = obj.messages || []
  const invitations = obj.invitation_items || []
  const appointments = obj.receiver ? obj.receiver.appointments : []
  const lastMessage = messages[messages.length - 1] || false
  const lastInvitation = invitations[invitations.length - 1] || false
  const lastAppointment = appointments[appointments.length - 1] || false
  const videos = obj.video_items || []
  const lastVideo = videos[videos.length - 1] || false

  let lastInteraction = { createdAt: new Date(0) }
  const parsedDateLastMessage = new Date(lastMessage.createdAt || new Date(0))
  const parsedDateLastInvitation = new Date(lastInvitation.createdAt || 0)
  const parsedDateLastAppointement = new Date(lastAppointment.createdAt || 0)
  const parsedDateLastVideo = new Date(lastVideo.createdAt || 0)

  if (lastMessage) {
    lastInteraction = {
      createdAt: parsedDateLastMessage,
      text: lastMessage.body,
    }
    if (lastMessage.type === OUTGOING_CALL) {
      lastInteraction.text = getText('TEXT_VIDEO_CALL_END')
    }
    if (lastMessage.type === EXPORT) {
      lastInteraction.text = getText('TEXT_CONVERSATION_SYNCED_WITH_CRM')
    }
    if (lastMessage.type === REMINDER) {
      lastInteraction.text = getText('WORD_REMINDED_MESSAGE')
    }
    if (lastMessage.type === CALL) {
      lastInteraction.text = getText('TEXT_INCOMING_CALL')
    }
    if (lastMessage.type === TAG_USER) {
      lastInteraction.text = addTagBeforeText(lastMessage)
    }
    if (lastMessage.media && lastMessage.media.length && !lastMessage.body) {
      lastInteraction.text = (
        <span style={{ display: 'flex' }}>
          <SVGPhotoIcon />
          <label style={{ marginLeft: 5 }}>{getText('WORD_ATTACHMENT')}</label>
        </span>
      )
    }
  }

  if (parsedDateLastVideo > lastInteraction.createdAt) {
    lastInteraction = {
      createdAt: parsedDateLastVideo,
      text: (
        <span style={{ display: 'flex', alignItems: 'center' }}>
          <SVGShowVideoIcon
            color={'var(--secondaryTextColor)'}
            backgroundColor={'transparent'}
          />
          <label style={{ marginLeft: 5 }}>
            {getText('WORD_SENT_CLIENT_VIDEO')}
          </label>
        </span>
      ),
    }
    return lastInteraction
  }
  if (parsedDateLastInvitation > lastInteraction.createdAt) {
    lastInteraction = {
      createdAt: parsedDateLastInvitation,
      text: getText('TITLE_MESSAGE_INVITATION'),
    }
  }
  if (parsedDateLastAppointement > lastInteraction.createdAt) {
    lastInteraction = {
      createdAt: parsedDateLastAppointement,
      text: getText('TEXT_BOOKED_AN_APPOINTMENT'),
    }
  }
  if (lastInteraction.createdAt === new Date(0)) {
    lastInteraction.createdAt = new Date()
  }
  return lastInteraction
}

const MessageLeftConversations = ({
  item,
  selectedConversation,
  onClick,
  messageType,
  selectMultipleConversationsType,
}) => {
  const authorName = getCustomerName(item)
  const contextValue = useContext(MessageContext)

  const { createdAt } = getLastMessageContent(item)
  let { text } = getLastMessageContent(item)

  if (_.isString(text)) {
    text = text.replace(
      '{{review_invite_url}}',
      getText('WORD_REVIEW_INVITE_LINK_LC')
    )
  }

  const LeftSideConversationContent = useMemo(() => {
    const lastSequence =
      item.last_source && item.sources_names && item.sources_names.length
        ? item.sources_names.find(
            (filItem) => filItem.source === item.last_source
          ) &&
          item.sources_names.find((filItem) => filItem.source === item.last_source)
            .name
        : ''

    const SourcePopover = () => {
      if (item.source && item.source.length <= 1) {
        return null
      }
      const list = item.sources_names.filter(
        (filItem) => filItem.source !== item.last_source
      )
      return (
        <div className='message_conversation_item--sources-leads'>
          <Tooltip
            title={list.map((it, index) => (
              <p key={index}>{it.name}</p>
            ))}
          >
            <span className='message_conversation_item--sources-leads-plus-popup'>
              +
            </span>
          </Tooltip>
        </div>
      )
    }

    const selectedConvForActions =
      messageType === ARCHIVED
        ? selectMultipleConversationsType.unArchiveConversationIds.includes(item._id)
        : selectMultipleConversationsType.archiveConversationsIds.includes(item._id)

    return (
      <div
        className={`conversation_item_wrapper standard 
        ${!item.isNew && item.is_unread ? 'unread' : ''}
        ${
          item._id === selectedConversation._id || selectedConvForActions
            ? contextValue.screenSize === 'mobile'
              ? ''
              : 'active'
            : ''
        }
        `}
        onClick={() => {
          onClick && onClick()
        }}
      >
        <div className='message_conversation_item'>
          <div className='message_conversation_item--row'>
            <div className='message_conversation_item--selected-conv'>
              {selectedConvForActions && (
                <div className='message_conversation_item--selected-conv-icon'>
                  <SVGConvCheckedIcon />
                </div>
              )}
              <ProfileLetters
                className='message_conversation_item--conv-letter'
                name={authorName}
                withoutIcon
              />
            </div>
            <div className='message_conversation_item--header'>
              <span className='message_conversation_item--header-name'>
                <span className='message_conversation_item--header-name-text'>
                  {authorName}
                </span>
                {messageType === MY_MESSAGES &&
                item.preferred_nudge_time &&
                isToday(item.preferred_nudge_time) ? (
                  item.smart_nudge_enabled ? (
                    <SVGMessageNotificationEnableDisable
                      enable={item.smart_nudge_enabled}
                    />
                  ) : undefined
                ) : null}
                {item.preferred_nudge_time && isToday(item.preferred_nudge_time) ? (
                  <SVGRemindMe width={18} height={18} color='#a3a6be' />
                ) : undefined}
              </span>
              {Boolean(!item.isNew) && (
                <div className='message_conversation_item--header-time'>
                  {createdAt && utilDate.getConversationDate(createdAt)}
                </div>
              )}
            </div>
          </div>
          <div className='message_conversation_item--last-message'>
            <p>{text}</p>
          </div>
          {lastSequence || item?.recent_tagged_users?.length ? (
            <div className='message_conversation_item--sources'>
              <Flex align='center' style={{ overflow: 'hidden' }} gap={3}>
                {lastSequence && (
                  <>
                    {lastSequence.length > 25 ? (
                      <>
                        <Tooltip title={lastSequence}>
                          <div className='message_conversation_item--sources-leads'>
                            <p>{lastSequence}</p>
                          </div>
                        </Tooltip>
                        <SourcePopover />
                      </>
                    ) : (
                      <>
                        <div className='message_conversation_item--sources-leads'>
                          <p>{lastSequence}</p>
                        </div>
                        <SourcePopover />
                      </>
                    )}
                  </>
                )}
              </Flex>
              {item?.recent_tagged_users?.length ? (
                <Flex
                  align='center'
                  className='message_conversation_item--needs-you'
                >
                  <SVGResponseRequiredIcon rectColor={'transparent'} />
                  {getText('WORD_NEEDS_YOU')}
                </Flex>
              ) : null}
            </div>
          ) : null}
          {!item.isNew && item.is_unread && (
            <div class='message_conversation_item--unread-badge'></div>
          )}
        </div>
      </div>
    )
  }, [selectedConversation, item, selectMultipleConversationsType, onClick])

  return LeftSideConversationContent
}

export default memo(MessageLeftConversations)
