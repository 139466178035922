import React, { useCallback, useEffect, useState } from 'react'
import { debounce } from 'lodash'
import { useSelector } from 'react-redux'
import tagsActions from '../../store/modules/tagsActions'
import SelectChecked from '../Form/SelectChecked'
import SelectCheckedSecondary from '../Form/SelectChecked/SelectCheckedSecondary'
import MatSelectSecondary from '../MatSelect/MatSelectSecondary'
import { useSocket } from '../../utils/socket'

const PER_PAGE = 50

const SelectFromTagsList = (props) => {
  const organization = useSelector((state) =>
    state.authReducer.user.organization ? state.authReducer.user.organization : {}
  )

  const [searchValue, setSearchValue] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [page, setPage] = useState(0)
  const [tags, settags] = useState([])
  const [totalTags, settotalTags] = useState(0)

  const getTagsList = async () => {
    setIsLoading(true)
    let result = await tagsActions.getTagsList(page, PER_PAGE, searchValue)
    if (result.success) {
      const tagsList = (result?.data || []).map((tag) => ({
        value: tag._id,
        label: tag.name,
        key: tag._id,
        ...tag,
      }))

      const newListTags = page > 0 ? [...tags, ...tagsList] : tagsList

      settags(newListTags)
      settotalTags(result.max)
      props.onFetchedTags && props.onFetchedTags(newListTags)
    }
    setIsLoading(false)
  }

  const handleUpdateList = (data) => {
    setIsLoading(true)
    const newList = tags.map((tag) => {
      if (tag._id === data._id) {
        return {
          ...tag,
          value: data._id,
          label: data.name,
          name: data.name,
          key: data._id,
        }
      }
      return tag
    })
    settags([...newList])
    setIsLoading(false)
  }

  useSocket(
    `private-organization=${organization._id}`,
    'TAG_UPDATED',
    handleUpdateList,
    !!organization._id
  )

  useSocket(
    `private-organization=${organization._id}`,
    'TAG_CREATED',
    () => {
      if (page === 0) {
        getTagsList()
      } else {
        setPage(0)
      }
    },
    !!organization._id
  )

  useSocket(
    `private-organization=${organization._id}`,
    'TAG_DELETED',
    (data) => {
      const newList = tags.filter((tag) => tag._id !== data._id)
      settags([...newList])
    },
    !!organization._id
  )

  useEffect(() => {
    getTagsList()
  }, [searchValue, page])

  const debouncedChange = useCallback(
    debounce((options) => {
      setPage(0)
      setSearchValue(options)
    }, 500),
    []
  )

  return props.notForm ? (
    <MatSelectSecondary
      label={props.label}
      placeholder={props.placeholder}
      options={tags || []}
      value={props.value}
      mode='multiple'
      labelInValue={true}
      showSearch={true}
      onSearch={(val) => {
        setPage(0)
        setSearchValue(val)
      }}
      loading={isLoading}
      multipleNewStyle
      onChangeItem={(val, items) => {
        props.onChangeItem && props.onChangeItem(val, items)
        setSearchValue('')
      }}
      usePreviousValueOnChange
      onLoadMore={() => {
        if (tags.length < totalTags && !isLoading) {
          setPage(page + 1)
        }
      }}
      onDropdownVisibleChange={(e) => {
        if (!e) {
          setSearchValue('')
        }
      }}
    />
  ) : props.secondarySelect ? (
    <SelectCheckedSecondary
      name={props.name}
      label={props.label}
      disabled={props.disabled}
      placeholder={props.placeholder}
      primary={props.primary}
      dropdownClassName={props.dropdownClassName}
      dropdownStyle={props.dropdownStyle}
      multipleNewStyle={props.multipleNewStyle}
      mode={props.mode}
      showArrow={props.showArrow}
      showSearch={true}
      option={tags || []}
      loading={isLoading}
      allowClear={props.allowClear}
      onSearch={debouncedChange}
      maxTagCount={props?.maxTagCount || 2}
      onLoadMore={() => {
        if (tags.length < totalTags && !isLoading) {
          setPage(page + 1)
        }
      }}
      onSelectItem={(tag) => {
        props.onSelectItem && props.onSelectItem(tag)
        setSearchValue('')
      }}
      onChange={props.onChange}
      onChangeItem={props.onChangeItem}
      onSelect={props.onSelect}
      onDeselect={props.onDeselect}
      required={props.required}
      labelInValue={props.labelInValue}
      message={props.message}
    />
  ) : (
    <SelectChecked
      name={props.name}
      disabled={props.disabled}
      label={props.label}
      placeholder={props.placeholder}
      primary={props.primary}
      dropdownClassName={props.dropdownClassName}
      dropdownStyle={props.dropdownStyle}
      multipleNewStyle={props.multipleNewStyle}
      mode={props.mode}
      showArrow={props.showArrow}
      showSearch={true}
      option={tags || []}
      loading={isLoading}
      allowClear={props.allowClear}
      onSearch={debouncedChange}
      maxTagCount={props?.maxTagCount || 2}
      onLoadMore={() => {
        if (tags.length < totalTags && !isLoading) {
          setPage(page + 1)
        }
      }}
      onSelectItem={(tag) => {
        props.onSelectItem && props.onSelectItem(tag)
        setSearchValue('')
      }}
      onChange={props.onChange}
      onChangeItem={props.onChangeItem}
      onSelect={props.onSelect}
      onDeselect={props.onDeselect}
      required={props.required}
      labelInValue={props.labelInValue}
      message={props.message}
    />
  )
}

export default SelectFromTagsList
