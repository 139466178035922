import React, { useState } from 'react'
import { getLongName } from '../../../../utils'
import MyDefaultSwitch from '../../../../components/MyDefaultSwitch/MyDefaultSwitch'

const LanguageItem = (props) => {
  const {
    lng,
    isDefault,
    disabled,
    onSetAsDefaultClick,
    isSelected,
    onChangeSwitch,
    isFirst,
    isLast,
  } = props

  const [value, setValue] = useState(isSelected)

  return (
    <MyDefaultSwitch
      title={getLongName(lng)}
      isFirst={isFirst}
      isLast={isLast}
      titleStyle={{ fontSize: 14 }}
      value={value}
      onChangeSwitch={(e) => {
        setValue(e)
        onChangeSwitch(lng)
      }}
      onSetAsDefaultClick={() => {
        if (value) {
          onSetAsDefaultClick(lng)
        }
      }}
      disabled={disabled}
      isDefault={isDefault}
    />
    // <SwitchWithText
    //   title={getLongName(lng)}
    //   value={value}
    //   isDefault={isDefault}
    //   disabled={disabled}
    //   onSetAsDefaultClick={() => {
    //     if (value) {
    //       onSetAsDefaultClick(lng)
    //     }
    //   }}
    //   onChangeSwitch={(e) => {
    //     setValue(e)
    //     onChangeSwitch(lng)
    //   }}
    // />
  )
}

export default LanguageItem
