import React from 'react'
import './MyDefaultDrawer.scss'
import { Drawer, Flex } from 'antd'
import { getText } from '../../lang'
import SVGChatClose from '../../icons/SVG/SVGChatClose'
import MyDefaultButton from '../MyDefaultButton/MyDefaultButton'

const MyDefaultDrawer = ({
  children,
  forceRender = false,
  open,
  onClose,
  closable = false,
  placement = 'left',
  onClearAll,
}) => {
  return (
    <Drawer
      placement={placement}
      closable={closable}
      onClose={onClose}
      open={open}
      width={285}
      rootClassName='my_default_drawer'
      forceRender={forceRender}
    >
      <Flex
        align='center'
        justify='space-between'
        gap={12}
        style={{ marginBottom: 30 }}
      >
        {getText('WORD_FILTER')}
        <Flex align='center' gap={12}>
          <MyDefaultButton
            size={'small'}
            typeButton={'radius'}
            buttonText={getText('WORD_CLEAR_ALL')}
            style={{ minWidth: '90px' }}
            onClick={onClearAll}
          />
          <SVGChatClose color={'#A3A6BE'} stroke={2} onClick={onClose} />
        </Flex>
      </Flex>
      {children}
    </Drawer>
  )
}

export default MyDefaultDrawer
