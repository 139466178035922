import React, { useEffect, useState, useRef } from 'react'
import { Form } from 'antd'
import InfiniteScroll from 'react-infinite-scroller'
import { getText } from '../../../../lang'
import OrganizationPage from '../../components/OrganizationPage'
import MatForm from '../../../../components/Form/MatForm'
import tagsActions from '../../../../store/modules/tagsActions'
import LoadingSpinner from '../../../../components/LoadingSpinner'
import MatBlockWithLabel from '../../../../components/MatBlockWithLabel'
import MyDefaultBlock from '../../../../components/MyDefaultBlock/MyDefaultBlock'
import MatButton from '../../../../components/MatButton'
import { useSocket } from '../../../../utils/socket'
import AddTagsList from './AddTagsList'

const Tags = (props) => {
  const { organization } = props

  const [tagsData, settagsData] = useState({ totalCount: 0, data: [] })
  const [page, setpage] = useState(0)
  const [isLoading, setisLoading] = useState(true)

  const [form] = Form.useForm()

  const scrollParentRef = useRef(null)
  const formRef = useRef(null)

  useEffect(() => {
    props.setPageTitle(getText('WORD_TAGS'))
  }, [])

  const handleUpdateList = (data) => {
    setTimeout(() => {
      setisLoading(true)
      const newList = tagsData.data.map((tag) => {
        if (tag._id === data._id) {
          return {
            ...tag,
            value: data._id,
            label: data.name,
            name: data.name,
            key: data._id,
            color: data.color,
            _id: data._id,
          }
        }
        return tag
      })
      settagsData({
        data: [...newList],
        totalCount: tagsData.totalCount,
      })
      form.setFieldsValue({ tags_items: [...newList] })
      setisLoading(false)
    }, 1000)
  }

  useSocket(
    `private-organization=${organization._id}`,
    'TAG_UPDATED',
    handleUpdateList,
    !!organization._id
  )

  useSocket(
    `private-organization=${organization._id}`,
    'TAG_CREATED',
    (data) => {
      setTimeout(() => {
        const oldData = form.getFieldsValue().tags_items
        const find = oldData.find((tag) => tag._id === data._id)
        if (!find) {
          setisLoading(true)
          form.setFieldsValue({ tags_items: [] })
          if (page === 0) {
            fill()
          } else {
            setpage(0)
          }
        }
      }, 1500)
    },
    !!organization._id
  )

  useSocket(
    `private-organization=${organization._id}`,
    'TAG_DELETED',
    (data) => {
      setTimeout(() => {
        const oldData = form.getFieldsValue().tags_items
        const newList = oldData.filter((tag) => {
          return tag._id !== data._id
        })
        if (oldData.length !== newList.length) {
          settagsData({
            data: [...newList],
            totalCount: tagsData.totalCount,
          })
          form.setFieldsValue({ tags_items: [...newList] })
        }
      }, 1000)
    },
    !!organization._id
  )

  const fill = async () => {
    setisLoading(true)
    const result = await tagsActions.getTagsList(page, 50, '')

    if (result.success) {
      const newData = page === 0 ? result.data : [...tagsData.data, ...result.data]
      settagsData({
        data: newData,
        totalCount: result.max,
      })
      form.setFieldsValue({ tags_items: newData })
    }
    setisLoading(false)
  }

  useEffect(() => {
    fill()
  }, [page])

  if (!organization._id || isLoading) {
    return <LoadingSpinner />
  }

  return (
    <OrganizationPage
      title={getText('WORD_TAGS')}
      subtitle={getText('TEXT_UPDATE_ORGANIZATION_TAGS')}
    >
      <MyDefaultBlock>
        <MatBlockWithLabel
          className='tags-header'
          label={getText('WORD_TAGS')}
          button={
            <MatButton
              onClick={() => {
                formRef?.current?.add(
                  {
                    name: '',
                    color: '#47A4FE',
                    tmpId: new Date(),
                  },
                  0
                )
              }}
              buttonText={getText('WORD_ADD_TAG')}
              htmlType={'button'}
            />
          }
        ></MatBlockWithLabel>
        <MatForm
          form={form}
          // onFinish={onFinish}
          // onFinishFailed={onFinishFailed}
          initialValues={{
            tags_items: tagsData.data,
          }}
        >
          <div ref={scrollParentRef} className={'tags_items_container'}>
            <InfiniteScroll
              pageStart={page}
              loadMore={() => {
                if (!isLoading && tagsData.data.length < tagsData.totalCount) {
                  setisLoading(true)
                  setpage((ov) => ov + 1)
                }
              }}
              hasMore={!isLoading && tagsData.data.length < tagsData.totalCount}
              useWindow={false}
              threshold={150}
              className={`mat-infinite-scroll ${props.inifiniteScrollClass || ''}`}
              getScrollParent={() => scrollParentRef.current}
            >
              <AddTagsList
                form={form}
                ref={formRef}
                onInitialValuesUpdate={(addLength) => {
                  settagsData({
                    totalCount: addLength
                      ? tagsData.totalCount + 1
                      : tagsData.totalCount,
                    data: form.getFieldsValue().tags_items,
                  })
                }}
              />
            </InfiniteScroll>
          </div>
        </MatForm>
      </MyDefaultBlock>
    </OrganizationPage>
  )
}

export default Tags
