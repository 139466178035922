import React, { useState } from 'react'
import { Flex } from 'antd'
import SVGHamburgerFilterIcon from '../../icons/SVG/SVGHamburgerFilterIcon.js'
import MyDefaultButton from '../MyDefaultButton/MyDefaultButton'
import MyDefaultSearchInput from '../MyDefaultSearchInput/MyDefaultSearchInput'
import MyDefaultSelect from '../MyDefaultSelect/MyDefaultSelect'
import MyDefaultDrawer from '../MyDefaultDrawer/MyDefaultDrawer'
import {
  BUTTON,
  DROPDOWN_BUTTON,
  NEGATIVE_BUTTON,
  POSITIVE_BUTTON,
  PRIMARY,
  SEARCH,
  SELECT,
} from '../../devOptions.js'
import { MyDefaultDropdownButton } from '../MyDefaultDropdownButton/MyDefaultDropdownButton.js'
import MyDefaultNegativeButton from '../MyDefaultButton/MyDefaultNegativeButton/MyDefaultNegativeButton.js'
import MyDefaultPositiveButton from '../MyDefaultButton/MyDefaultPositiveButton/MyDefaultPositiveButton.js'
import './MyDefaultPageHeader.scss'

const deviceSizes = ['tabletPortrait', 'mobile']

const HeaderItem = ({ item }) => {
  if (item.type === SEARCH) {
    return <MyDefaultSearchInput value={item.value} onChange={item.onChange} />
  }
  if (item.type === SELECT) {
    return (
      <MyDefaultSelect
        color={item.color || 'white'}
        options={item.options}
        value={item.value}
        onChange={item.onChange}
      />
    )
  }
  if (item.type === BUTTON) {
    return (
      <MyDefaultButton
        typeButton={item.typeButton || PRIMARY}
        buttonText={item.title}
        onClick={item.onClick}
      />
    )
  }
  if (item.type === NEGATIVE_BUTTON) {
    return (
      <MyDefaultNegativeButton
        buttonText={item.title}
        onClick={item.onClick}
        disabled={item.disabled}
      />
    )
  }
  if (item.type === POSITIVE_BUTTON) {
    return (
      <MyDefaultPositiveButton
        buttonText={item.title}
        onClick={item.onClick}
        disabled={item.disabled}
      />
    )
  }
  if (item.type === DROPDOWN_BUTTON) {
    return (
      <MyDefaultDropdownButton
        title={item.title}
        dropdownMenuItems={item.items}
        onItemClick={item.onItemClick}
        onClick={item.onClick}
      />
    )
  }
  return null
}

const MyDefaultPageHeaderAdvanced = ({
  onClearAll,
  screenSize,
  left,
  right,
  drawer,
  isResponsiveSize,
}) => {
  const [visibleDrawer, setvisibleDrawer] = useState(false)

  return (
    <Flex
      className={`my_default_page_header_advanced ${isResponsiveSize ? 'responsive_size' : ''}`}
    >
      <Flex className='my_default_page_header_advanced--left' justify='start'>
        {(left || []).map((item, index) => {
          return <HeaderItem key={index} item={item} />
        })}
        {Boolean(deviceSizes.includes(screenSize) && drawer?.length) && (
          <MyDefaultButton
            icon={<SVGHamburgerFilterIcon color={'var(--white)'} />}
            onClick={() => {
              setvisibleDrawer(true)
            }}
          />
        )}
      </Flex>
      <Flex className='my_default_page_header_advanced--right' justify='end'>
        {(right || []).map((item, index) => {
          return <HeaderItem key={index} item={item} />
        })}
      </Flex>
      <MyDefaultDrawer
        placement={'right'}
        closable={false}
        onClose={() => {
          setvisibleDrawer(false)
        }}
        open={visibleDrawer}
        width={285}
        onClearAll={() => {
          onClearAll && onClearAll()
        }}
      >
        {(drawer || []).map((item, index) => {
          return <HeaderItem key={index} item={item} />
        })}
      </MyDefaultDrawer>
    </Flex>
  )
}

export default MyDefaultPageHeaderAdvanced
