import React, { useCallback, useState } from 'react'
import { debounce } from 'lodash'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { getText } from '../../lang'
import SVGSearch from '../../icons/SVG/SVGSearch'
import InputText from '../../components/InputText'
import MatButton from '../../components/MatButton'
import MatRow from '../../components/MatRow'
import MessageInvitationModal from '../messages/center/centerMessageFooter/MessageInvitation/MessageInvitationModal'

const InvitationHeader = ({ onFiltersChange, onSaveInvitation }) => {
  const navigate = useNavigate()
  const [searchInputValue, setSearchInputValue] = useState('')
  const debouncedChange = useCallback(
    debounce((options) => onFiltersChange(options), 500),
    []
  )

  const user = useSelector((state) => {
    return state.authReducer.user
  })

  const handleFitlersChange = (value) => {
    setSearchInputValue(value)
    debouncedChange({ searchInputValue: value })
  }

  return (
    <MatRow className={'invitation-list-header-wrapper'}>
      <div className='invitation-list-header-left'>
        <div className='left-title'>{getText('WORD_INVITATIONS_SUBTITLE')}</div>
        <div className='left-desc'>{getText('TEXT_INVITATIONS_DESC')}</div>
      </div>
      <MatRow className={'invitation-list-header-right'}>
        {!user.isSupportAgent && (
          <MatButton
            buttonText={getText('TITLE_LEADERBOARD')}
            onClick={() => {
              navigate('/leaderboard')
            }}
          />
        )}
        <MessageInvitationModal
          disableInvitationPhoneNumber
          conversation={{}}
          disabled={false}
          trigger={<MatButton buttonText={getText('ACTION_INVITE')} />}
          onSaveInvitation={onSaveInvitation}
        />
        <div className='inputWithSearch'>
          <SVGSearch />
          <InputText
            className='inputSearch'
            placeholder={getText('WORD_SEARCH')}
            value={searchInputValue}
            onChange={(e) => handleFitlersChange(e.target.value, 'searchInputValue')}
          />
        </div>
      </MatRow>
    </MatRow>
  )
}

export default InvitationHeader
