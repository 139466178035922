import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { CloseOutlined } from '@ant-design/icons'
import SVGWorkflowAssignUserIcon from '../../../../icons/SVG/SVGWorkflowAssignUserIcon'
import { getText } from '../../../../lang'
import userActions from '../../../../store/modules/userActions'
import { GET_DATA_FETCH_SIZE } from '../../../../utils'
import SelectCheckedSecondary from '../../../../components/Form/SelectChecked/SelectCheckedSecondary'
import MatRow from '../../../../components/MatRow'
import { getUsersListIds } from '../../../broadcast/CreateCampaign/CreateSegment/utils'

const WorkflowActionAssignItem = ({
  onRemove,
  field,
  item,
  form,
  fieldItemName,
}) => {
  const [listUsers, setListUsers] = useState([])
  const [assignSearchKeyword, setAssignSearchKeyword] = useState('')
  const [loading, setLoading] = useState(false)
  const [isActionFinished, setIsActionFinished] = useState(false)
  const [listUserSelected, setlistUserSelected] = useState(
    item.config.users_to_assign.length ? item.config.users_to_assign : []
  )

  const organization = useSelector((state) => {
    return state.authReducer.user.organization
  })

  const user = useSelector((state) => {
    return state.authReducer.user
  })

  const searchUsers = useCallback(async () => {
    setLoading(true)
    const usersRes = await userActions.getUsersForAssignUnassign(
      0,
      GET_DATA_FETCH_SIZE,
      organization._id,
      assignSearchKeyword,
      user.isSuperAdmin
        ? {
            fullList: true,
          }
        : {
            withoutSAdmins: true,
          }
    )
    let stateValue = {}
    if (usersRes.success) {
      stateValue = {
        listUsers: getUsersListIds(usersRes.data),
        maxManagers: usersRes.max,
      }
    }
    setListUsers(stateValue)
    setLoading(false)
  }, [assignSearchKeyword, organization])

  useEffect(() => {
    if (user.isAdmin || user.isManager) {
      searchUsers()
    }
  }, [searchUsers])

  const handleLoadMoreUsers = async () => {
    if (user.isAdmin || user.isManager) {
      if (!isActionFinished && listUsers.listUsers.length < listUsers.maxManagers) {
        setIsActionFinished(true)
        const result = await userActions.getUsersForAssignUnassign(
          listUsers.listUsers.length / GET_DATA_FETCH_SIZE,
          GET_DATA_FETCH_SIZE,
          organization._id,
          '',
          user.isSuperAdmin
            ? {
                fullList: true,
              }
            : {
                withoutSAdmins: true,
              }
        )
        if (result.success) {
          if (listUsers.listUsers.length < listUsers.maxManagers) {
            let listOld = listUsers.listUsers
            let list = [...listOld, ...result.data]
            setListUsers({
              listUsers: getUsersListIds(list),
              maxManagers: result.max,
            })
          }
        }
        setIsActionFinished(false)
      }
    }
  }

  const handleChangeItems = (val, items) => {
    const oldIdsObj = {}
    const pushedIdsObj = {}
    const arr = []

    for (const vv of val) {
      oldIdsObj[vv] = true
    }

    for (const user of listUserSelected) {
      if (oldIdsObj[user.value]) {
        arr.push(user)
        pushedIdsObj[user.value] = true
      }
    }

    for (const item of items) {
      if (!pushedIdsObj[item.value] && oldIdsObj[item.value]) {
        arr.push(item)
      }
    }

    setlistUserSelected(arr)
    setAssignSearchKeyword('')
  }

  useEffect(() => {
    if (
      item.config.users &&
      item.config.users.length &&
      (!item.config.users_to_assign.length ||
        (item.config.users_to_assign.length &&
          typeof item.config.users_to_assign[0] === 'string'))
    ) {
      setlistUserSelected(item.config.users)
    }
  }, [item.config.users])

  useEffect(() => {
    if (listUserSelected.length) {
      const newObj = listUserSelected.map((user) =>
        Object.assign(user, {
          label: user.fullName,
          value: user._id,
        })
      )
      if (newObj) {
        form.setFieldValue([...fieldItemName, 'config', 'users_to_assign'], newObj)
      }
    }
  }, [listUserSelected])

  return (
    <div className='workflow-action-item-wrapper'>
      <MatRow flexStart className='workflow-action-item-row'>
        <div className='workflow-action-item-icon' style={{ color: '#52B387' }}>
          <SVGWorkflowAssignUserIcon width={48} height={48} />
          {getText('ACTION_ASSIGN')}
        </div>
        <div className='workflow-action-item-textarea-wrapper'>
          <SelectCheckedSecondary
            name={[field.name, 'config', 'users_to_assign']}
            option={listUsers.listUsers || []}
            label={''}
            onLoadMore={handleLoadMoreUsers}
            loading={loading}
            mode='multiple'
            onSearch={(search) => setAssignSearchKeyword(search)}
            multipleNewStyle
            placeholder={getText('WORD_SELECT_PEOPLE')}
            onChangeItem={handleChangeItems}
            onClear={() => {
              setAssignSearchKeyword('')
            }}
            required
            message={getText('ERR_MSG_ASSIGN_WORKFLOW')}
          />
          <div className='bottom-text'>
            {listUserSelected.length} {getText('WORD_PEOPLE_ASSIGNED')}
          </div>
        </div>
      </MatRow>
      <CloseOutlined
        style={{ color: '#77808d', fontSize: '10px' }}
        className='workflow-remove-icon'
        onClick={() => {
          onRemove && onRemove()
        }}
      />
    </div>
  )
}

export default WorkflowActionAssignItem
