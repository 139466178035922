import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Divider, Modal, Tooltip } from 'antd'
import { DEFAULT, NIGHT, TABLE_DEFAULT_ROW_NUMBER } from '../../../../devOptions'
import { getText, getTextServerError } from '../../../../lang'
import organizationActions from '../../../../store/modules/organizationActions'
import { notifyError, notifySuccess } from '../../../../utils/Notify'
import MatSwitch from '../../../../components/MatSwitch'
import PageFull from '../../../../components/PageFull'
import OrganizationPage from '../../components/OrganizationPage'
import ListLoadMore from '../../../../components/ListLoadMore'
import MatButtonWithDropDown from '../../../../components/MatButtonWithDropDown/MatButtonWithDropDown'
import InactiveBlock from '../../../../components/InactiveBlock/InactiveBlock'
import MatButton from '../../../../components/MatButton'
import SVGInfoIconSilver from '../../../../icons/SVG/SVGInfoIconSilver'
import DripSequencetItem from './DripSequenceListItem'
import './DripSequenceListItem.scss'

const DripSequence = (props) => {
  const { organization, scrollParentRef } = props

  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(0)
  const [perPage] = useState(TABLE_DEFAULT_ROW_NUMBER)
  const [totalCount, setTotalCount] = useState(0)
  const [sequencesData, setSequencesData] = useState([])
  const [enableAutoReply, setEnableAutoReply] = useState(
    organization.enable_auto_reply
  )
  const navigate = useNavigate()

  const isActivitySequencePresent = sequencesData.some(
    (seq) => seq.triggeredOn === 'activity'
  )
  const getSequencesData = async () => {
    setLoading(true)
    const result = await organizationActions.getPageListSequence(
      page,
      perPage,
      organization._id
    )
    if (result.success) {
      let data = []
      if (page === 0) {
        data = result.data
      } else {
        data = [...sequencesData, ...result.data]
      }
      setSequencesData(data)
      setTotalCount(result.max)
      setTimeout(() => {
        setLoading(false)
      }, 150)
    }
  }

  useEffect(() => {
    props.setPageTitle(getText('WORD_SEQUENCES'))
  }, [])

  useEffect(() => {
    if (organization && organization._id) {
      setEnableAutoReply(organization.enable_auto_reply)
      getSequencesData()
    }
  }, [organization, page, perPage])

  const handleEnableChange = async (newValue) => {
    const enableObject = { enable_auto_reply: newValue }
    const result = await organizationActions.setAutoReply(
      enableObject,
      organization._id
    )
    if (result.success) {
      props.setOrganization(result.data)
      setEnableAutoReply(result.data.enable_auto_reply)
      if (newValue) {
        notifySuccess(getText('TEXT_UPDATE_ORGANIZATION_DRIP_SEQUENCE_ENABLED'))
      } else {
        notifySuccess(getText('TEXT_UPDATE_ORGANIZATION_DRIP_SEQUENCE_DISABLED'))
      }
    } else {
      notifyError(getTextServerError(result.errMsg))
    }
  }

  const handleChangeAllSequencesStatus = async (bool) => {
    const result = await organizationActions.changeAllSequencesStatus({
      isActive: bool,
      _organization_id: organization._id,
    })
    if (result.success) {
      setSequencesData((ov) => {
        ov.map((seq) => (seq.isActive = bool))
        return [...ov]
      })
      notifySuccess(
        getText(
          bool
            ? 'TEXT_ALL_SEQUENCES_WERE_ENABLED_SUCCESSFULLY'
            : 'TEXT_ALL_SEQUENCES_WERE_DISABLED_SUCCESSFULLY'
        )
      )
    } else {
      notifyError(getTextServerError(result.errMsg))
    }
  }

  return (
    <OrganizationPage
      title={''}
      subtitle={
        <div className='org-ai-bot-user-action'>
          {getText('TITLE_DRIP_SEQUENCE')}
          {organization.featuresConnectingData.AIBot && (
            <div className='footer-connected-account'>
              <span>
                {organization.featuresConnectingData.AIBot.isConnected
                  ? getText('TEXT_ENABLED_BY')
                  : getText('TEXT_DISABLED_BY')}{' '}
              </span>
              <span>
                {organization.featuresConnectingData.AIBot.userData.fullName}
              </span>
            </div>
          )}
        </div>
      }
      switchBox={<MatSwitch value={enableAutoReply} onChange={handleEnableChange} />}
    >
      <PageFull>
        <div className='broadcast-list-new-layout-wrapper'>
          <div className='broadcast-list-header sequence'>
            <div className='left-content'>
              <p>{getText('WORD_TOTAL_SEQUENCES')}</p>
              <span>{totalCount}</span>
            </div>
            <div className='right-content'>
              {Boolean(!enableAutoReply) && (
                <InactiveBlock
                  tooltipTitle={getText(
                    'TEXT_YOU_CAN_CONTINUE_TO_MODIFY_SEQUENCE_CONFIGURATIONS'
                  )}
                />
              )}
              <MatButton
                className={'enable_all_sequences'}
                disabled={!enableAutoReply}
                onClick={() => {
                  Modal.confirm({
                    title: getText('TEXT_ENABLE_ALL_SEQUENCES'),
                    content: (
                      <>
                        {getText(
                          'TEXT_ARE_YOU_SURE_YOU_WANT_TO_ENABLE_ALL_SEQUENCES_IN_THIS_ORGANIZATION'
                        )}
                        <br />
                        <br />
                        {getText('TEXT_THIS_ACTION_CANNOT_BE_UNDONE')}
                      </>
                    ),
                    icon: null,
                    okText: getText('WORD_YES'),
                    cancelText: getText('WORD_NO'),
                    onOk: async () => {
                      handleChangeAllSequencesStatus(true)
                    },
                    centered: true,
                    okButtonProps: {
                      type: 'primary',
                      className: 'mat-btn mat-btn-primary',
                    },
                    className: 'confirm-alert-wrapper',
                    cancelButtonProps: {
                      type: 'white',
                      className: 'mat-btn mat-btn-white',
                    },
                  })
                }}
                buttonText={
                  <div className='enable_all_sequences_text'>
                    {getText('TEXT_ENABLE_ALL_SEQUENCES')}
                    <Tooltip
                      title={getText('TEXT_BY_CLICKING_YOU_ENABLE_ALL_SEQUENCES')}
                    >
                      <div style={{ marginLeft: 4 }}>
                        <SVGInfoIconSilver />
                      </div>
                    </Tooltip>
                  </div>
                }
              />
              <MatButton
                className={'disable_all_sequences'}
                disabled={!enableAutoReply}
                onClick={() => {
                  Modal.confirm({
                    title: getText('TEXT_DISABLE_ALL_SEQUENCES'),
                    content: (
                      <>
                        {getText(
                          'TEXT_ARE_YOU_SURE_YOU_WANT_TO_DISABLE_ALL_SEQUENCES_IN_THIS_ORGANIZATION'
                        )}
                        <br />
                        <br />
                        {getText('TEXT_THIS_ACTION_CANNOT_BE_UNDONE')}
                      </>
                    ),
                    icon: null,
                    okText: getText('WORD_YES'),
                    cancelText: getText('WORD_NO'),
                    onOk: async () => {
                      handleChangeAllSequencesStatus(false)
                    },
                    centered: true,
                    okButtonProps: {
                      type: 'primary',
                      className: 'mat-btn mat-btn-primary',
                    },
                    className: 'confirm-alert-wrapper',
                    cancelButtonProps: {
                      type: 'white',
                      className: 'mat-btn mat-btn-white',
                    },
                  })
                }}
                buttonText={
                  <div className='disable_all_sequences_text'>
                    {getText('TEXT_DISABLE_ALL_SEQUENCES')}
                    <Tooltip
                      title={getText('TEXT_BY_CLICKING_YOU_DISABLE_ALL_SEQUENCES')}
                    >
                      <div style={{ marginLeft: 4 }}>
                        <SVGInfoIconSilver />
                      </div>
                    </Tooltip>
                  </div>
                }
              />
              <Divider type='vertical' className='buttons_divider' />
              <MatButtonWithDropDown
                options={[
                  {
                    label: (
                      <>
                        <span className='not-show'>
                          {getText('WORD_CREATE_WORKING_HOURS_SEQUENCE')}
                        </span>
                        <span className='default'>{getText('WORD_DEFAULT')}</span>
                      </>
                    ),
                    value: DEFAULT,
                  },
                  {
                    label: (
                      <>
                        <span className='not-show'>
                          {getText('WORD_CREATE_AFTER_HOURS_SEQUENCE')}
                        </span>
                      </>
                    ),
                    value: NIGHT,
                  },
                ]}
                onDropDownChange={(val) => {
                  navigate(
                    `/organization/settings/${organization._id}/dripsequence/create`,
                    { state: { isActivitySequencePresent, type: val } }
                  )
                }}
                buttonText={getText('WORD_CREATE_WORKING_HOURS_SEQUENCE')}
                onClick={() => {
                  navigate(
                    `/organization/settings/${organization._id}/dripsequence/create`,
                    { state: { isActivitySequencePresent, type: DEFAULT } }
                  )
                }}
              />
            </div>
          </div>
          <ListLoadMore
            loading={loading}
            totalDataCount={totalCount}
            onLoadMore={() => {
              if (!loading && totalCount > sequencesData.length) {
                setLoading(true)
                setPage((ov) => ov + 1)
              }
            }}
            pageStart={0}
            getScrollParent={() => scrollParentRef.current}
            data={sequencesData}
            renderItem={(item, index) => (
              <DripSequencetItem
                key={index}
                item={item}
                onEdit={() => {
                  navigate(
                    `/organization/settings/${organization._id}/dripsequence/edit`,
                    {
                      state: {
                        sequenceData: item,
                        isActivitySequencePresent,
                      },
                    }
                  )
                }}
                onDelete={async () => {
                  setLoading(true)
                  const result = await organizationActions.deleteSequence(item._id)
                  if (result.success) {
                    getSequencesData()
                    notifySuccess(getText('TEXT_SEQUENCE_WAS_DELETED_SUCCESSFULLY'))
                  } else {
                    notifyError(getTextServerError(result.errMsg))
                  }
                }}
                onStatusChange={() => {
                  setSequencesData((ov) => {
                    ov.map((seq) => {
                      if (seq._id === item._id) {
                        seq.isActive = !seq.isActive
                      }
                      return seq
                    })
                    return [...ov]
                  })
                }}
              />
            )}
          />
        </div>
      </PageFull>
    </OrganizationPage>
  )
}

export default DripSequence
