import React from 'react'
import Div from '../Div/Div'
import MyDefaultSwitch from '../MyDefaultSwitch/MyDefaultSwitch'

const MyDefaultBlockRow = ({ isSwitch, label, value, onChange, isLast }) => {
  if (isSwitch) {
    return (
      <MyDefaultSwitch
        title={label}
        value={value}
        onChangeSwitch={onChange}
        noText
        isLast={isLast}
      />
    )
  }
  return (
    <Div className={`my_default_block_row ${isLast ? 'last' : ''}`}>
      <span>{label}</span>
      <span>{value}</span>
    </Div>
  )
}

export default MyDefaultBlockRow
