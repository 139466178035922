import React, { memo } from 'react'
import { getText } from '../../../../../lang'
import SVGChatClose from '../../../../../icons/SVG/SVGChatClose'
import SVGSendMessageLater from '../../../../../icons/SVG/SVGSendMessageLater'
import DateTimePicker from '../../../../../components/api/DateTimePicker'
import MatPopover from '../../../../../components/MatPopover'
import MatButton from '../../../../../components/MatButton'
import './MessageScheduledAction.scss'

const deviceSize = [
  'mobile',
  'tabletPortrait',
  'tablet',
  'tabletLandscape',
  'messageRightDrawer',
]

const MessagesPicker = (props) => {
  const {
    isMessageScheduled,
    active,
    screenSize,
    disabled,
    sendMessageLaterVisible,
    setSendMessageLaterVisible,
  } = props

  return (
    <MatPopover
      title={getText('TEXT_SEND_THIS_MESSAGE_LATER')}
      trigger='click'
      visible={sendMessageLaterVisible}
      onVisibleChange={() => {
        if (!isMessageScheduled && active) {
          setSendMessageLaterVisible((ov) => !ov)
        }
      }}
      overlayClassName={'message_date_picker_dropdown'}
      placement={deviceSize.includes(screenSize) ? 'topRight' : 'top'}
      triggerPopover={
        <MatButton
          icon={<SVGSendMessageLater />}
          className={`icon-size send_message_later_icon ${active ? 'active-scheduled-message' : ''}`}
          disabled={disabled}
          style={{ marginLeft: '0' }}
        />
      }
      content={
        <>
          <DateTimePicker
            visible={sendMessageLaterVisible}
            onClickSend={(date) => {
              setSendMessageLaterVisible(false)
              props.onClickSend(date)
            }}
          />
          <div
            className='close-icon'
            onClick={() => {
              setSendMessageLaterVisible(false)
            }}
          >
            <SVGChatClose color={'#77808D'} />
          </div>
        </>
      }
    />
  )
}

export default memo(MessagesPicker)
